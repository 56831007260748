import React from "react";
import { Link } from "react-router-dom";
import { format } from "../../components/number-format/number-format.component";
import { lookUP, save, saveChanges } from "../../api/functions";
import Switch from "@material-ui/core/Switch";

// Customer/Vendors functions

export const getDummyTeam = (api_token, setTeam, setAtion, disableAgents) => {
  let cus = 0;
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },

      {
        label: "Name",
        field: "name",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        width: 270,
      },

      {
        label: "Ref ID",
        field: "ref_id",
        width: 270,
      },

      {
        label: "Gender",
        field: "gender",
        width: 270,
      },
      {
        label: "Current Earning",
        field: "current_earning",
        width: 270,
      },
      {
        label: "Signup",
        field: "account",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "addTeam",
    { inputs: { page: "getTeamMembers", web: "web" } },
    api_token,
    (responseJson) => {
      // console.log(responseJson);
      // return false;
      try {
        responseJson.table_data.map((teamAcc) => {
          i += 1;
          obj.rows.push({
            id: i,
            name: (
              <Link to={`/memberAccount/${teamAcc.agent_ref_id}`}>
                {teamAcc.name}
              </Link>
            ),
            phone: teamAcc.phone,
            email: teamAcc.email,
            ref_id: teamAcc.agent_ref_id,
            current_earning: teamAcc.cuurent_earning,
            account: teamAcc.account,
            gender: teamAcc.gender,
          });
          cus += 1;
        });

        setTeam(
          obj,
          responseJson.team_info_data,
          responseJson.team_summary_data
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getTeam = (api_token, setTeam, setAtion, disableAgents) => {
  let cus = 0;
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },

      {
        label: "Name",
        field: "name",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        width: 270,
      },

      {
        label: "Ref ID",
        field: "ref_id",
        width: 270,
      },

      {
        label: "Gender",
        field: "gender",
        width: 270,
      },
      {
        label: "Current Earning",
        field: "current_earning",
        width: 270,
      },
      {
        label: "Signup",
        field: "account",
        width: 270,
      },
      {
        label: "Date",
        field: "date",
        width: 270,
      },
      // {
      //   label: "Enable",
      //   field: "disable",
      //   width: 270,
      // },
      // {
      //   label: "Assign Admin",
      //   field: "action",
      //   width: 270,
      // },
    ],
    rows: [],
  };

  lookUP(
    "addTeam",
    { inputs: { page: "getTeamMembers", web: "web" } },
    api_token,
    (responseJson) => {
      console.log(responseJson);
      // return false;
      try {
        if (responseJson.table_data.length > 0) {
          responseJson.table_data.map((teamAcc) => {
            i += 1;
            obj.rows.push({
              id: i,
              name: (
                <Link to={`/memberAccount/${teamAcc.agent_ref_id}`}>
                  {teamAcc.name}
                </Link>
              ),
              phone: teamAcc.phone,
              email: teamAcc.email,
              ref_id: teamAcc.agent_ref_id,
              current_earning: teamAcc.cuurent_earning,
              account: teamAcc.account,
              gender: teamAcc.gender,
              date: teamAcc.date,
              disable: (
                <Switch
                  checked={teamAcc.status == 1 ? false : true}
                  onChange={() =>
                    disableAgents(
                      teamAcc.agent_id,
                      teamAcc.status,
                      teamAcc.team_id
                    )
                  }
                  color="secondary"
                />
              ),
              action: (
                <Switch
                  checked={teamAcc.is_admin == 1 ? true : false}
                  onChange={() =>
                    setAtion(
                      teamAcc.agent_id,
                      teamAcc.is_admin,
                      teamAcc.team_id
                    )
                  }
                  color="primary"
                />
              ),
            });
            cus += 1;
          });
        }

        setTeam(
          obj,
          responseJson.team_info_data,
          responseJson.team_summary_data,
          responseJson.agent_reg_summary
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getMembers = (api_token, setMembers, agentRefId) => {
  console.log({ api_token, agentRefId });
  let ven = 0;
  let i = 0;

  let obj = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 270,
      },
      {
        label: "Date",
        field: "date",
        width: 270,
      },
      {
        label: "Signup Count",
        field: "signup_count",
        width: 270,
      },
      {
        label: "Conversion Count",
        field: "conversion_count",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "addTeam",
    { inputs: { page: "agentSummary", web: "web", agent_ref_id: agentRefId } },
    api_token,
    (responseJson) => {
      console.log(responseJson);
      // return false;
      try {
        if (responseJson.table_data.length > 0) {
          responseJson.table_data.map((member) => {
            i += 1;
            obj.rows.push({
              id: i,
              date: (
                <Link to={`/referred-client/${agentRefId},${member.date}`}>
                  {member.date}
                </Link>
              ),
              signup_count: member.signup_count,
              conversion_count: member.conversion_count,
            });
            ven += 1;
          });
        }

        setMembers(obj, responseJson.agent_summary_data);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getHomeMembers = (api_token, setMembers) => {
  let i = 0;

  let obj = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 270,
      },
      {
        label: "Date",
        field: "date",
        width: 270,
      },
      {
        label: "Signup Count",
        field: "signup_count",
        width: 270,
      },
      {
        label: "Conversion Count",
        field: "conversion_count",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "addTeam",
    { inputs: { page: "agentSummaryDashboard", web: "web" } },
    api_token,
    (responseJson) => {
      console.log(responseJson);
      //   return false;
      try {
        if (responseJson.table_data !== null) {
          responseJson.table_data.map((member) => {
            i += 1;
            obj.rows.push({
              id: i,
              date: (
                <Link
                  to={`/referred-client/${responseJson.agent_summary_data.agent_ref_id},${member.date}`}
                >
                  {member.date}
                </Link>
              ),
              signup_count: member.signup_count,
              conversion_count: member.conversion_count,
            });
          });
        } else {
          setMembers("", responseJson.agent_summary_data);
        }

        // alert('yes');
        setMembers(obj, responseJson.agent_summary_data);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getReferredClient = (
  api_token,
  setReferredClient,
  agentRefId,
  date
) => {
  console.log({ agentRefId, date });
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },
      {
        label: "Name",
        field: "name",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        width: 270,
      },
      {
        label: "Address",
        field: "address",
        width: 270,
      },
      {
        label: "Subscription Date",
        field: "subscription",
        width: 270,
      },
      {
        label: "Expiry Date",
        field: "expire",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "addTeam",
    {
      inputs: {
        page: "getAgentRefferedClients",
        agent_ref_id: agentRefId,
        date: date,
      },
    },
    api_token,
    (responseJson) => {
      console.log(responseJson);
      //   return false;
      try {
        responseJson.map((referredClient) => {
          i += 1;
          obj.rows.push({
            id: i,
            name: referredClient.name,
            phone: referredClient.phone,
            email: referredClient.email,
            address: referredClient.address,
            subscription: referredClient.start_date,
            expire: referredClient.end_date,
          });
        });

        setReferredClient(obj);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getDebtors = (api_token, setDebtors) => {
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },
      { label: "Name", field: "name", width: 270 },
      {
        label: "Phone",
        field: "phone",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        width: 270,
      },
      {
        label: "Balance",
        field: "balance",
        width: 270,
      },
      {
        label: "View",
        field: "view",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "getDebtors",
    { inputs: { page: "debtors" } },
    api_token,
    (responseJson) => {
      try {
        responseJson[0].map((customer) => {
          i += 1;
          obj.rows.push({
            id: i,
            name: customer.coy_name,
            phone: customer.phone,
            email: customer.email,
            balance: <font color="tomato">{format(customer.balance, 2)}</font>,
            view: (
              <Link
                to={
                  customer.cate == "LBA"
                    ? `/customer-profile/${customer.id}`
                    : `/vendor-profile/${customer.id}`
                }
              >
                View
              </Link>
            ),
          });
        });

        setDebtors(obj);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const getLoyalty = (api_token, setLoyalty) => {
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },
      {
        label: "Name",
        field: "name",
        width: 270,
      },
      {
        label: "Growing",
        field: "growing",
        width: 270,
      },
      {
        label: "Matured",
        field: "matured",
        width: 270,
      },
      ,
      {
        label: "View",
        field: "view",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "getLoyalties",
    { inputs: { page: "loyalties", web: "web" } },
    api_token,
    (responseJson) => {
      try {
        responseJson[0].rlt.map((customer) => {
          i += 1;
          obj.rows.push({
            id: i,
            name: customer.coy_name,
            growing: format(customer.growing_loyalty, 2),
            matured: format(customer.matured_loyalty, 2),
            view: <Link to={`customer-profile/${customer.id}`}>View</Link>,
          });
        });

        setLoyalty(
          obj,
          responseJson[0].loyaltyExist,
          responseJson[0].loyaltyExist[0].loyalty_percentage,
          responseJson[0].loyaltyExist[0].usable_level,
          responseJson[0].loyaltyExist[0].status
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const insertVendor = (input, api_token, getFunctions) => {
  if (input.name == "" || input.phone == "" || input.gender == "") {
    alert("None of the asterisked fields must be empty");
    return false;
  }

  save("customerVendor", { inputs: input }, api_token, (responseJson) => {
    if (responseJson["success"] === true) {
      alert(responseJson["data"]);
      getFunctions.getFunctions();
    } else {
      alert(responseJson["data"]);
      return false;
    }
  });
};

export const insertTeam = (input, api_token, setResponse) => {
  //alert(api_token);

  save("addTeam", { inputs: input }, api_token, (responseJson) => {
    // console.log(responseJson); return false;
    if (responseJson.success === true) {
      setResponse(responseJson.data, "success");

      //getFunctions.getFunctions();
    } else {
      setResponse(responseJson.data, "danger");
    }
  });
};

export const insertLoyalty = (input, api_token, getFunctions) => {
  save("setUpLoyalty", { inputs: input }, api_token, (responseJson) => {
    if (responseJson["success"] === true) {
      alert("Entry saved successfully");
      getFunctions.getFunctions();
    } else {
      alert(responseJson["data"]);
    }
  });
};

export const updateLoyalty = (input, api_token, getFunctions) => {
  save("setUpLoyalty", { inputs: input }, api_token, (responseJson) => {
    if (responseJson["success"] === true) {
      alert("Entry saved successfully");
      getFunctions.getFunctions();
    } else {
      alert(responseJson["data"]);
    }
  });
};

//  Customer profile functions
export const getSingleCustomer = (api_token, id, profileData) => {
  lookUP(
    "customerVendor",
    { inputs: { page: "getSingleCustomer", id: id } },
    api_token,
    (responseJson) => {
      try {
        profileData.profileData(responseJson);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const purchaseHistory = (
  api_token,
  id,
  filter,
  dateRange,
  userId,
  setPurchaseHistory
) => {
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },
      { label: "Time", field: "date", width: 270 },
      {
        label: "Token",
        field: "token",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        width: 270,
      },
      {
        label: "Amount",
        field: "amount",
        width: 270,
      },
      {
        label: "Location",
        field: "location",
        width: 270,
      },
      {
        label: "User",
        field: "user",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "customerVendor",
    {
      inputs: {
        page: "getPurchaseHistory",
        id: id,
        filter: filter,
        dateRange: dateRange,
        userId: userId,
        web: "web",
      },
    },
    api_token,
    (responseJson) => {
      try {
        responseJson["rlt"].map((item) => {
          i += 1;
          obj.rows.push({
            id: i,
            date: item.update_date,
            token: (
              <Link to="">
                <font style={{ textTransform: "capitalize" }}>
                  {item.token}
                </font>
              </Link>
            ),
            name: item.name,
            amount: format(item.amount, 2),
            location: (
              <font style={{ textTransform: "capitalize" }}>
                {item.location}
              </font>
            ),
            user: (
              <font style={{ textTransform: "capitalize" }}>{item.user}</font>
            ),
          });
        });

        setPurchaseHistory(obj, responseJson["balance"]);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const loyaltyTransactions = (
  api_token,
  id,
  filter,
  dateRange,
  setLoyaltyTransactions
) => {
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },
      {
        label: "Time",
        field: "date",
        width: 270,
      },
      {
        label: "Token",
        field: "token",
        width: 270,
      },
      {
        label: "Description",
        field: "being",
        width: 270,
      },
      {
        label: "Amount",
        field: "amount",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "loyalty",
    {
      inputs: {
        page: "get_loyalty",
        id: id,
        filter: filter,
        dateRange: dateRange,
        web: "web",
      },
    },
    api_token,
    (responseJson) => {
      try {
        responseJson.rlt.map((trans) => {
          i += 1;
          obj.rows.push({
            id: i,
            date: `${trans.date} ${trans.tm}`,
            token: (
              <Link to="">
                <font style={{ textTransform: "capitalize" }}>
                  {trans.token}
                </font>
              </Link>
            ),
            being: (
              <div className="being">
                Being loyalty value on sales of good.
                <br />
                {trans.effect == "CR" ? (
                  <font color="green">Credit</font>
                ) : (
                  <font color="red">Debit</font>
                )}
              </div>
            ),
            amount: format(trans.amount, 2),
          });
        });

        setLoyaltyTransactions(
          obj,
          responseJson.growing,
          responseJson.balance,
          responseJson.used,
          responseJson.matured
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const users = (api_token, setUser) => {
  let i = 0;
  const arr = [];
  lookUP(
    "sales",
    { inputs: { page: "getUsers" } },
    api_token,
    (responseJson) => {
      try {
        responseJson.map(
          (user) => (
            (i += 1), arr.push({ id: i, value: user.userId, text: user.name })
          )
        );
        setUser(arr);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const eraseCustomer = (api_token, id, redirect) => {
  save(
    "customerVendor",
    { inputs: { page: "deleteCustomer", id: id } },
    api_token,
    (responseJson) => {
      if (responseJson["success"] === true) {
        alert(responseJson["data"]);
        redirect(true);
      } else if (responseJson["success"] === false) {
        alert(responseJson["data"]);
      }
    }
  );
};

export const updateCustomer = (input, api_token, getFunctions) =>
  saveChanges(
    "customerVendor",
    { inputs: input },
    api_token,
    (responseJson) => {
      alert(responseJson["data"]);
      getFunctions();
    }
  );

// Vendor profile functions
export const getSingleVendor = (api_token, id, profileData) => {
  lookUP(
    "customerVendor",
    { inputs: { page: "getSingleVendor", id: id } },
    api_token,
    (responseJson) => {
      try {
        profileData.profileData(responseJson);
      } catch (error) {
        console.log(error);
      }
    }
  );
};

export const eraseVendor = (api_token, id, redirect) => {
  save(
    "customerVendor",
    { inputs: { page: "deleteVendor", id: id } },
    api_token,
    (responseJson) => {
      if (responseJson["success"] === true) {
        alert(responseJson["data"]);
        redirect(true);
      } else if (responseJson["success"] === false) {
        alert(responseJson["data"]);
      }
    }
  );
};

export const updateVendor = (input, api_token, getFunctions) =>
  saveChanges(
    "customerVendor",
    { inputs: input },
    api_token,
    (responseJson) => {
      alert(responseJson["data"]);
      getFunctions();
    }
  );

// Both customer and vendor profile functions
export const transactions = (
  api_token,
  id,
  filter,
  transactionDateRange,
  userId,
  setTransaction
) => {
  let i = 0;

  let obj = {
    columns: [
      { label: "#", field: "id", width: 270 },
      { label: "Time", field: "date", width: 270 },
      {
        label: "Token",
        field: "token",
        width: 270,
      },
      {
        label: "Description",
        field: "being",
        width: 270,
      },
      {
        label: "Payment method",
        field: "pmethod",
        width: 270,
      },
      {
        label: "Amount",
        field: "amount",
        width: 270,
      },
      {
        label: "User",
        field: "user",
        width: 270,
      },
    ],
    rows: [],
  };

  lookUP(
    "transactions",
    {
      inputs: {
        page: "get_transactions",
        id: id,
        filter: filter,
        dateRange: transactionDateRange,
        userId: userId,
        web: "web",
      },
    },
    api_token,
    (responseJson) => {
      try {
        responseJson["rlt"].map((trans) => {
          i += 1;
          obj.rows.push({
            id: i,
            date: `${trans.txn_date} ${trans.tm}`,
            token: <Link to="">{trans.token}</Link>,
            being: (
              <div style={{ textTransform: "capitalize" }}>
                {trans.being} at {trans.location}
                <br />
                {trans.effect == "CR" ? (
                  <font color="green">Credit</font>
                ) : (
                  <font color="red">Debit</font>
                )}
              </div>
            ),
            pmethod: (
              <font style={{ textTransform: "capitalize" }}>
                {trans.pmethod}
              </font>
            ),
            amount: format(trans.jvalue, 2),
            user: trans.user,
          });
        });

        setTransaction(obj, responseJson["credit"], responseJson["debit"]);
      } catch (error) {
        console.log(error);
      }
    }
  );
};
