import React from 'react';
import { Link } from "react-router-dom";
import { format } from '../../components/number-format/number-format.component';
import { lookUP, save, saveChanges } from '../../api/functions';

// Customer/Vendors functions
export const getCustomers = (api_token, setCustomers) => {
    let cus = 0;
    let i = 0;
    
    let obj={
        columns: [
            {label: '#', field: 'id',width: 270},
            {label: 'Name',field: 'name',width: 270},
            {
                label: 'Phone',
                field: 'phone',
                width: 270,
            },
            {
                label: 'Email',
                field: 'email',
                width: 270,
            },
            {
                label: 'Balance',
                field: 'balance',
                width: 270,
            },
            {
                label: 'Matured Loyalty',
                field: 'matured',
                width: 270,
            },
            {
                label: 'Growing Loyalty',
                field: 'growing',
                width: 270,
            },
            {
                label: 'Profile',
                field: 'view',
                width: 270,
            }
        ],
        rows: []
    };
    
    lookUP('customerVendor', { inputs: { page: 'getCustomers', web: 'web' } }, api_token, responseJson => {
        
      try {
        
        responseJson[0].map(customer => {
            i+=1;
            obj.rows.push(
                {
                    id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: customer.balance > 0 ? <font color="yellowgreen">{format(customer.balance, 2)}</font> : <font color="red">{format(customer.balance, 2)}</font>, matured: format(customer.matured_loyalty, 2), growing: format(customer.growing_loyalty, 2), view: <Link to={`customer-profile/${customer.id}`}>View</Link>
                }
            )
            cus+=1;
        });

        setCustomers(obj, cus, responseJson[1]['debtors'], responseJson[1]['creditors'], responseJson['loyalty_sum']);

      } catch (error) {
        console.log(error);
      }
    });
  }

export const getVendors = (api_token, setVendors) => {
    let ven = 0;
    let i = 0;
    
    let obj={
        columns: [
            {
                label: '#',
                field: 'id',
                width: 270,
            },
            {
                label: 'Name',
                field: 'name',
                width: 270,
            },
            {
                label: 'Phone',
                field: 'phone',
                width: 270,
            },
            {
                label: 'Email',
                field: 'email',
                width: 270,
            },
            {
                label: 'Balance',
                field: 'balance',
                width: 270,
            },
            {
                label: 'Profile',
                field: 'view',
                width: 270,
            }
        ],
        rows: []
    };
    
    lookUP('customerVendor', { inputs: { page: 'getvendors', web: 'web' } }, api_token, responseJson => {
        
      try {
        
        responseJson[0].map(customer => {
            i+=1;
            obj.rows.push(
                {
                    id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: customer.balance > 0 ? <font color="green">{format(customer.balance, 2)}</font> : <font color="red">{format(customer.balance, 2)}</font>, view: <Link to={`vendor-profile/${customer.id}`}>View</Link>
                }
            )
            ven+=1;
        });

        setVendors(obj, ven);

      } catch (error) {
        console.log(error);
      }
    });
  }

  export const getCreditors = (api_token, setCreditors) => {
        
    let i = 0;
    
    let obj={
        columns: [
            { label: '#',field: 'id',width: 270},
            {
                label: 'Name',
                field: 'name',
                width: 270,
            },
            {
                label: 'Phone',
                field: 'phone',
                width: 270,
            },
            {
                label: 'Email',
                field: 'email',
                width: 270,
            },
            {
                label: 'Balance',
                field: 'balance',
                width: 270,
            },
            {
                label: 'View',
                field: 'view',
                width: 270,
            }
        ],
        rows: []
    };
    
    lookUP('getCreditors', {inputs: {page: 'creditors'}}, api_token, responseJson => {
      try {
        
        responseJson[0].map(customer => {
            i+=1;
            obj.rows.push(
                {
                    id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: <font color="green">{format(customer.balance, 2)}</font>, view: <Link to={customer.cate == 'LBA' ? `/customer-profile/${customer.id}` : `/vendor-profile/${customer.id}`}>View</Link>
                }
               
            )
        });

        setCreditors(obj);

      } catch (error) {
        console.log(error);
      }
    });
  }


  export const getDebtors = (api_token, setDebtors) => {
    
    let i = 0;
    
    let obj={
        columns: [
            { label: '#',field: 'id',width: 270},
            {label: 'Name', field: 'name', width: 270,
            },
            {
                label: 'Phone',
                field: 'phone',
                width: 270,
            },
            {
                label: 'Email',
                field: 'email',
                width: 270,
            },
            {
                label: 'Balance',
                field: 'balance',
                width: 270,
            },
            {
                label: 'View',
                field: 'view',
                width: 270,
            }
        ],
        rows: []
    };
    
    lookUP('getDebtors', {inputs: {page: 'debtors'}}, api_token, responseJson => {
      try {
        
        responseJson[0].map(customer => {
            i+=1;
            obj.rows.push(
                {
                    id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: <font color="tomato">{format(customer.balance, 2)}</font>, view: <Link to={customer.cate == 'LBA' ? `/customer-profile/${customer.id}` : `/vendor-profile/${customer.id}`}>View</Link>
                }
               
            )
        });

        setDebtors(obj);
        
      } catch (error) {
        console.log(error);
      }
    });
  }

  export const getLoyalty = (api_token, setLoyalty) => {
        
    let i = 0;
    
    let obj={
        columns: [
            { label: '#',field: 'id',width: 270},
            {
                label: 'Name',
                field: 'name',
                width: 270,
            },
            {
                label: 'Growing',
                field: 'growing',
                width: 270,
            }, {
                label: 'Matured',
                field: 'matured',
                width: 270,
            },
            , {
                label: 'View',
                field: 'view',
                width: 270,
            },
            
        ],
        rows: []
    };
    
    lookUP('getLoyalties', {inputs: {page: 'loyalties', web:'web'}}, api_token, responseJson => {
      try {
        responseJson[0].rlt.map(customer => {
            i+=1;
            obj.rows.push(
                {
                    id: i, name: customer.coy_name, growing: format(customer.growing_loyalty, 2), matured: format(customer.matured_loyalty, 2), view: <Link to={`customer-profile/${customer.id}`}>View</Link>
                }
            )
        });

        setLoyalty(obj, responseJson[0].loyaltyExist, responseJson[0].loyaltyExist[0].loyalty_percentage, responseJson[0].loyaltyExist[0].usable_level, responseJson[0].loyaltyExist[0].status);

      } catch (error) {
        console.log(error);
      }
    });
  }      

  export const insertVendor = (input, api_token, getFunctions) => {
   
    if(input.name == '' || input.phone == '' || input.gender == '') {
        alert("None of the asterisked fields must be empty");
        return false;
    }
    
    save('customerVendor', { inputs: input }, api_token, responseJson => {
      if (responseJson['success'] === true) {
        alert(responseJson['data']);  
        getFunctions.getFunctions();     
      } else {
        alert(responseJson['data']);
        return false;
      }
    });
  }

  export const insertTeam = (input, api_token, getFunctions) => {
    //alert(api_token); 
    if(input.folio == '' || input.phone == '' || input.email == '' ) {
        alert("None of the asterisked fields must be empty");
        return false;
    }  
    
    if(input.password != input.rePassword ) {
        alert("Password do not match");
        return false;
    }  
      
    save('register', { inputs: input }, '', responseJson => {
        //console.log(responseJson); return false;
    if (responseJson['success'] === true) {
        console.log(responseJson);
        //alert(responseJson['data']);
        //getFunctions.getFunctions();
    } else {
        alert(responseJson['data']);
    }
    }); 


    
 }

 export const insertLoyalty = (input, api_token, getFunctions) => {      
      save('setUpLoyalty', {inputs: input}, api_token, responseJson => {
        if(responseJson['success'] === true){     
          alert("Entry saved successfully");
          getFunctions.getFunctions();
        } else {
            alert(responseJson['data']);
        }
      });
 }

 export const updateLoyalty = (input, api_token, getFunctions) => {
      
      save('setUpLoyalty', {inputs: input}, api_token, responseJson => {
        if(responseJson['success'] === true){            
          alert("Entry saved successfully");
          getFunctions.getFunctions();
        } else {
          alert(responseJson['data']);
        }
      });
 }

 

//  Customer profile functions
 export const getSingleCustomer = (api_token, id, profileData) => {        
    lookUP('customerVendor', {inputs: {page: 'getSingleCustomer', id: id}}, api_token, responseJson =>  {  
        try{   
            profileData.profileData(responseJson);
        } catch(error) {
            console.log(error);
        }      
    });
}

export const purchaseHistory = (api_token, id, filter, dateRange, userId, setPurchaseHistory) => {
    let i = 0;

        let obj={
            columns: [
                {label: '#', field: 'id',width: 270},
                {label: 'Time',field: 'date',width: 270},
                {
                    label: 'Token',
                    field: 'token',
                    width: 270,
                },
                {
                    label: 'Name',
                    field: 'name',
                    width: 270,
                },
                {
                    label: 'Amount',
                    field: 'amount',
                    width: 270,
                },{
                    label: 'Location',
                    field: 'location',
                    width: 270,
                },
                {
                    label: 'User',
                    field: 'user',
                    width: 270,
                }
            ],
            rows: []
        };

        lookUP('customerVendor', { inputs: { page: 'getPurchaseHistory', id: id, filter: filter, dateRange: dateRange, userId: userId, web: 'web' } }, api_token, responseJson => {
            
            try{                             
                responseJson['rlt'].map((item) => {
                    i += 1;           
                    obj.rows.push({id: i, date: item.update_date, token: <Link to=""><font style={{textTransform: 'capitalize'}}>{item.token}</font></Link>, name: item.name, amount: format(item.amount, 2), location: <font style={{textTransform: 'capitalize'}}>{item.location}</font>, user: <font style={{textTransform: 'capitalize'}}>{item.user}</font>});             
                });
               
                setPurchaseHistory(obj, responseJson['balance']);

            } catch(error) {
                console.log(error);
            }      
        });
}

export const loyaltyTransactions = (api_token, id, filter, dateRange, setLoyaltyTransactions) => {
    let i = 0;

        let obj={
            columns: [
                {label: '#', field: 'id',width: 270},
                {
                    label: 'Time',
                    field: 'date',
                    width: 270,
                },
                {
                    label: 'Token',
                    field: 'token',
                    width: 270,
                },
                {
                    label: 'Description',
                    field: 'being',
                    width: 270,
                },
                {
                    label: 'Amount',
                    field: 'amount',
                    width: 270,
                }
            ],
            rows: []
        };

        lookUP('loyalty', { inputs: { page: 'get_loyalty', id: id, filter: filter, dateRange: dateRange, web: 'web' } }, api_token, responseJson => {
            
            try{                             
                responseJson.rlt.map((trans) => {
                    i += 1;           
                    obj.rows.push({id: i, date: `${trans.date} ${trans.tm}`, token: <Link to=""><font style={{textTransform: 'capitalize'}}>{trans.token}</font></Link>, being: <div className="being">Being loyalty value on sales of good.<br />{trans.effect == 'CR' ? <font color="green">Credit</font> : <font color="red">Debit</font>}</div>, amount: format(trans.amount, 2)});             
                });

                setLoyaltyTransactions(obj, responseJson.growing, responseJson.balance, responseJson.used, responseJson.matured);

            } catch(error) {
                console.log(error);
            }      
        });
}

export const users = (api_token, setUser) => {
    let i = 0;
    const arr = [];
    lookUP('sales', {inputs: {page: 'getUsers'}}, api_token, responseJson => {
       try {                
         responseJson.map(user => (
             i+=1,
             arr.push({id: i, value: user.userId, text: user.name})
         )) 
        setUser(arr);
       } catch(error) {
           console.log(error);
       }      
   });
}

export const eraseCustomer = (api_token, id, redirect) => {
    save('customerVendor', { inputs: {page: 'deleteCustomer', id: id} }, api_token, responseJson => {
        if (responseJson['success'] === true) {
           alert(responseJson['data']);
           redirect(true);
        } else if(responseJson['success'] === false) {
          alert(responseJson['data']);
        }
    });
}

export const updateCustomer = (input, api_token, getFunctions) => saveChanges('customerVendor', {inputs: input}, api_token, responseJson => {
    alert(responseJson['data']);
    getFunctions();
});



// Vendor profile functions
export const getSingleVendor = (api_token, id, profileData) => {        
    lookUP('customerVendor', {inputs: {page: 'getSingleVendor', id: id}}, api_token, responseJson =>  {  
        try{   
            profileData.profileData(responseJson);
        } catch(error) {
            console.log(error);
        }      
    });
}

export const eraseVendor = (api_token, id, redirect) => {
    save('customerVendor', { inputs: {page: 'deleteVendor', id: id} }, api_token, responseJson => {
        if (responseJson['success'] === true) {
           alert(responseJson['data']);
           redirect(true);
        } else if(responseJson['success'] === false) {
          alert(responseJson['data']);
        }
    });
}

export const updateVendor = (input, api_token, getFunctions) => saveChanges('customerVendor', {inputs: input}, api_token, responseJson => {
    alert(responseJson['data'])
    getFunctions();
});



// Both customer and vendor profile functions
export const transactions = (api_token, id, filter, transactionDateRange, userId, setTransaction ) => {
    let i = 0;

    let obj={
        columns: [
            {label: '#', field: 'id',width: 270},
            {label: 'Time',field: 'date',width: 270},
            {
                label: 'Token',
                field: 'token',
                width: 270,
            },
            {
                label: 'Description',
                field: 'being',
                width: 270,
            },
            {
                label: 'Payment method',
                field: 'pmethod',
                width: 270,
            },
            {
                label: 'Amount',
                field: 'amount',
                width: 270,
            },
            {
                label: 'User',
                field: 'user',
                width: 270,
            }
        ],
        rows: []
    };

    lookUP('transactions', { inputs: { page: 'get_transactions', id: id, filter: filter, dateRange: transactionDateRange, userId: userId, web: 'web' } }, api_token, responseJson => {
        
        try{                             
            responseJson['rlt'].map((trans) => {
                i += 1;           
                obj.rows.push({id: i, date: `${trans.txn_date} ${trans.tm}`, token: <Link to="">{trans.token}</Link>, being: <div style={{textTransform: 'capitalize'}}>{trans.being} at {trans.location}<br />{trans.effect == 'CR' ? <font color="green">Credit</font> : <font color="red">Debit</font>}</div>, pmethod: <font style={{textTransform: 'capitalize'}}>{trans.pmethod}</font>, amount: format(trans.jvalue, 2), user:trans.user});             
            });

            setTransaction(obj, responseJson['credit'], responseJson['debit']);

        } catch(error) {
            console.log(error);
        }      
    });
}