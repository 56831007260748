import { setData, setFileData } from "./api";
// import NetInfo from '@react-native-community/netinfo';

// export const checkConnection = () => {
//     return NetInfo.fetch().then(state => {
//        return state.isConnected;
//     });
// }

export const lookUP = (page, data = {}, api, response) => {
  let datas = { ...data, inputs: { ...data.inputs, appId: 4 } };
  return setData(page, datas, api)
    .then(response)
    .catch((error) => {
      console.error(error);
    });
};

export const save = (page, data = {}, api, response) => {
  let datas = { ...data, inputs: { ...data.inputs, appId: 4 } };
  //alert(page);
  return setData(page, datas, api)
    .then(response)
    .catch((error) => {
      console.error(error);
      //console.error('there is an error');
    });
};

export const saveChanges = (page, data = {}, api, response) => {
  let datas = { ...data, inputs: { ...data.inputs, appId: 4 } };
  return setData(page, datas, api)
    .then(response)
    .catch((error) => {
      console.error(error);
    });
};

export const saveFile = (page, data = {}, api, response, content = "") => {
  let datas = { ...data, inputs: { ...data.inputs, appId: 4 } };
  return setFileData(page, datas, api, content)
    .then(response)
    .catch((error) => {
      console.error(error);
    });
};

// export const profitMargin = (retailValue, inventoryBalance) => {
//     let sub = retailValue - inventoryBalance;
//     let per = sub / retailValue;
//     let pm = per * 100;
//     return pm;
// }
