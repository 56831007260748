import React from "react";
import { Row, Col, Breadcrumb, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../Footer/Footer";
import {
  TeamMemberTab,
  AccountCreatedTab,
  SignupEarningTab,
  ConversionEarningTab,
  TaxPercentageTab,
  AgentRegisteredToday,
  AgentsTotalRegistration,
  AgentMonthRegistration,
  AgentYearRegistration,
} from "./tabs";
import { lookUP, save, saveChanges } from "../../api/functions";
import { format } from "../../components/number-format/number-format.component";
import {
  TeamModal,
  VendorModal,
  LoyaltyModal,
  EditLoyaltyModal,
} from "./modal";
import { RetrieveSession } from "../../components/session/session";
import TableComponent from "../../components/table/table";
import Loader from "../../components/Common/Loader";
import * as Icon from "react-feather";
import { insertTeam, getTeam } from "./functions";
import Switch from "@material-ui/core/Switch";

class CustomerAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideMenu: true,
      api_token: RetrieveSession(),
      teamData: {},
      teamSummaryData: {},
      agentRegSummary: {},
      teamInfoData: {},
      datatable: {},
      name: "",
      team_target: "",
      team_member_target: "",
      team_size: "",
      logistic_percentage: "",
      task_unit_value: "",
      address: "",
      day: "",
      month: "",
      limit: "",
      duration: "",
      loading: false,
      response: "",
      status: "",
      teamId: "",
      teamActive: 1,
    };
  }

  _onSideMenu = (active) => this.setState({ sideMenu: active });

  getFunctions = () => {
    getTeam(
      this.state.api_token,
      this.setTeam.bind(this),
      this.setAtion.bind(this),
      this.disableAgents.bind(this)
    );
    //    this.revoke();
  };

  // revoke() {
  //     if(this.getFunctions !== true){
  //        this.getFunctions();
  //     } else{
  //         return false;
  //     }
  // }

  setAtion = (id, admin, teamId) => {
    lookUP(
      "addTeam",
      {
        inputs: {
          page: "setAdmin",
          web: "web",
          id: id,
          admin: admin,
          team_id: teamId,
        },
      },
      this.state.api_token,
      (responseJson) => {
        this.setState({ loading: true });
        this.getFunctions();
        alert(responseJson.data);
      }
    );
  };

  activateLeadTeam() {
    this.setState({ loading: true });
    lookUP(
      "addTeam",
      {
        inputs: {
          page: "activateTeam",
          web: "web",
          team_id: this.state.teamId,
        },
      },
      this.state.api_token,
      (responseJson) => {
        // console.log(responseJson);
        // return;
        this.setState({ loading: false });
        this.getFunctions();
        alert(responseJson.data);
      }
    );
  }

  disableAgents = (id, status, teamId) => {
    lookUP(
      "addTeam",
      {
        inputs: {
          page: "disableAgent",
          web: "web",
          id: id,
          status: status,
          team_id: teamId,
        },
      },
      this.state.api_token,
      (responseJson) => {
        this.setState({ loading: true });
        this.getFunctions();
        alert(responseJson.data);
      }
    );
  };
  revoke() {
    this.getFunctions();
  }

  componentDidMount = () => {
    this.getFunctions();
    this.revoke();
  };

  setTeam = (obj, team_info_data, team_summary_data, agent_reg_summary) =>
    this.setState({
      teamData: obj,
      teamInfoData: team_info_data,
      teamSummaryData: team_summary_data,
      agentRegSummary: agent_reg_summary,
      teamId: team_info_data.team_id,
      name: team_info_data.team_name,
      team_target: team_info_data.team_target,
      team_member_target: team_info_data.team_member_target,
      team_size: team_info_data.team_size,
      logistic_percentage: team_info_data.team_logistic_percentage,
      task_unit_value: team_info_data.task_unit_value,
      loading: false,
    });

  // setVendors = (obj, ven) => this.setState({ vendorData: obj, vendors: ven, loading: false });

  // setCreditors = (obj) => this.setState({creditorData: obj, loading: false });

  // setDebtors = (obj) => this.setState({ debtorData: obj, loading: false });

  // setLoyalty = (obj, loyaltyExist, loyalty_percentage, usable_level, loyaltyStatus) => this.setState({ loyaltyData: obj, loyaltyExist: loyaltyExist, loyalty_percentage: loyalty_percentage, maturity_level: usable_level, loyaltyStatus: loyaltyStatus, loading: false });

  handleDropDownSelect = (event, data) => this.setState({ gender: data.value });

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  createTeam() {
    const input = {
      name: this.state.name,
      team_target: this.state.team_target,
      team_member_taget: this.state.team_member_target,
      team_size: this.state.team_size,
      logistic_percentage: this.state.logistic_percentage,
      task_unit_value: this.state.task_unit_value,
      team_id: this.state.teamId,
      page: "registerTeam",
    };
    //console.log(input); return false;
    this.setState({ loading: true });
    insertTeam(input, this.state.api_token, this.setResponse);
    this.getFunctions();
    this.setState({ loading: false });
  }

  setResponse = (response, status) =>
    this.setState({ response: response, status: status });

  handleClick = () =>
    this.state.loyaltyStatus == 0
      ? this.setState({ loyaltyStatus: 1 })
      : this.setState({ loyaltyStatus: 0 });

  render() {
    // console.log(this.state.response);
    const {
      loading,
      teamData,
      debtors,
      creditors,
      loyalty_sum,
      genderOptions,
      teamSummaryData,
      agentRegSummary,
      teamInfoData,
      teamActive,
    } = this.state;

    const formStatus =
      teamInfoData.team_name == "" || teamInfoData.team_name == undefined
        ? "New Team"
        : "Edit Team";

    const heading =
      teamInfoData.team_name == "" || teamInfoData.team_name == undefined
        ? "Create New Team"
        : "Edit Team";
    // console.log(teamInfoData.team_name);

    return (
      <div className="page-wrapper">
        {/* Navigation */}
        <Navigation onClick={this._onSideMenu} />
        {/* End Navigation */}

        <div
          className={`main-content d-flex flex-column ${
            this.state.sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <Loader loading={loading} />
          {/* Breadcrumb */}
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Team Account</h1>
              <Link to="/dashboard" className="breadcrumb-item">
                Dashboard
              </Link>
              {/* <Breadcrumb.Item active>Customers</Breadcrumb.Item> */}
            </Breadcrumb>
          </div>
          {/* End Breadcrumb */}

          <Row>
            <Col sm={4} lg={4}>
              {" "}
              {TeamMemberTab(teamSummaryData.team_members)}{" "}
            </Col>

            <Col sm={4} lg={4}>
              {" "}
              {AccountCreatedTab(
                teamSummaryData.number_of_created_account
              )}{" "}
            </Col>

            {/* <Col sm={3} lg={2}>
              {" "}
              {SignupEarningTab(
                format(teamSummaryData.signup_earnings, 2)
              )}{" "}
            </Col> */}

            <Col sm={4} lg={4}>
              {" "}
              {ConversionEarningTab(
                format(teamSummaryData.conversion_earnings, 2)
              )}{" "}
            </Col>

            {/* <Col sm={3} lg={3}>
              {" "}
              {TaxPercentageTab(`${teamSummaryData.task_percentage}%`)}
            </Col> */}
          </Row>

          {/* Basic Table */}
          <Row style={{ marginTop: 10 }}>
            {/* Tab Demo One */}
            <Col xl={9}>
              <div className="card mb-4">
                <div className="card-body">
                  <div className="tabs-style-one">
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                      <Tab eventKey="home" title="Team">
                        <Row>
                          <div className="card-header">
                            <h5 className="card-title">Agent's Registration</h5>
                          </div>

                          <Col sm={3} lg={4}>
                            {" "}
                            {AgentYearRegistration(
                              agentRegSummary.agent_reg_thisyear
                            )}{" "}
                          </Col>

                          <Col sm={3} lg={4}>
                            {" "}
                            {AgentMonthRegistration(
                              agentRegSummary.agent_reg_thismonth
                            )}{" "}
                          </Col>

                          <Col sm={3} lg={4}>
                            {" "}
                            {AgentRegisteredToday(
                              agentRegSummary.agent_reg_today
                            )}{" "}
                          </Col>
                        </Row>
                        <div className="before-table-header-short">
                          {TeamModal(
                            () => this.createTeam(),
                            this.handleInput,
                            this.state.response,
                            this.state.status,
                            formStatus,
                            this.state.name,
                            this.state.team_target,
                            this.state.team_member_target,
                            this.state.team_size,
                            this.state.logistic_percentage,
                            this.state.task_unit_value,
                            heading
                          )}
                          <Icon.RefreshCcw
                            className="icon"
                            style={{ cursor: "pointer", color: "yellowgreen" }}
                            onClick={() => {
                              this.setState({ loading: true });
                              this.getFunctions();
                            }}
                          />
                          Refresh
                        </div>
                        <TableComponent data={teamData} />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Col>

            <Col lg="3">
              <div className="profile-left-content">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-header">
                      <h5 className="card-title">Info</h5>
                    </div>
                    <ul className="info">
                      <li>
                        <Icon.Users className="icon" />
                        <strong>Team Name:</strong> {teamInfoData.team_name}
                      </li>
                      <li>
                        <Icon.MapPin className="icon" />
                        <strong>Team ID: </strong>
                        {teamInfoData.team_id}
                      </li>
                      <li>
                        <Icon.MapPin className="icon" />
                        <strong>Team Admin: </strong>
                        {teamInfoData.team_admin_name}
                      </li>
                      {/* <li>
                        <Icon.Edit className="icon" />
                        <strong>Team Targer:</strong> {teamInfoData.team_target}
                      </li> */}
                      {/* <li>
                        <Icon.Calendar className="icon" />
                        <strong>Team Member Target:</strong>{" "}
                        {teamInfoData.team_member_target}
                      </li> */}
                      <li>
                        <Icon.Aperture className="icon" />
                        <strong>Team Size: </strong>
                        {teamSummaryData.team_members}
                      </li>
                      <li>
                        <Icon.Calendar className="icon" />
                        <strong>Activation Date:</strong>{" "}
                        {teamSummaryData.start_date}
                      </li>
                      <li>
                        <Icon.Calendar className="icon" />
                        <strong>Expire Date: </strong>
                        {teamSummaryData.end_date}
                      </li>
                      <li>
                        <Icon.Mail className="icon" />
                        <strong>Status: </strong>
                        {teamSummaryData.activate_status === "1" ? (
                          <span style={{ color: "tomato" }}> Pending</span>
                        ) : (
                          <span style={{ color: "green" }}> Active</span>
                        )}
                      </li>
                      <Link
                        to={{
                          pathname: `https://portal.omnixbms.org/#/registration/${teamInfoData.team_id}`,
                        }}
                        target="_blank"
                      >
                        <li>
                          <Icon.Mail className="icon" />
                          <strong>Team Url:</strong>{" "}
                          https://portal.omnixbms.org/#/registration/
                          {teamInfoData.team_id}
                        </li>
                      </Link>
                      <p></p>
                      {teamSummaryData.team_members >= "11" ? (
                        <li>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: -10,
                                zIndex: 10,
                                width: "100%",
                              }}
                            >
                              <div class="spinner-border center">
                                <span class="sr-only center">Loading...</span>
                              </div>
                            </div>
                          ) : null}
                          <strong>Activate Account:</strong>{" "}
                          <Switch
                            checked={
                              teamSummaryData.activate_status === "1"
                                ? false
                                : true
                            }
                            onChange={() => this.activateLeadTeam()}
                            color="primary"
                          />
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default CustomerAccounts;
