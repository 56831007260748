import React from "react";
import { Row, Col, Breadcrumb, Tabs, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../Footer/Footer";
import {
  TeamMemberTab,
  AccountCreatedTab,
  SignupEarningTab,
  ConversionEarningTab,
  TaxPercentageTab,
} from "./tabs";
import { lookUP, save, saveChanges } from "../../api/functions";
import { format } from "../../components/number-format/number-format.component";
import {
  TeamModal,
  VendorModal,
  LoyaltyModal,
  EditLoyaltyModal,
} from "./modal";
import { RetrieveSession } from "../../components/session/session";
import TableComponent from "../../components/table/table";
import Loader from "../../components/Common/Loader";
import * as Icon from "react-feather";
import { getReferredClient } from "./functions";

class ReferredClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideMenu: true,
      api_token: RetrieveSession(),
      referredClientData: {},
      loading: false,
      response: "",
      status: "",
      agentRef: this.props.match.params.agentRef,
      date: this.props.match.params.date,
    };
  }

  _onSideMenu = (active) => this.setState({ sideMenu: active });

  getFunctions = () => {
    getReferredClient(
      this.state.api_token,
      this.setReferredClient.bind(this),
      this.state.agentRef,
      this.state.date
    );
    // getMembers(this.state.api_token, this.setMembers.bind(this));
    // getCreditors(this.state.api_token, this.setCreditors.bind(this));
    // getDebtors(this.state.api_token, this.setDebtors.bind(this));
    // getLoyalty(this.state.api_token, this.setLoyalty.bind(this));
  };

  // setAtion = (id, admin) =>{

  //     lookUP('addTeam', { inputs: { page: 'setAdmin', web: 'web', id: id , admin: admin} }, this.state.api_token, responseJson => {
  //         this.setState({loading: true});
  //         this.getFunctions();
  //         alert(responseJson.data)

  //     });

  // }

  componentDidMount = () => this.getFunctions();

  setReferredClient = (obj) =>
    this.setState({ referredClientData: obj, loading: false });

  // setVendors = (obj, ven) => this.setState({ vendorData: obj, vendors: ven, loading: false });

  // setCreditors = (obj) => this.setState({creditorData: obj, loading: false });

  // setDebtors = (obj) => this.setState({ debtorData: obj, loading: false });

  // setLoyalty = (obj, loyaltyExist, loyalty_percentage, usable_level, loyaltyStatus) => this.setState({ loyaltyData: obj, loyaltyExist: loyaltyExist, loyalty_percentage: loyalty_percentage, maturity_level: usable_level, loyaltyStatus: loyaltyStatus, loading: false });

  handleDropDownSelect = (event, data) => this.setState({ gender: data.value });

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  // createTeam() {
  //     const input = {  name: this.state.name, team_target: this.state.team_target, team_member_taget: this.state.team_member_taget, team_size: this.state.team_size, logistic_percentage: this.state.logistic_percentage, task_unit_value: this.state.task_unit_value, page: 'registerTeam'}
  //     //console.log(input); return false;
  //     this.setState({loading: true});
  //     insertTeam(input, this.state.api_token, this.setResponse);
  //     this.setState({loading: false});

  // }

  setResponse = (response, status) =>
    this.setState({ response: response, status: status });

  handleClick = () =>
    this.state.loyaltyStatus == 0
      ? this.setState({ loyaltyStatus: 1 })
      : this.setState({ loyaltyStatus: 0 });

  render() {
    // console.log(this.state.agentRef);
    const { loading, genderOptions, referredClientData, teamInfoData } =
      this.state;

    return (
      <div className="page-wrapper">
        {/* Navigation */}
        <Navigation onClick={this._onSideMenu} />
        {/* End Navigation */}

        <div
          className={`main-content d-flex flex-column ${
            this.state.sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <Loader loading={loading} />
          {/* Breadcrumb */}
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Referred Client</h1>
              <Link to="/dashboard" className="breadcrumb-item">
                Dashboard
              </Link>
              {/* <Link to="/teamAccount" className="breadcrumb-item">
                                Team Account
                            </Link> */}

              {/* <Breadcrumb.Item active>Customers</Breadcrumb.Item> */}
            </Breadcrumb>
          </div>
          {/* End Breadcrumb */}

          {/* <Row>
                        <Col sm={3} lg={3}> {TeamMemberTab(teamSummaryData.team_members)} </Col>

                        <Col sm={3} lg={2}> {AccountCreatedTab(teamSummaryData.number_of_created_account)} </Col>
 
                        <Col sm={3} lg={2}> {SignupEarningTab(format(teamSummaryData.signup_earnings, 2))} </Col>

                        <Col sm={3} lg={2}> {ConversionEarningTab(format(teamSummaryData.conversion_earnings, 2))} </Col>

                        <Col sm={3} lg={3}> {TaxPercentageTab(`${teamSummaryData.task_percentage}%`)}</Col>
                    </Row> */}

          {/* Basic Table */}
          <Row style={{ marginTop: 10 }}>
            {/* Tab Demo One */}
            <Col xl={12}>
              <div className="card mb-4">
                <div className="card-body">
                  <div className="tabs-style-one">
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                      <Tab eventKey="home" title="Team">
                        <div className="before-table-header-short">
                          <Icon.RefreshCcw
                            className="icon"
                            style={{ cursor: "pointer", color: "yellowgreen" }}
                            onClick={() => {
                              this.setState({ loading: true });
                              this.getFunctions();
                            }}
                          />
                          Refresh
                        </div>
                        <TableComponent data={referredClientData} />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ReferredClient;
