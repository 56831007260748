import fetch from 'isomorphic-fetch';
import axios from 'axios';
import {url} from "./url";

export const setData = async (page, data={}, api_token='', content='') => {

  const authorization = api_token !== '' ? `Bearer ${api_token}` : '';
  const contentType = content !=='' ? content : 'application/json';

  const body = content !== '' ? data : JSON.stringify(data);

  const result = await fetch(url('index.php', page), {  
    method: 'POST',
    headers:{
      'Accept': 'application/json',
      'Content-type': contentType,
      'Authorization': authorization
    },
    body: body
  });

  const response = await result.json();

  return response;
  
};


export const setFileData = async (page, data={}, api_token='', content='') => {

  const authorization = api_token !== '' ? `Bearer ${api_token}` : '';
  const contentType = content !=='' ? content : 'application/json';

  const body = content !== '' ? data : JSON.stringify(data);

  const result = await fetch(url('index.php', page), {  
    method: 'POST',
    headers:{
      'Accept': 'application/json',
      'Content-type': contentType,
      'Authorization': authorization,
      'Access-Control-Allow-Origin': "*"
    },
    body: body
  });

  const response = await result.json();

  return response;
  
};