import React, { useState } from "react";
import ModalComponent from "../../components/modal/modal";
import WithdrawalComponentModal from "../../components/modal/withdrawalComponentModal";
import InstructionModalComponent from "../../components/modal/instructionModal";
import { Row, Form, Button, Image, Alert, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
// import { phone } from 'faker/locale/en_CA';

export function WithdrawalModal(
  teamObj,
  handleInput,
  response,
  status,
  total_withdraw,
  isClickable
) {
  return (
    //   ModalComponent({content, action, heading, handleSubmit, errorMsg, errorStatus}){
    <WithdrawalComponentModal
      action="Withdraw"
      heading="Withdraw"
      errorMsg={response}
      errorStatus={status}
      isClickable={isClickable}
      content={
        <Form>
          <center style={{ paddingBottom: 15 }}>
            <text style={{ fontSize: 30 }}>Balance: ₦{total_withdraw}</text>
          </center>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Enter Amount"
                type="phone"
                name="withdrawAmount"
                onChange={handleInput}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => teamObj()}
    />
  );
}
