import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Tabs,
  Tab,
  Form,
  Button,
  Image,
  Alert,
  Container,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../Footer/Footer";
import {
  TotalConversionTab,
  TotalSignupTab,
  TaxPercentageTab,
} from "../team/tabs";
import { lookUP, save, saveChanges, saveFile } from "../../api/functions";
import axios from "axios";
import { format } from "../../components/number-format/number-format.component";
import { PassportIdModal } from "../team/modal";
import { RetrieveSession } from "../../components/session/session";
import { ImageUplaodModal } from "./upload-profileModal";
import TableComponent from "../../components/table/table";
import Loader from "../../components/Common/Loader";
import user1 from "../../assets/img/user/user1.jpg";
import * as Icon from "react-feather";
import { getHomeMembers } from "../team/functions";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideMenu: true,
      api_token: RetrieveSession(),
      memberData: {},
      loading: true,
      response: "",
      status: "",
      bankCode: "",
      accNum: "",
      accName: "",
      totalCon: 0,
      totalSign: 0,
      taskPer: 0,
      agent_name: "",
      agent_ref: "",
      bankList: "",
      bankName: "",
      accountName: "",
      accountNumber: "",
      passport: null,
      passportName: null,
      idcard: null,
      idcardName: null,
      response: "",
      status: "",
      passportImage: "",
      idcardImage: "",
      email: "",
    };
    this.handleSelectState = this.handleSelectState.bind(this);
    this.updateBankInfo = this.updateBankInfo.bind(this);
  }

  onFileChange1 = (event) => {
    // Update the state
    this.setState({
      passport: event.target.files[0],
      passportName: event.target.files[0].name,
    });
  };

  onFileChange2 = (event) => {
    // Update the state
    this.setState({
      idcard: event.target.files[0],
      idcardName: event.target.files[0].name,
    });
  };

  upload = () => {
    // alert('yes ooO'); return false;
    const input = { idcard: this.state.idcard, passport: this.state.passport };

    // console.log(input);
    // return false;

    if (
      this.state.idcardName == null ||
      this.state.passportName == null ||
      this.state.idcard == null ||
      this.state.passport == null
    ) {
      alert("None of the fields must be empty");
      return false;
    }

    this.setState({ loading: true });

    // e.preventDefault();

    const formData = new FormData();
    // formData.append('email', this.state.email)
    formData.append("idcard", this.state.idcard, this.state.idcarName);
    formData.append("passport", this.state.passport, this.state.passportName);
    formData.append("page", "uploadInfo");

    // var headers = {
    //   'Content-Type': 'application/json',
    //   "Access-Control-Allow-Origin": "*"
    // // }
    // `https://omnixbmsdev.com/mobile/l/${path}/${target}`;
    axios
      .post("https://omnixbms.org/omnix_2.1/l/index.php/withdraw", formData, {
        headers: { Authorization: `Bearer ${this.state.api_token}` },
      })

      // axios.post('https://omnixbmsdev.com/mobile/l/index.php/addTeam', formData, headers)
      .then((response) => {
        //handle success
        console.log(response);
        if (response.data.success === true) {
          this.setState({
            response: response.data.data,
            status: response.data.success,
            loading: false,
          });
        } else {
          this.setState({
            response: response.data.data,
            status: response.data.success,
            loading: false,
          });
        }
      })
      .catch(function (response) {});
  };

  _onSideMenu = (active) => this.setState({ sideMenu: active });

  getFunctions = () => {
    getHomeMembers(this.state.api_token, this.setMembers.bind(this));
    // this.revoke();
  };

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSelectState(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  onFileChange1 = (event) => {
    // Update the state
    this.setState({
      passport: event.target.files[0],
      passportName: event.target.files[0].name,
    });
  };
  onFileChange2 = (event) => {
    // Update the state
    this.setState({
      idcard: event.target.files[0],
      idcardName: event.target.files[0].name,
    });
  };

  retrievBankInfo() {
    lookUP(
      "addTeam",
      { inputs: { page: "agentDetails", web: "web" } },
      this.state.api_token,
      (responseJson) => {
        // console.log(responseJson);
        // return false;
        try {
          this.setState({
            bankName: responseJson.bank_name,
            accountName: responseJson.account_name,
            accountNumber: responseJson.account_number,
            agent_ref: responseJson.ref_id,
            email: responseJson.email,
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  retrievImages() {
    lookUP(
      "addTeam",
      { inputs: { page: "agentDetails", web: "web" } },
      this.state.api_token,
      (responseJson) => {
        // console.log(responseJson);
        // return false;
        try {
          this.setState({
            passportImage: responseJson.passport,
            idcardImage: responseJson.idcard,
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  //   updateImage() {
  //       this.setState({loading: true})
  //       lookUP('withdraw', {inputs: {page: 'uploadInfo', passport: this.state.passport, idcard: this.state.idcard, web:'web'}}, this.state.api_token, responseJson => {
  //         console.log(responseJson);
  //         // return false;
  //         try {
  //             this.setState({loading: false})
  //            if (responseJson.data.success == true) {
  //                 alert('Updated successfully!');
  //             } else{
  //                 alert('An error occur! please try again');
  //             }

  //         } catch (error) {
  //           console.log(error);
  //         }
  //       });
  //   }

  updateImage() {
    this.setState({ loading: true });

    // e.preventDefault();

    const formData = new FormData();
    formData.append("idcard", this.state.idcard, this.state.idcardName);
    formData.append("passport", this.state.passport, this.state.passportName);
    formData.append("page", "uploadInfo");

    axios
      .post("https://omnixbms.org/omnix_2.1/l/index.php/withdraw", formData, {
        headers: { Authorization: `Bearer ${this.state.api_token}` },
      })
      .then((response) => {
        console.log(response);
        if (response.data.success == true) {
          this.setState({ loading: false });
          alert("Updated successfully!");
        } else {
          this.setState({ loading: false });
          alert("An error occur! please try again");
        }

        this.retrievImages();
      })
      .catch(function (response) {});
  }

  updateBankInfo(e) {
    e.preventDefault();
    if (
      this.state.bankCode == "" ||
      this.state.accName == "" ||
      this.state.accNum == ""
    ) {
      return alert("None of the field must be empty!");
    }
    // return console.log({
    //   bankCode: this.state.bankCode,
    //   accName: this.state.accName,
    //   accNum: this.state.accNum,
    // });
    // return;
    this.setState({
      loading: true,
    });
    lookUP(
      "withdraw",
      {
        inputs: {
          page: "addBankAccountInfo",
          account_bank: this.state.bankCode,
          account_name: this.state.accName,
          account_number: this.state.accNum,
          web: "web",
        },
      },
      this.state.api_token,
      (responseJson) => {
        // console.log(responseJson);
        // return false;
        try {
          this.setState({
            loading: false,
          });
          if (responseJson.success == true) {
            alert("Updated successfully!");
          } else {
            alert("An error occur! please try again");
          }
          this.retrievBankInfo();
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  //   updateBankInfo = (e) => {

  //     this.setState({loading: true})

  //     // e.preventDefault();

  //     const formData = new FormData();
  //     formData.append('account_number', this.state.accNum)
  //     formData.append('account_name', this.state.accName)
  //     formData.append('account_bank', this.state.bankCode)
  //     formData.append('idcard', this.state.idcard, this.state.idcarName)
  //     formData.append('passport', this.state.passport, this.state.passportName)
  //     formData.append('page', 'addBankAccountInfo')

  //     var headers = {
  //       'Content-Type': 'application/json',
  //       "Access-Control-Allow-Origin": "*"
  //     }

  //     axios.post('https://omnixbmsdev.com/mobile/l/index.php/withdraw', formData, headers, this.state.api_token)
  //     .then(response => {
  //         console.log(response);
  //             if (response.data.success == true) {
  //                 alert('Updated successfully!');
  //             } else{
  //                 alert('An error occur! please try again');
  //             }
  //         this.retrievBankInfo();
  //   })
  //   .catch(function (response) {

  //   });

  // }

  bankInfo() {
    lookUP(
      "withdraw",
      { inputs: { page: "getBankList", web: "web" } },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        // return false;
        try {
          this.setState({
            bankList: responseJson.data,
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  dashboardInfo() {
    lookUP(
      "addTeam",
      { inputs: { page: "agentDetails", web: "web" } },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        // return false;
        try {
          this.setState({
            agent_name: responseJson.name,
            agent_ref: responseJson.ref_id,
          });
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  revoke() {
    this.getFunctions();
  }

  componentDidMount = () => {
    this.getFunctions();
    this.revoke();
    this.dashboardInfo();
    this.bankInfo();
    this.retrievBankInfo();
    this.retrievImages();
  };

  setMembers = (obj, agent_summary_data) =>
    this.setState({
      memberData: obj,
      agentSummaryData: agent_summary_data,
      totalCon: agent_summary_data.total_conversion,
      totalSign: agent_summary_data.total_signup,
      taskPer: agent_summary_data.task_percentage,
      loading: false,
    });

  // setVendors = (obj, ven) => this.setState({ vendorData: obj, vendors: ven, loading: false });

  // setCreditors = (obj) => this.setState({creditorData: obj, loading: false });

  // setDebtors = (obj) => this.setState({ debtorData: obj, loading: false });

  // setLoyalty = (obj, loyaltyExist, loyalty_percentage, usable_level, loyaltyStatus) => this.setState({ loyaltyData: obj, loyaltyExist: loyaltyExist, loyalty_percentage: loyalty_percentage, maturity_level: usable_level, loyaltyStatus: loyaltyStatus, loading: false });

  handleDropDownSelect = (event, data) => this.setState({ gender: data.value });

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  // createTeam() {
  //     const input = {  name: this.state.name, team_target: this.state.team_target, team_member_taget: this.state.team_member_taget, team_size: this.state.team_size, logistic_percentage: this.state.logistic_percentage, task_unit_value: this.state.task_unit_value, page: 'registerTeam'}
  //     //console.log(input); return false;
  //     this.setState({loading: true});
  //     insertTeam(input, this.state.api_token, this.setResponse);
  //     this.setState({loading: false});

  // }

  setResponse = (response, status) =>
    this.setState({ response: response, status: status });

  render() {
    const {
      loading,
      totalCon,
      totalSign,
      taskPer,
      agent_name,
      agent_ref,
      bankList,
      bankCode,
      accNum,
      accName,
      bankName,
      accountName,
      accountNumber,
    } = this.state;

    // console.log(this.state.email);
    // console.log(this.state.passport);
    return (
      <div className="page-wrapper">
        {/* Navigation */}
        <Navigation onClick={this._onSideMenu} />
        {/* End Navigation */}

        <div
          className={`main-content d-flex flex-column ${
            this.state.sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <Loader loading={loading} />
          {/* Breadcrumb */}
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Account details</h1>
              <Link to="/dashboard" className="breadcrumb-item">
                Dashboard
              </Link>
            </Breadcrumb>
          </div>
          {/* End Breadcrumb */}
          {ImageUplaodModal(
            () => this.upload(),
            this.onFileChange1,
            this.onFileChange2,
            this.state.loading,
            this.state.response,
            this.state.status
          )}

          <Row>
            <Col sm={3} lg={4}>
              {" "}
              {TotalConversionTab(totalCon)}{" "}
            </Col>

            <Col sm={3} lg={4}>
              {" "}
              {TotalSignupTab(totalSign)}{" "}
            </Col>

            <Col sm={3} lg={4}>
              {" "}
              {TaxPercentageTab(`${taskPer}%`)}
            </Col>
          </Row>

          {/* Basic Table */}
          <Row style={{ marginTop: 10 }}>
            {/* Tab Demo One */}
            <Col xl={12}>
              <div className="card mb-4">
                <div className="card-body">
                  <div className="tabs-style-one">
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                      <Tab eventKey="home" title="Team">
                        <div className="before-table-header-short">
                          {/* {PassportIdModal(
                                                     () => this.updateImage(), this.onFileChange1, this.onFileChange2, this.state.response, this.state.status)}
                                                */}
                          <Icon.RefreshCcw
                            className="icon"
                            style={{ cursor: "pointer", color: "yellowgreen" }}
                            onClick={() => {
                              this.setState({ loading: true });
                              this.getFunctions();
                            }}
                          />
                          Refresh
                        </div>
                        <Row>
                          <Col lg="6">
                            <div className="profile-left-content">
                              <div className="card mb-4">
                                <div className="card-body">
                                  <div className="card-header">
                                    <h5 className="card-title">Account Info</h5>
                                  </div>
                                  <Form className="center">
                                    <Form.Row>
                                      <Form.Group>
                                        {/* <Form.Label>Select Bank</Form.Label> */}
                                        <Form.Control
                                          as="select"
                                          onChange={this.handleSelectState}
                                          name="bankCode"
                                        >
                                          <option value="">
                                            {bankName
                                              ? bankName
                                              : "Select Bank"}
                                          </option>
                                          {bankList
                                            ? bankList.map((item, index) => (
                                                <option
                                                  key={index}
                                                  value={`${item.code}, ${item.name}`}
                                                >
                                                  {item.name}
                                                </option>
                                              ))
                                            : null}
                                        </Form.Control>
                                      </Form.Group>

                                      <Form.Group
                                        sm="10"
                                        controlId="formGridAddress1"
                                      >
                                        {/* <Form.Label>Account Number</Form.Label> */}
                                        <Form.Control
                                          type="text"
                                          placeholder="Account Number"
                                          name="accNum"
                                          defaultValue={accountNumber}
                                          onChange={this.handleInput}
                                        />
                                      </Form.Group>

                                      <Form.Group controlId="formGridAddress1">
                                        {/* <Form.Label>Account Name</Form.Label> */}
                                        <Form.Control
                                          type="text"
                                          placeholder="Account Name"
                                          name="accName"
                                          defaultValue={accountName}
                                          onChange={this.handleInput}
                                        />
                                      </Form.Group>

                                      {/* <Form.Group>
                                                        <Form.Label>Upload Passport</Form.Label>
                                                        <Form.Control onChange={event => this.onFileChange1(event)} name="passport" type="file"  />
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Form.Label>Upload Identity Card</Form.Label>
                                                        <Form.Control onChange={event => this.onFileChange2(event)} name="idcard" type="file"  />
                                                    </Form.Group> */}

                                      <Button
                                        variant="primary"
                                        type="submit"
                                        onClick={this.updateBankInfo.bind(this)}
                                      >
                                        Update
                                      </Button>
                                    </Form.Row>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="profile-header mb-4">
                              {this.state.passportImage ? (
                                <Image
                                  src={`https://omnixbms.org/omnix_2.1/l/images/uploads/${this.state.passportImage}`}
                                  alt="Profle"
                                  className="octagon-style"
                                />
                              ) : (
                                <Image src={user1} alt="Profle" roundedCircle />
                              )}

                              <h3 className="name mt-3">Passport</h3>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="profile-header mb-4">
                              {this.state.idcardImage ? (
                                <Image
                                  src={`https://omnixbms.org/omnix_2.1/l/images/uploads/${this.state.idcardImage}`}
                                  alt="Profle"
                                  // roundedCircle
                                />
                              ) : (
                                <Image
                                  src={user1}
                                  alt="Profle"
                                  // roundedCircle
                                />
                              )}

                              <h3 className="name mt-3">ID Card</h3>
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Profile;
