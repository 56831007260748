import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import * as Icon from "react-feather";
import "./SideMenu.css";
import { lookUP } from "../../../api/functions";
// import Loader from '../components/loader/loader.component';
import {
  DashboardOutlined,
  CaretRightFilled,
  CaretDownFilled,
  ShoppingCartOutlined,
  AppstoreOutlined,
  SettingOutlined,
  ArrowRightOutlined,
  UserOutlined,
  FileTextOutlined,
  MoneyCollectOutlined,
  MessageOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { RetrieveSession } from "../../session/session";

class SideMenuDark extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      id: "",
      api_token: RetrieveSession(),
      sidebarStatus: false,
    };
  }

  componentDidMount() {
    this.getSideMenu();
  }

  getSideMenu() {
    lookUP(
      "addTeam",
      { inputs: { page: "sidebarAccess" } },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        this.setState({
          sidebarStatus: responseJson.success,
        });
      }
    );
  }

  render() {
    const { sidebarStatus } = this.state;
    return (
      <div
        className={`sidemenu-area ${
          this.props.sideMenu ? "" : "sidemenu-toggle"
        }`}
      >
        <Navbar
          className={`sidemenu ${this.props.sideMenu ? "" : "hide-nav-title"}`}
        >
          <Navbar.Collapse>
            <Nav>
              <NavLink to="/dashboard" className="nav-link">
                <DashboardOutlined className="icon" />
                <span className="title">Dashboard</span>
              </NavLink>
              {sidebarStatus == true ? (
                <NavLink to="/teamAccount" className="nav-link">
                  <Icon.Award className="icon" />
                  <span className="title">Team Account</span>
                </NavLink>
              ) : null}

              {/* <NavLink to={{pathname: 'https://trainning.omnixbmsdev.com/login'}} target="_blank" className="nav-link">
                            <Icon.Monitor  className="icon"  /> 
                            <span className="title">Training</span>
                            </NavLink>  */}

              <NavLink to="/user-profile" className="nav-link">
                <Icon.User className="icon" />
                <span className="title">Profile</span>
              </NavLink>
              <NavLink to="/withdrawal" className="nav-link">
                <Icon.DollarSign className="icon" />
                <span className="title">Transactions</span>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default SideMenuDark;
