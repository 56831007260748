import React from 'react';
class Footer extends React.Component {
    render(){
        return(
            <footer className="footer mt-2">
                <p>Copyright © 2021 omnixBMS. All Rights Reserved</p>
            </footer>
        )
    }
}

export default Footer;