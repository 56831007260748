import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Row, Form, Button, Image, Alert, Container } from "react-bootstrap";
import { lookUP, save, saveFile } from "../api/functions";
import Loader from "../components/Common/Loader";
import { RetrieveSession } from "../components/session/session";
import axios from "axios";
import Logo from "../assets/img/trans2.png";

const agentData = [
  { label: "Account Manager(Regular)", value: "1" },
  { label: "Spike Agent", value: "2" },
];

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      folio: "",
      email: "",
      phone: "",
      password: "",
      rePassword: "",
      address: "",
      teamId: this.props.match.params.code,
      idcard: null,
      idcardName: null,
      passport: null,
      passportName: null,
      loading: false,
      loadingVer: false,
      loadingLocal: false,
      agentList1: "",
      agentList2: "",
      agentList3: "",
      category: "",
      respo: "",
      conRes: "",
      gender: "",
      state: "",
      localGov: "",
      linkedIn: "",
      stateList: "",
      localGovList: "",
      verificationList: "",
      verification_type: "",
      verification_number: "",
      stateStatus: false,
      digit: 11,
      verificationStatus: false,
      isHidden: false,
      verificationError: "",
      agentType: "",
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectState = this.handleSelectState.bind(this);
    this.handleSelectVerification = this.handleSelectVerification.bind(this);
  }
  handleRoute = () => {
    this.props.history.push("/smsVerification");
  };
  handleSelectChange(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectState(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value, stateStatus: true });
  }

  handleSelectVerification(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  isChecked = () => {
    this.setState((state) => ({
      isHidden: !state.isHidden,
    })); /* we're setting the state value isHidden equal to the negation of previous value of isHidden onChange event of checkbox */
  };

  onFileChange1 = (event) => {
    // Update the state
    this.setState({
      passport: event.target.files[0],
      passportName: event.target.files[0].name,
    });
  };
  onFileChange2 = (event) => {
    // Update the state
    this.setState({
      idcard: event.target.files[0],
      idcardName: event.target.files[0].name,
    });
  };

  genderData = [
    {
      label: "Male",
      value: "m",
      key: "1",
    },
    {
      label: "Female",
      value: "f",
      key: "2",
    },
  ];

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    this.getAgent();
    this.getState();
    //this.getVerificationData();
  }

  getLocalGov() {
    this.setState({ stateStatus: false, loadingLocal: true });
    const formData = new FormData();
    formData.append("page", "getLga");
    formData.append("state_id", this.state.state);

    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbms.org/omnix_2.1/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        // console.log(response.data);
        try {
          this.setState({
            localGovList: response.data,
            loadingLocal: false,
          });
        } catch (error) {
          console.log(error);
        }
      });
  }

  getVerificationData() {
    this.setState({ digit: "", loadingVer: true });
    // alert('yes');

    const formData = new FormData();
    formData.append("page", "verify");
    formData.append("number", this.state.verification_number);
    formData.append("type", this.state.verification_type);
    // console.log(this.state.verification_type, this.state.verification_number);
    // return;
    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbms.org/omnix_2.1/l/index.php/bvnNinVerification",
        formData,
        headers
      )
      .then((response) => {
        console.log(response);
        // return;
        try {
          if (response.data.status == false) {
            alert(
              "Record not found, be sure your verification number is correct"
            );
            this.setState({
              loadingVer: false,
              verificationError: "Error in verification",
              digit: 30,
            });
            return false;
          }
          if (this.state.verification_type == "bvn") {
            if (response.data !== "") {
              this.setState({
                verificationList: response.data,
                loadingVer: false,
                folio: response.data.bvn_data.nameOnCard,
                phone: response.data.bvn_data.phoneNumber1,
                gender: response.data.bvn_data.gender,
                // stateOfOrigin:response.bvn_data.stateOfOrigin,
                // lgaOfOrigin: response.bvn_data.lgaOfOrigin,
              });
            }
          } else if (this.state.verification_type == "phone") {
            this.setState({
              verificationList: response.data,
              loadingVer: false,
              folio: `${response.data.data.firstname} ${response.data.data.surname}`,
              phone: response.data.data.telephoneno,
              gender: response.data.data.gender,
              // stateOfOrigin:response.bvn_data.stateOfOrigin,
              // lgaOfOrigin: response.bvn_data.lgaOfOrigin,
            });
          } else {
            if (response.data !== "") {
              this.setState({
                verificationList: response.data,
                loadingVer: false,
                folio: `${response.data.nin_data.firstname} ${response.data.nin_data.surname}`,
                phone: response.data.nin_data.telephoneno,
                gender: response.data.nin_data.gender,
                // stateOfOrigin:response.bvn_data.stateOfOrigin,
                // lgaOfOrigin: response.bvn_data.lgaOfOrigin,
              });
            }
          }
          //console.log(this.state.folio);
        } catch (error) {
          console.log(error);
        }
      });
  }

  getState() {
    const formData = new FormData();
    formData.append("page", "getstate");

    this.setState({ loading: true });
    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbms.org/omnix_2.1/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        // console.log(response);
        // return false;
        try {
          this.setState({
            stateList: response.data.data,
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      });
  }

  getAgent() {
    const formData = new FormData();
    formData.append("page", "getAgentList");

    this.setState({ loading: true });
    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbms.org/omnix_2.1/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        // console.log(response); return false;
        try {
          this.setState({
            agentList1: response.data[0],
            agentList2: response.data[1],
            agentList3: response.data[2],
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      });
  }

  registerCus = (e) => {
    const input = {
      folio: this.state.folio,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      rePassword: this.state.rePassword,
      address: this.state.address,
      page: "newclientWeb",
      linkedIn: this.state.linkedIn,
      gender: this.state.gender,
      state: this.state.state,
      lga: this.state.localGov,
      teamId: this.state.teamId === undefined ? "" : this.state.teamId,
      isHidden: this.state.isHidden,
    };

    // console.log(input);
    // return false;

    if (
      input.name == "" ||
      input.phone == "" ||
      input.email == "" ||
      input.gender == ""
    ) {
      alert("None of the asterisked fields must be empty");
      return false;
    }

    this.setState({ loading: true });

    e.preventDefault();

    const formData = new FormData();
    formData.append("folio", this.state.folio);
    formData.append("email", this.state.email);
    formData.append("state", this.state.state);
    formData.append("lga", this.state.localGov);
    formData.append("gender", this.state.gender);
    formData.append("password", this.state.password);
    formData.append("phone", this.state.phone);
    formData.append("rePassword", this.state.rePassword);
    // formData.append("agentType", this.state.agentType);
    formData.append("address", this.state.address);
    formData.append("agentCategory", this.state.agentType);
    formData.append("teamId", input.teamId);
    formData.append("isHidden", this.state.isHidden);
    // formData.append('passport', this.state.passport, this.state.passportName)
    formData.append("page", "newclientWeb");

    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .post(
        "https://omnixbms.org/omnix_2.1/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        //handle success
        console.log(response);
        if (response.data.success === true) {
          alert(
            `Successful\nVerification code has been sent to your phone and link to verify your email has been sent to your mail.`
          );
          console.log(
            `Successful\nVerification code has been sent to your phone and link to verify your email has been sent to your mail.`
          );
          this.props.history.push({
            pathname: "/smsVerification",
            data: {
              userId: response.data.data[0],
              phone: response.data.data[1],
            },
          });
          // this.props.history.push("/smsVerification");
          this.setState({
            respo: response.data.data,
            conRes: response.data.success,
            loading: false,
          });
        } else {
          this.setState({
            respo: response.data.data,
            conRes: response.data.success,
            loading: false,
          });
        }
      })
      .catch(function (response) {});
  };

  render() {
    // console.log(this.state.localGov);
    const {
      loading,
      email,
      password,
      respo,
      conRes,
      stateList,
      stateStatus,
      verificationStatus,
      linkedIn,
      localGovList,
      loadingLocal,
      verification_number,
      digit,
      loadingVer,
    } = this.state;

    if (RetrieveSession()) {
      return <Redirect to="/home" />;
    }

    return (
      <div className="auth-main-content">
        {verification_number.length == digit && digit != null
          ? this.getVerificationData()
          : null}

        {
          // verificationList = verificationList.map()
        }
        <div className="d-table">
          <div className="d-tablecell">
            <div className="login-logo">
              <Image className="login-logo" src={Logo} alt="Logo" />
            </div>
            <div className="auth-box">
              <Row>
                {conRes === true ? (
                  <Container>
                    <Alert
                      variant="success"
                      className="center"
                      style={{
                        justifySelf: "center",
                        alignItems: "center",
                        top: 10,
                      }}
                    >
                      {respo}
                    </Alert>
                  </Container>
                ) : conRes === false ? (
                  <Container>
                    <Alert
                      variant="danger"
                      className="center"
                      style={{
                        justifySelf: "center",
                        alignItems: "center",
                        top: 10,
                      }}
                    >
                      {respo}
                    </Alert>
                  </Container>
                ) : (
                  ""
                )}
                <div className="form-content">
                  <h1 className="heading">Omnix</h1>

                  {this.props.match.params.code != null && (
                    <Form.Group>
                      <Form.Label>Team ID</Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        name="teamId"
                        type="text"
                        value={this.props.match.params.code}
                        disabled
                      />
                    </Form.Group>
                  )}

                  {/* <Form.Group>
                    <Form.Label>Register With</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleSelectVerification}
                      name="verification_type"
                    >
                      <option value="">Select</option>
                      <option value="nin">NIN</option>
                      <option value="bvn">BVN</option>
                      <option value="phone">Phone Number</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Number</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="verification_number"
                      type="text"
                    />
                  </Form.Group> */}

                  {loadingVer == true ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: -10,
                        zIndex: 10,
                        width: "100%",
                      }}
                    >
                      <div class="spinner-border center">
                        <span class="sr-only center">Loading...</span>
                      </div>
                    </div>
                  ) : null}

                  <Form.Group>
                    <Form.Label>Full name.</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="folio"
                      type="text"
                      value={this.state.folio}
                      // readOnly
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="phone"
                      type="phone"
                      value={this.state.phone}
                      // readOnly
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>LinkedIn</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="linkedIn"
                      type="linkedIn"
                      value={this.state.linkedIn}
                      // readOnly
                    />
                  </Form.Group>

                  {/* <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="gender"
                      type="text"
                      value={this.state.gender}
                      // readOnly
                    />
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleSelectVerification}
                      name="gender"
                    >
                      <option value="">Gender</option>
                      {this.genderData
                        ? this.genderData.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          ))
                        : null}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="email"
                      type="email"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Select State of Residence</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleSelectState}
                      name="state"
                    >
                      <option value="">Residence State</option>
                      {stateList
                        ? stateList.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </Form.Control>
                  </Form.Group>
                  {loadingLocal == true ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: -10,
                        zIndex: 10,
                        width: "100%",
                      }}
                    >
                      <div class="spinner-border center">
                        <span class="sr-only center">Loading...</span>
                      </div>
                    </div>
                  ) : null}

                  {stateStatus !== false ? this.getLocalGov() : null}

                  {localGovList !== "" ? (
                    <Form.Group>
                      <Form.Label>Select Local-Government</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={this.handleSelectChange}
                        name="localGov"
                      >
                        <option value="">Local Government</option>
                        {localGovList
                          ? localGovList.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))
                          : null}
                      </Form.Control>
                    </Form.Group>
                  ) : null}

                  <Form.Group>
                    <Form.Label>Select Agent-Type</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleSelectChange}
                      name="agentType"
                    >
                      <option value="">Agent-Type</option>
                      {agentData
                        ? agentData.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          ))
                        : null}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                    />
                  </Form.Group>

                  {/*
                                            <Form.Group>
                                                <Form.Label>Re Password</Form.Label>
                                                <Form.Control onChange={this.handleChange} name="rePassword" type="password"  />
                                            </Form.Group>
                                            */}

                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      onChange={this.handleChange}
                      name="address"
                      type="text"
                    />
                  </Form.Group>

                  {this.props.match.params.code == null && (
                    <Form.Group>
                      <Form.Control
                        onChange={this.handleChange}
                        name="category"
                        type="hidden"
                        // value={this.state.agentList2.id}
                      />
                    </Form.Group>
                  )}

                  {/*
                                                <Form.Group>
                                            <Form.Label>Select Category</Form.Label>
                                            <Form.Control as="select" onChange={ this.handleSelectChange } name="category" > 
                                            <option value=''>Category</option>
                                           
                                            <option value={this.state.agentList2.id}>{this.state.agentList2.name}</option>
                                           
                                             </Form.Control>
                                             </Form.Group>

                                            */}

                  {/* <Form.Group>
                                                <Form.Label>NIN</Form.Label>
                                                <Form.Control onChange={this.handleChange} name="nin" type="text"  />
                                            </Form.Group> */}
                  {/* 
                                            job
                                            Account Managers
                                            Stock Takers */}
                  {/*      
                                            <Form.Group>
                                                <Form.Label>Upload Passport</Form.Label>
                                                <Form.Control onChange={event => this.onFileChange1(event)} name="passport" type="file"  />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Upload Identity Card</Form.Label>
                                                <Form.Control onChange={event => this.onFileChange2(event)} name="idcard" type="file"  />
                                            </Form.Group>

                                            
                                            <p>
                                            <input 
                                            type='checkbox'
                                            className='checkbox'
                                            id='termschkbx'
                                            onchange="isChecked(this,'sub1')"
                                            />
                                            <span>I agree to Terms & Conditions.<a href="https://omnixbms.com/agentsAgreements.php" className='readme'>Read here</a> </span></p>*/}

                  <div className="check">
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="termschkbx"
                      onChange={this.isChecked}
                    />

                    <span>
                      I agree to Terms & Conditions.
                      <a
                        href="https://omnixbms.com/manager_agreements.php"
                        className="readme"
                      >
                        Read here
                      </a>{" "}
                    </span>
                  </div>

                  <div className="text-center">
                    {loading == true ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          top: -10,
                          zIndex: 10,
                          width: "100%",
                        }}
                      >
                        <div class="spinner-border center">
                          <span class="sr-only center">Loading...</span>
                        </div>
                      </div>
                    ) : null}

                    <Button
                      // to="/dashboard/"
                      variant="primary"
                      type="submit"
                      onClick={this.registerCus}
                      disabled={this.state.isHidden ? false : "disabled"}
                    >
                      Create Account
                    </Button>

                    <Link
                      // to="/forgot-password/"
                      to="/login"
                      className="fp-link"
                    >
                      Login
                    </Link>
                  </div>

                  {/* </Form> */}
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Registration);
