import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
 
export default function DateRange({ getDateRange }) {
    const handleCallback = () => {
        let value = document.getElementById("daterange").value;
        getDateRange.getDateRange(value);
    }
     
    return (
        <DateRangePicker
            onApply={handleCallback}
        >
            <input type="text" name="daterange" id="daterange" className="form-control col-3" />
        </DateRangePicker>
    );
}