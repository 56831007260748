import React from "react";
import { Link, Redirect, withRouter, useLocation } from "react-router-dom";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import { lookUP, save } from "../../api/functions";
// import Loader from '../components/loader/loader.component';
import { RetrieveSession } from "../../components/session/session";
// Logo image file path
import Logo from "../../assets/img/trans2.png";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import './login.scss';Typography

class SmsVerification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      api_token: "",
      verificationCode: "",
      loading: false,
      phone: props.location.data.phone,
      userId: props.location.data.userId,
    };
  }

  handleLoginClick = () => {
    this.props.history.push("/");
  };
  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  smsVerification = () => {
    // setLoading(true);
    const input = {
      code: this.state.code,
      page: "smsverification",
      id: this.state.userId,
    };
    console.log(input);
    save("registerAgent", { inputs: input }, "", (responseJson) => {
      console.log(responseJson);
      //   return false;
      if (responseJson.success === true) {
        alert("Phone number verified! Proceed to sign in to your account.");
        this.props.history.push("/");
      } else if (
        responseJson.success === false &&
        responseJson.data === "Invalid code"
      ) {
        // setLoading(false);
        alert(responseJson.data);
      } else {
        // setLoading(false);
        alert(responseJson.data);
      }
      //   console.log(responseJson);
    });

    // setInterval(() => {
    //   this.setState({success: null})
    // }, 5000)
  };

  resendCode = () => {
    const input = {
      phone: this.state.phone,
      page2: "resmsverification",
      id: this.state.userId,
    };
    // setLoading(true);
    save("register", { inputs: input }, {}, (responseJson) => {
      // console.log(responseJson); return false;
      if (responseJson.success === true) {
        alert(responseJson.data);
        // setLoading(false);
      } else {
        // setLoading(false);
        alert(responseJson.data);
      }
    });
  };

  render() {
    const { loading, email, code } = this.state;

    // console.log(code);

    if (RetrieveSession()) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="auth-main-content">
        <div className="d-table">
          <div className="d-tablecell">
            {/* <Loader loading={loading} styles="login-loader" /> */}
            <div className="login-logo">
              <Image className="login-logo logo" src={Logo} alt="Logo" />
            </div>
            <div className="auth-box">
              <Row className="login-container">
                <Col>
                  <div className="form-content">
                    <h1 className="heading">Omnix</h1>

                    <Box
                      fontSize="h6.fontSize"
                      fontWeight="h6.fontWeight"
                      mb={4}
                      //   color="common.white"
                    >
                      Enter Verification Code to Proceed with Account
                      Registration
                    </Box>
                    <Form.Group>
                      {/* <Form.Label>Email address</Form.Label> */}
                      <Form.Control
                        onChange={this.handleChange}
                        name="code"
                        type="number"
                        value={code}
                        Placeholder="Enter Verification Code e.g 000111"
                      />
                    </Form.Group>

                    <div className="text-center">
                      <Button
                        // to="/dashboard/"
                        variant="primary"
                        type="submit"
                        onClick={this.smsVerification}
                      >
                        Verify Number
                      </Button>
                      <Box
                        className="fp-link"
                        color="primary.main"
                        onClick={this.resendCode}
                        style={{ cursor: "pointer" }}
                      >
                        Resend Code
                      </Box>

                      {/* <Link
                        // to="/forgot-password/"
                        to="/registration/"
                        className="fp-link"
                      >
                        Resend Code
                      </Link> */}
                      <Box fontSize="subtitle2.fontSize">
                        <Box mb={1}>
                          <Typography variant="body2">
                            Already have an account?
                          </Typography>
                        </Box>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.handleLoginClick}
                        >
                          Sign In
                        </Button>
                      </Box>
                      {/* <Link
                        // to="/forgot-password/"
                        to="/forgot-password/"
                        className="fp-link"
                      >
                        Forgot Password ?
                      </Link> */}
                    </div>
                    {/* </Form> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SmsVerification);
