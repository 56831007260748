import React, {useState} from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "./table.scss";

export default function TableComponent({ data }) {
  const [width, setWidth] = useState(window.innerWidth);
  // Code block for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [dataBackUp, setDataBackUp] = useState(data.rows ? data.rows : '');

  const handleSearch = event => {
    const {value, name} = event.target;
    setSearch({ [name]: value});
  }

  const lastIndex = currentPage * perPage;
  const firstIndex = lastIndex - perPage;

  const pageNumbers = [];

  if(data.rows){
    for(let i = 1; i <= Math.ceil(data.rows.length / perPage); i++) {
      pageNumbers.push(i);
    }
  }

  let post = data.rows ? data.rows.slice(firstIndex, lastIndex) : null;

  const searchList = (event) => {
    const {value, name} = event.target;
    
    const temp = post.filter(x => x.name.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1);
    console.log(temp);
  }

  return (

    <div>
      {
        width >= 1024

        ?

        <MDBDataTableV5
          hover
          entriesOptions={[10, 20, 50, 100]}
          entries={10}
          pagesAmount={4}
          data={data}
          pagingTop
          searchTop
          hover
          searchBottom={false}
          fullPagination
        />

        :

        post && post.length > 0

        ?

        <div>
          {/* <input type="text" className="table-search-box" placeholder="Search" name="search" onChange={searchList} /> */}
          {
            post.map((r, index) => (              
              <div key={index} className="table-container">
                {
                  data.columns.map((c, i) => (
                    c.field === Object.keys(r)[i] 
                    ? 
                    <div key={i} className="records">
                      <div>{c.label}</div>
                      <div>{Object.values(r)[i]}</div>
                    </div>
                    : 
                    null
                  ))
                }
              </div>
            ))
          }
          {/* Block code to display the pagination numbers inteligently */}
          <div className="navigation-container">
            <div className="navigation" onClick={() => currentPage > 1 ? setCurrentPage(currentPage - 1) : null}>{'<<'}</div>
              {
                pageNumbers.map(num => (
                  num <= 10 && currentPage <= 10
                  ?
                  <div key={num} className="navigation" onClick={() => setCurrentPage(num)}>{num}</div>
                  :
                  currentPage > 10 && currentPage - num <= 10 && num <= currentPage
                  ?
                  <div key={num} className="navigation-number" onClick={() => setCurrentPage(num)}>{num}</div>
                  :
                  null
                ))
              }
            <div className="navigation" onClick={() => currentPage < pageNumbers.length ? setCurrentPage(currentPage + 1) : null }>{'>>'}</div>
          </div>
        </div>

        :

        null
        
      }
    </div>
  );
}