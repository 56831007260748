import React from 'react';
import { Link, Redirect } from "react-router-dom";
import {Row, Form, Button, Image, Alert, Container } from 'react-bootstrap'; 
import { lookUP, save, saveFile } from '../api/functions';
import Loader from '../components/Common/Loader';
import { RetrieveSession } from '../components/session/session';
import axios from 'axios';
import Logo from '../assets/img/trans2.png';

class ImageUpload extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            idcard: null,
            idcardName: null,
            passport: null,
            passportName: null,
            loading: false,
            referral: '',
            respo: '',
            conRes: '',
        }
    }


    onFileChange1 = event => { 
        // Update the state 
        this.setState({ passport: event.target.files[0], passportName: event.target.files[0].name}); 
        
      }; 
      onFileChange2 = event => { 
        // Update the state 
        this.setState({ idcard: event.target.files[0], idcardName: event.target.files[0].name }); 
        
      }; 

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ [name]: value});
    }



    updateImages = (e) => {
        const input = {  folio: this.state.referral, idcard: this.state.idcard, passport: this.state.passport}
        
        // console.log(input);
        // return false;

        if(input.referral == '' || this.state.idcardName == null|| this.state.passportName == null || this.state.idcard == null || this.state.passport == null ) {
            alert("None of the fields must be empty");
            return false;
        }

        this.setState({loading: true})

        e.preventDefault();
        
        const formData = new FormData();
        formData.append('ref', this.state.referral) 
        formData.append('idcard', this.state.idcard, this.state.idcarName)
        formData.append('passport', this.state.passport, this.state.passportName)
        formData.append('page', 'uploadInfo')
    
        var headers = {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*"
        }
    
        axios.post('https://omnixbmsdev.com/mobile/l/index.php/registerAgent', formData, headers)
        .then(response => {
           //handle success
        //    console.log(response.data.success);
           if(response.data.success == 'true'){
            this.setState({
                respo: response.data.data,
                conRes: response.data.success,
                loading: false
            })
           } else{
            this.setState({
                respo: response.data.data,
                conRes: response.data.success,
                loading: false
            })
           }
      })
      .catch(function (response) {
       
      });
               
    }


    render() {
        // console.log(this.state.localGov);
        const { loading, email, password, respo, conRes, stateList, stateStatus, localGovList, loadingLocal } = this.state;

        if(RetrieveSession()) {return(<Redirect to='/home' />)}

        return (
            <div className="auth-main-content">
                <div className="d-table">
                    <div className="d-tablecell"> 
                   
                    <div className="login-logo">
                         <Image className="login-logo" src={Logo} alt="Logo" /> 
                    </div>
                        <div className="auth-box">
                            <Row> 
                                { conRes === true ?
                                    <Container>
                                    <Alert variant="success" className="center" style={{justifySelf: 'center', alignItems: 'center', top: 10}}>
                                        {respo}
                                    </Alert>
                                    </Container>
                                    :
                                    conRes === false
                                    ?
                                    <Container>
                                    <Alert variant="danger" className="center" style={{justifySelf: 'center', alignItems: 'center', top: 10}}>
                                        {respo}
                                    </Alert>
                                    </Container>
                                    :
                                    ""
                                }
                                    <div className="form-content">
                                        <h1 className="heading">Omnix</h1> 

                                        

                                        {
                                            this.props.match.params.code != null &&

                                                <Form.Group>
                                                <Form.Label>Team ID</Form.Label>
                                                <Form.Control onChange={this.handleChange} name="teamId" type="text" value={this.props.match.params.code} disabled  />

                                               

                                                </Form.Group>
                                        }


                                        
                                        


                                            <Form.Group>
                                                {/* <Form.Label>Referral id</Form.Label> */}
                                                <Form.Control placeholder="Enter referral id or email" onChange={this.handleChange} name="referral" type="phone"  />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Upload Passport</Form.Label>
                                                <Form.Control onChange={event => this.onFileChange1(event)} name="passport" type="file"  />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Upload Identity Card</Form.Label>
                                                <Form.Control onChange={event => this.onFileChange2(event)} name="idcard" type="file"  />
                                            </Form.Group>

                                            <div className="text-center">
                                            {
                                                loading == true ?
                                               
                                                <div style={{display: 'flex', justifyContent:'center',alignItems: 'center', top: -10, zIndex: 10, width: '100%'}}>
                                                    <div class="spinner-border center" >
                                                        <span class="sr-only center">Loading...</span>
                                                    </div>
                                                </div>
                                            
                                                : null
                                            }
                                                <Button to='/dashboard/' variant="primary" type="submit" onClick={this.updateImages}>
                                                   Update
                                                </Button>
                                        
                                                <Link 
                                                    // to="/forgot-password/" 
                                                     to="/login" 
                                                    className="fp-link">
                                                    Login
                                                </Link>
                                            </div>
                                            
                                        {/* </Form> */}
                                    </div> 
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageUpload;