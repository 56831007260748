import React from 'react';
import {Row, Col, Breadcrumb, Tabs, Tab, Image } from 'react-bootstrap';
import user1 from '../../assets/img/user/user1.jpg';
import { Link,  useLocation } from "react-router-dom";
import Navigation from '../../components/Navigation/Navigation';
import Footer from '../Footer/Footer';
import {TotalConversionTab, TotalSignupTab, TaxPercentageTab} from './tabs';
import { lookUP, save, saveChanges } from '../../api/functions';
import { format } from '../../components/number-format/number-format.component';
import {TeamModal, AccountDetailsModal} from './modal';
import { RetrieveSession } from '../../components/session/session';
import TableComponent from '../../components/table/table';
import Loader from '../../components/Common/Loader';
import * as Icon from 'react-feather';
import { getMembers } from './functions';


class TeamMember extends React.Component {
    constructor(props){
        super(props);

        this.state = { sideMenu: true, api_token: RetrieveSession(), memberData: {},  loading: true, response: '', status: '', agentRefId: this.props.match.params.agentRefId, agentSummaryData: {}, passportImage: '', idcardImage: '', bankName: '', accountName: '', accountNumber: ''
        };
    }

    _onSideMenu = (active) => this.setState({sideMenu: active});

    retrievBankInfo() {
         
        lookUP('addTeam', {inputs: {page: 'agentDetails', web:'web'}}, this.state.api_token, responseJson => {
            // console.log(responseJson);
            // return false;
            try {
              
                this.setState({
                    bankName: responseJson.bank_name, accountName: responseJson.account_name, accountNumber: responseJson.account_number, loading: false
                })
                   
            } catch (error) {
              console.log(error);
            }
          });
     }

    getFunctions = () => {
        getMembers(this.state.api_token, this.setMembers.bind(this), this.state.agentRefId);
        // this.retrievBankInfo();
      
    }

    // setAtion = (id, admin) =>{
      
    //     lookUP('addTeam', { inputs: { page: 'setAdmin', web: 'web', id: id , admin: admin} }, this.state.api_token, responseJson => {
    //         this.setState({loading: true});
    //         this.getFunctions();  
    //         alert(responseJson.data)

    //     });

    // }

    //  retrievImages() {
         
    //     lookUP('addTeam', {inputs: {page: 'agentDetails', web:'web'}}, this.state.api_token, responseJson => {
    //         // console.log(responseJson);
    //         // return false;
    //         try {
              
    //             this.setState({
    //                 passportImage: responseJson.passport, idcardImage: responseJson.idcard, loading: false
    //             })
                   
    //         } catch (error) {
    //           console.log(error);
    //         }
    //       });
    //  }

    componentDidMount = () => this.getFunctions();

    setMembers = (obj, agent_summary_data) => this.setState({  memberData: obj, agentSummaryData: agent_summary_data, passportImage: agent_summary_data.passport, idcardImage: agent_summary_data.idcard, accountName: agent_summary_data.account_name, accountNumber: agent_summary_data.account_number, bankName: agent_summary_data.bank_name,  loading: false });

    // setVendors = (obj, ven) => this.setState({ vendorData: obj, vendors: ven, loading: false });

    // setCreditors = (obj) => this.setState({creditorData: obj, loading: false });

    // setDebtors = (obj) => this.setState({ debtorData: obj, loading: false });
    
    // setLoyalty = (obj, loyaltyExist, loyalty_percentage, usable_level, loyaltyStatus) => this.setState({ loyaltyData: obj, loyaltyExist: loyaltyExist, loyalty_percentage: loyalty_percentage, maturity_level: usable_level, loyaltyStatus: loyaltyStatus, loading: false });

    handleDropDownSelect = (event, data) => this.setState({gender: data.value});

    handleInput = event => {
        const { value, name } = event.target;
        this.setState({[name]: value}); 
    }

    // createTeam() {
    //     const input = {  name: this.state.name, team_target: this.state.team_target, team_member_taget: this.state.team_member_taget, team_size: this.state.team_size, logistic_percentage: this.state.logistic_percentage, task_unit_value: this.state.task_unit_value, page: 'registerTeam'}
    //     //console.log(input); return false;
    //     this.setState({loading: true});
    //     insertTeam(input, this.state.api_token, this.setResponse);
    //     this.setState({loading: false});

    // }

    setResponse = (response, status) => this.setState({response: response, status: status});

      
    handleClick = () => this.state.loyaltyStatus == 0 ? this.setState({loyaltyStatus: 1}) : this.setState({loyaltyStatus: 0});

     
    render() {
        // console.log(this.state.accountName);
        const { loading, agentSummaryData, memberData } = this.state;

        return (
            <div className="page-wrapper">
                {/* Navigation */}
             <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? 'hide-sidemenu' : ''}`}>
                    <Loader loading={loading} />
                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Team Members</h1>
                            <Link to="/teamAccount" className="breadcrumb-item">
                                Team Account
                            </Link>
                            {/* <Breadcrumb.Item active>Customers</Breadcrumb.Item> */}
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}
           

                     <Row>
                        <Col sm={3} lg={4}> {TotalConversionTab(agentSummaryData.total_conversion)} </Col>

                        <Col sm={3} lg={4}> {TotalSignupTab(agentSummaryData.total_signup)} </Col>

                        <Col sm={3} lg={4}> {TaxPercentageTab(`${agentSummaryData.task_percentage}%`)}</Col>
                    </Row>

                    {/* Basic Table */}
                    <Row style={{marginTop: 10}}>

                         {/* Tab Demo One */}
                         <Col xl={8}>
                            <div className="card mb-4">
                                <div className="card-body">
                                   
                                    <div className="tabs-style-one">
                                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                            <Tab eventKey="home" title="Team">
                                           
                                                <div className="before-table-header-short">

                                                 {AccountDetailsModal( this.state.bankName, this.state.accountNumber, this.state.accountName)}

                                               
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getFunctions();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                             <TableComponent data={memberData} />

                                        </Tab>

                                        
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </Col>
            
                        <Col lg={2}>
                            <div className="profile-header mb-4">
                            {
                                this.state.passportImage ?
                                <Image 
                                    src={`https://omnixbmsdev.com/mobile/ag/upload/${this.state.passportImage}`}
                                    alt="Profle" 
                                    roundedCircle 
                                />
                                :
                                 <Image 
                                    src={user1} 
                                    alt="Profle" 
                                    roundedCircle 
                                />
                            }
                               
                                <h3 className="name mt-3">Passport</h3>
                            </div>
                        </Col>
                        <Col lg={2}>
                            <div className="profile-header mb-4">
                            {
                                this.state.idcardImage ?
                                 <Image 
                                    src={`https://omnixbmsdev.com/mobile/ag/upload/${this.state.idcardImage}`}
                                    alt="Profle" 
                                    // roundedCircle 
                                />
                                : 
                                <Image 
                                    src={user1} 
                                    alt="Profle" 
                                    roundedCircle 
                                />
                            }
                               
                                <h3 className="name mt-3">ID Card</h3>
                            </div>
                        </Col>

                    </Row>
                     
                    <div className="flex-grow-1"></div>
                    <Footer /> 
                </div>
            </div>
        );
    }
}

export default TeamMember;