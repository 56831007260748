import React from 'react';
import TopTab from '../../components/tabs/top-tab';

export const WalletBalance = (data) => {
    return (
        <TopTab data={data} text="Wallet Balance" icon="bag-alt" color="purple" />
    );
};

export const TotalEarning = (data) => {
    return (
        <TopTab data={data} text="Total Earning" icon="badge" color="light-blue" />
    );
};

export const TotalWithdraw = (data) => {
    return (
        <TopTab data={data} text="Total Withdral" icon="pay" color="success" />
    );
};

export const PendingWithdrawalTab = (data) => {
    return (
        <TopTab data={data} text="Pending Withdral" icon="automation" color="danger" />
    );
};

export const LoyaltyTab = (data) => {
    return (
        <TopTab data={data} text="Loyalty" icon="lni-users" color="purple" />
    );
};