import React from 'react';
import Icofont from 'react-icofont';

const TopTab = ({data, text, icon, color}) => {
    return (
        <div className={`stats-card ${color}-card mb-4"`}>
            <h6>{data}</h6>
            <p>{text}</p>
            <Icofont 
                icon={icon} 
                className="icofont-2x"
            />
            {/* <i className={icon} /> */}
        </div>
    );
};

export default TopTab;