import React from 'react';
import TopTab from '../../components/tabs/top-tab';


export const TeamMemberTab = (data) => {
    return (
        <TopTab data={data} text="Team Member" icon="users" color="purple" />
    );
};

export const AccountCreatedTab = (data) => {
    return (
        <TopTab data={data} text="Account Created" icon="bag-alt" color="light-blue" />
    );
};

export const SignupEarningTab = (data) => {
    return (
        <TopTab data={data} text="Signup Earning" icon="pay" color="success" />
    );
};

export const ConversionEarningTab = (data) => {
    return (
        <TopTab data={data} text="Conversion Earning" icon="badge" color="danger" />
    );
};

export const TaxPercentageTab = (data) => {
    return (
        <TopTab data={data} text="Percentage of Task" icon="site-map" color="purple" />
    );
};

export const TotalConversionTab = (data) => {
    return (
        <TopTab data={data} text="Total Conversion" icon="badge" color="success" />
    );
};

export const TotalSignupTab = (data) => {
    return (
        <TopTab data={data} text="Total Signup" icon="users" color="light-blue" />
    );
};






export const AgentsTotalRegistration = (data) => {
    return (
        <TopTab data={data} text="Total Signup" icon="users" color="success" />
    );
};

export const AgentYearRegistration = (data) => {
    return (
        <TopTab data={data} text="SignUp for the Year" icon="users" color="purple" />
    );
};

export const AgentMonthRegistration = (data) => {
    return (
        <TopTab data={data} text="Signup for the Month" icon="users" color="purple" />
    );
};

export const AgentRegisteredToday = (data) => {
    return (
        <TopTab data={data} text="Signup Today" icon="users" color="light-blue" />
    );
};

