import React from "react";
import { Row, Col, Breadcrumb, Tabs, Tab, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../Footer/Footer";

import Box from "@mui/material/Box";
import {
  TotalConversionTab,
  TotalSignupTab,
  TaxPercentageTab,
} from "../team/tabs";
import { lookUP, save, saveChanges } from "../../api/functions";
import { format } from "../../components/number-format/number-format.component";
import { AcceptTermsModal } from "../team/modal";
import { RetrieveSession } from "../../components/session/session";
import TableComponent from "../../components/table/table";
import Loader from "../../components/Common/Loader";
import * as Icon from "react-feather";
import { getHomeMembers } from "../team/functions";
import copy from "copy-to-clipboard";
import LeadAgentGuild from "./agentModal";
// import { Facebook, Youtube,Twitter, Instagram } from 'reactjs-social-embed';

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import "./dash-styles.css";

class DashboardTeamMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideMenu: true,
      api_token: RetrieveSession(),
      memberData: {},
      loading: false,
      response: "",
      status: "",
      agentRefId: this.props.match.params.agentRefId,
      agentSummaryData: {},
      welcomeName: "",
      totalCon: 0,
      totalSign: 0,
      taskPer: 0,
      agent_name: "",
      agent_ref: "",
      accept_terms: null,
      agree: "",
      agent_type: "",
      checkTeam: "",
      showTooltip: false,
      copied: false,
    };
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  _onSideMenu = (active) => this.setState({ sideMenu: active });

  getFunctions = () => {
    getHomeMembers(this.state.api_token, this.setMembers.bind(this));
    // this.revoke();
  };

  dashboardInfo() {
    lookUP(
      "addTeam",
      { inputs: { page: "agentDetails", web: "web" } },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        // return false;
        try {
          this.setState({
            agent_name: responseJson.name,
            agent_ref: responseJson.ref_id,
            accept_terms: responseJson.accept_terms,
            agent_type: responseJson.agent_type,
            checkTeam: responseJson.team_where,
          });
          //   if (!this.dashboardInfo()) {
          //     this.revoke();
          //   }
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  revoke() {
    this.getFunctions();
    this.dashboardInfo();
  }

  componentDidMount = () => {
    this.getFunctions();
    //this.revoke();
    this.dashboardInfo();
  };

  copyToClipboard = () => {
    copy(`http://appregistration.omnixbms.com/${this.state.agent_ref}`);
    alert("Copied to clipboard");
  };

  setMembers = (obj, agent_summary_data) =>
    this.setState({
      memberData: obj,
      agentSummaryData: agent_summary_data,
      totalCon: agent_summary_data.total_conversion,
      totalSign: agent_summary_data.total_signup,
      taskPer: agent_summary_data.task_percentage,
      loading: false,
    });

  // setVendors = (obj, ven) => this.setState({ vendorData: obj, vendors: ven, loading: false });

  // setCreditors = (obj) => this.setState({creditorData: obj, loading: false });

  // setDebtors = (obj) => this.setState({ debtorData: obj, loading: false });

  // setLoyalty = (obj, loyaltyExist, loyalty_percentage, usable_level, loyaltyStatus) => this.setState({ loyaltyData: obj, loyaltyExist: loyaltyExist, loyalty_percentage: loyalty_percentage, maturity_level: usable_level, loyaltyStatus: loyaltyStatus, loading: false });

  handleDropDownSelect = (event, data) => this.setState({ gender: data.value });

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleCheck = () => {
    this.setState({ agree: 1 });
  };

  updateTermsKeep = () => {
    alert(this.state.agree);
  };

  updateTerms = () => {
    if (
      this.state.agree == "" ||
      this.state.agree == 0 ||
      this.state.agree == null
    ) {
      alert("Kindly agree to the terms and conditions!");
      return false;
    }

    //   alert(this.state.agree);
    //  return false;

    this.setState({
      loading: true,
    });

    lookUP(
      "addTeam",
      {
        inputs: {
          page: "updateTerms",
          agree: this.state.agree,
          web: "web",
        },
      },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        // return false;
        try {
          this.setState({
            loading: false,
          });
          if (responseJson.success == true) {
            alert("Agreement accepted");
            window.location.reload();
          } else {
            alert("An error occur! please try again");
          }

          // this.retrievBankInfo();
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  // createTeam() {
  //     const input = {  name: this.state.name, team_target: this.state.team_target, team_member_taget: this.state.team_member_taget, team_size: this.state.team_size, logistic_percentage: this.state.logistic_percentage, task_unit_value: this.state.task_unit_value, page: 'registerTeam'}
  //     //console.log(input); return false;
  //     this.setState({loading: true});
  //     insertTeam(input, this.state.api_token, this.setResponse);
  //     this.setState({loading: false});

  // }

  setResponse = (response, status) =>
    this.setState({ response: response, status: status });

  handleClick = () =>
    this.state.loyaltyStatus == 0
      ? this.setState({ loyaltyStatus: 1 })
      : this.setState({ loyaltyStatus: 0 });

  // copyToClipboard() {
  //   const link = window.location.href;
  //   navigator.clipboard.writeText(
  //     `http://appregistration.omnixbms.com/${this.state.agent_ref}`
  //   );
  //   this.setState({ copied: true });
  //   setTimeout(() => {
  //     this.setState({ copied: false });
  //   }, 3000);
  // }

  handleMouseEnter() {
    this.setState({ showTooltip: true });
  }

  handleMouseLeave() {
    this.setState({ showTooltip: false });
  }

  render() {
    const {
      loading,
      memberData,
      totalCon,
      totalSign,
      taskPer,
      agent_name,
      agent_ref,
      accept_terms,
      agent_type,
      checkTeam,
      showTooltip,
      copied,
    } = this.state;
    console.log(agent_type);
    const shareUrl = `https://appregistration.omnixbms.com/${agent_ref}`;
    const quote = `Hello,  I am so excited to introduce Omnix - your 1 Business accounting and Inventory management App Omnix will help you
    ✔️track your daily sales/inventory
    ✔️track debtors & recover debts 5x faster 
    ✔️increase retention
    ✔️use customer loyalty tool to increase sales and much more. 
    
    💰Stay ahead of your cashflow and get financing opportunity for your business growth when you use Omnix!!
    
    Amazing right?
    
    Yes, but more exciting is;
     
    💃You can use Omnix absolutely for Free on the Free Plan or get up to 2 months free🕺subscription (on upgrades).
    
    Sign up now and Download the Omnix app (insert link)
    
    Join the train now and start running your business with ease.
    
    💥Hurry now and sign up for free (insert link)
    
    Best wishes`;

    return (
      <div className="page-wrapper">
        {/* Navigation */}
        <Navigation onClick={this._onSideMenu} />
        {/* End Navigation */}

        <div
          className={`main-content d-flex flex-column ${
            this.state.sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <Loader loading={loading} />
          {/* Breadcrumb */}

          <div className="main-content-header">
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb>
                {agent_name != "" && (
                  <h1>
                    Welcome back <strong>{agent_name}</strong>, you are{" "}
                    <strong>
                      {agent_type === "1"
                        ? "Account Manager"
                        : agent_type === "2"
                        ? "Spike Agent"
                        : agent_type === "5"
                        ? "Classic Lead Agent"
                        : agent_type === "6"
                        ? "Legend Lead Agent"
                        : null}
                    </strong>
                  </h1>
                )}

                <p></p>
              </Breadcrumb>
              {agent_type === "1" && checkTeam !== "other_team" && (
                // <Link to="./leadAgentGuild" class="btn ">
                //   Become a Lead Agent
                // </Link>

                <LeadAgentGuild />
              )}
            </Box>
            <p></p>
            <Breadcrumb>
              {agent_ref != "" && (
                <h1>
                  Agent Ref Id: <strong>{agent_ref}</strong>
                </h1>
              )}
            </Breadcrumb>
            <p></p>
            <div className="copy-link-container">
              <span
                className="copy-link-text"
                onClick={this.copyToClipboard}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
              >
                {shareUrl}
              </span>
              {showTooltip && (
                <span className="copy-link-tooltip">
                  Click to copy to clipboard
                </span>
              )}
            </div>
            <p></p>
            Share with:{" "}
            <p>
              <TwitterShareButton url={shareUrl} quote={"Register with Omnix"}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>

              <FacebookShareButton url={shareUrl} quote={quote}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>

              <WhatsappShareButton url={shareUrl} quote={quote}>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>

              <LinkedinShareButton url={shareUrl} quote={quote}>
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>

              <EmailShareButton url={shareUrl} quote={quote}>
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
            </p>
          </div>
          {/* <Breadcrumb>
            {agent_type === "1" ? (
              <h5>
                <strong>Account Manager</strong>
              </h5>
            ) : agent_type === "2" ? (
              <h5>
                <strong>Spike Agent</strong>
              </h5>
            ) : agent_type === "5" ? (
              <h5>
                <strong>Classic Lead Agent</strong>
              </h5>
            ) : agent_type === "6" ? (
              <h5>
                <strong>Legend Lead Agent</strong>
              </h5>
            ) : null}
          </Breadcrumb> */}
          {/* End Breadcrumb */}

          <Row>
            <Col sm={3} lg={4}>
              {" "}
              {TotalConversionTab(totalCon)}{" "}
            </Col>

            <Col sm={3} lg={4}>
              {" "}
              {TotalSignupTab(totalSign)}{" "}
            </Col>

            <Col sm={3} lg={4}>
              {" "}
              {TaxPercentageTab(`${taskPer}%`)}
            </Col>
          </Row>

          {/* Basic Table */}
          <Row style={{ marginTop: 10 }}>
            {/* Tab Demo One */}
            <Col xl={12}>
              <div className="card mb-4">
                <div className="card-body">
                  <div className="tabs-style-one">
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                      <Tab eventKey="home" title="Team">
                        <div className="before-table-header-short">
                          {accept_terms == 0 ? (
                            <AcceptTermsModal
                              agent_name={this.state.agent_name}
                              handleInput={this.handleCheck}
                              updateTerms={this.updateTerms}
                            />
                          ) : (
                            ""
                          )}
                          {/* <Link 
                                                    // to="/forgot-password/" 
                                                     to="/team-view-account" 
                                                    className="fp-link">
                                                <Button variant="primary" type="submit">
                                                   View agents
                                                </Button>
                                                </Link> */}
                          <Icon.RefreshCcw
                            className="icon"
                            style={{ cursor: "pointer", color: "yellowgreen" }}
                            onClick={() => {
                              this.setState({ loading: true });
                              this.getFunctions();
                            }}
                          />
                          Refresh
                        </div>
                        <TableComponent data={memberData} />
                      </Tab>
                      <Tab eventKey="expire" title="Client Subscription">
                        <div className="before-table-header-short">
                          {accept_terms == 0 ? (
                            <AcceptTermsModal
                              agent_name={this.state.agent_name}
                              handleInput={this.handleCheck}
                              updateTerms={this.updateTerms}
                            />
                          ) : (
                            ""
                          )}
                          {/* <Link 
                                                    // to="/forgot-password/" 
                                                     to="/team-view-account" 
                                                    className="fp-link">
                                                <Button variant="primary" type="submit">
                                                   View agents
                                                </Button>
                                                </Link> */}
                          <Icon.RefreshCcw
                            className="icon"
                            style={{ cursor: "pointer", color: "yellowgreen" }}
                            onClick={() => {
                              this.setState({ loading: true });
                              this.getFunctions();
                            }}
                          />
                          Refresh
                        </div>
                        <TableComponent data={memberData} />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Col>

            {/* <iframe width="782" height="440" src="https://www.youtube.com/embed/BuHB3T1qXJM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

            {/*
                        <Facebook type="post" width="100%" height="680px" show_text={true} 
url="https://www.facebook.com/postmalone/posts/639710980338504" />
 <Facebook type="video" width="100%" height="680px" show_text={true} 
url="https://www.facebook.com/postmalone/videos/639710980338504/" /> */}

            {/*
<Youtube width="30%" parentClass="p-container" iframeClass="v-container" id="w5GrxfjuTTI" /> 
<Instagram url="https://www.instagram.com/p/B8zB3rclo1H/" />	

<Twitter id="1169826984433606656" /> */}
          </Row>

          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default DashboardTeamMember;
