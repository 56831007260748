import React from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Row,
  Form,
  Button,
  Image,
  Alert,
  Container,
  Card,
  Modal,
} from "react-bootstrap";
import affiliateImage from "../../affiliates3.jpeg";
import { lookUP, save, saveFile } from "../../api/functions";
import Loader from "../../components/Common/Loader";
import { RetrieveSession } from "../../components/session/session";
import axios from "axios";
import Logo from "../../assets/img/trans2.png";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LeadAgentModal } from "./modal";
import Box from "@mui/material/Box";

const leadData = [
  { label: "Classic Lead Agent(CLA)", value: "5" },
  { label: "Legend Lead Agent(LLA)", value: "6" },
];

class LeadAgentGuild extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      leadType: "",
      api_token: RetrieveSession(),
      response: "",
      status: "",
      show: false,
      loading: false,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectState = this.handleSelectState.bind(this);
    this.handleSelectVerification = this.handleSelectVerification.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSelectChange(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectState(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value, stateStatus: true });
  }

  handleSelectVerification(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  isChecked = () => {
    this.setState((state) => ({
      isHidden: !state.isHidden,
    })); /* we're setting the state value isHidden equal to the negation of previous value of isHidden onChange event of checkbox */
  };

  onFileChange1 = (event) => {
    // Update the state
    this.setState({
      passport: event.target.files[0],
      passportName: event.target.files[0].name,
    });
  };
  onFileChange2 = (event) => {
    // Update the state
    this.setState({
      idcard: event.target.files[0],
      idcardName: event.target.files[0].name,
    });
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };
  handleClose() {
    this.setState({ show: !this.state.show });
  }

  createTeam() {
    const input = {
      name: this.state.name,
      agentCategory: this.state.leadType,
      page: "registerTeam",
    };
    this.setState({ loading: true });
    // console.log(input, this.state.api_token);
    // return;
    save("addTeam", { inputs: input }, this.state.api_token, (responseJson) => {
      console.log(responseJson);
      //   return false;
      if (responseJson.success === true) {
        this.setState({
          response: responseJson.data,
          status: "success",
          loading: false,
        });
      } else {
        this.setState({
          response: responseJson.data,
          status: "danger",
          loading: false,
        });
      }
    });
  }

  // getLocalGov() {
  //   this.setState({ stateStatus: false, loadingLocal: true });
  //   const formData = new FormData();
  //   formData.append("page", "getLga");
  //   formData.append("state_id", this.state.state);

  //   var headers = {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };
  //   axios
  //     .post(
  //       "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
  //       formData,
  //       headers
  //     )
  //     .then((response) => {
  //       try {
  //         this.setState({
  //           localGovList: response.data,
  //           loadingLocal: false,
  //         });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     });
  // }

  // getVerificationData() {
  //   this.setState({ digit: 5, loadingVer: true });
  //   // alert('yes');

  //   const formData = new FormData();
  //   formData.append("page", "verify");
  //   formData.append("number", this.state.verification_number);
  //   formData.append("type", this.state.verification_type);

  //   var headers = {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };
  //   axios
  //     .post(
  //       "https://omnixbmsdev.com/mobile/l/index.php/bvnNinVerification",
  //       formData,
  //       headers
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       try {
  //         if (this.state.verification_type == "bvn") {
  //           this.setState({
  //             verificationList: response.data,
  //             loadingVer: false,
  //             folio: response.data.bvn_data.nameOnCard,
  //             phone: response.data.bvn_data.phoneNumber1,
  //           });
  //         } else {
  //           this.setState({
  //             verificationList: response.data,
  //             loadingVer: false,
  //             folio: `${response.data.nin_data.firstname} ${response.data.nin_data.surname}`,
  //             phone: response.data.nin_data.telephoneno,
  //           });
  //         }
  //         //console.log(this.state.folio);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     });
  // }

  // getState() {
  //   const formData = new FormData();
  //   formData.append("page", "getstate");

  //   this.setState({ loading: true });
  //   var headers = {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };
  //   axios
  //     .post(
  //       "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
  //       formData,
  //       headers
  //     )
  //     .then((response) => {
  //       try {
  //         this.setState({
  //           stateList: response.data.data,
  //           loading: false,
  //         });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     });
  // }

  // getAgent() {
  //   const formData = new FormData();
  //   formData.append("page", "getAgentList");

  //   this.setState({ loading: true });
  //   var headers = {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };
  //   axios
  //     .post(
  //       "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
  //       formData,
  //       headers
  //     )
  //     .then((response) => {
  //       try {
  //         this.setState({
  //           agentList1: response.data[0],
  //           agentList2: response.data[1],
  //           agentList3: response.data[2],
  //           loading: false,
  //         });
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     });
  // }

  // registerCus = (e) => {
  //   const input = {
  //     folio: this.state.folio,
  //     email: this.state.email,
  //     phone: this.state.phone,
  //     password: this.state.password,
  //     rePassword: this.state.rePassword,
  //     address: this.state.address,
  //     page: "newclientWeb",
  //     gender: this.state.gender,
  //     state: this.state.state,
  //     lga: this.state.localGov,
  //     teamId: this.state.teamId,
  //     isHidden: this.state.isHidden,
  //   };

  //   if (
  //     input.name == "" ||
  //     input.phone == "" ||
  //     input.email == "" ||
  //     input.gender == ""
  //   ) {
  //     alert("None of the asterisked fields must be empty");
  //     return false;
  //   }

  //   this.setState({ loading: true });

  //   e.preventDefault();

  //   const formData = new FormData();
  //   formData.append("folio", this.state.folio);
  //   formData.append("email", this.state.email);
  //   formData.append("state", this.state.state);
  //   formData.append("lga", this.state.localGov);
  //   formData.append("gender", this.state.gender);
  //   formData.append("password", this.state.password);
  //   formData.append("phone", this.state.phone);
  //   formData.append("rePassword", this.state.rePassword);
  //   formData.append("address", this.state.address);
  //   formData.append("agentCategory", this.state.category);
  //   formData.append("teamId", this.state.teamId);
  //   formData.append("isHidden", this.state.isHidden);
  //   formData.append("page", "newclientWeb");

  //   var headers = {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //   };

  //   axios
  //     .post(
  //       "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
  //       formData,
  //       headers
  //     )
  //     .then((response) => {
  //       if (response.data.success == "true") {
  //         this.setState({
  //           respo: response.data.data,
  //           conRes: response.data.success,
  //           loading: false,
  //         });
  //       } else {
  //         this.setState({
  //           respo: response.data.data,
  //           conRes: response.data.success,
  //           loading: false,
  //         });
  //       }
  //     })
  //     .catch(function (response) {});
  // };

  render() {
    // console.log(this.state.localGov);
    const {
      loading,
      email,
      password,
      respo,
      conRes,
      stateList,
      stateStatus,
      verificationStatus,
      localGovList,
      show,
      response,
      status,
      name,
    } = this.state;
    const formS = "Proceed";
    return (
      <div className="auth-main-content">
        <div className="d-table">
          <div className="d-tablecell">
            <div className="login-logo">
              <Image className="login-logo" src={Logo} alt="Logo" />
            </div>
            <div className="welcome-box">
              <Row>
                <Card className="notes-card">
                  <Card.Body>
                    <CardMedia
                      component="img"
                      // image="https://omnixbmsdev.com/images/Omnix%20Affiliate.jpg"
                      image={affiliateImage} //"../../public/affiliates.jpg"
                      style={{
                        left: 0,
                        bottom: 0,
                        right: 0,
                        top: 0,
                        resizeMode: "contain",
                      }}
                    />
                    <p></p>

                    <Card.Text>
                      <h3>Guildlines for aspiring Agent Lead.</h3>
                      <p></p>

                      <p></p>

                      <Typography gutterBottom variant="h6" component="div">
                        Lead agent expected characteristics
                      </Typography>
                      <p></p>
                      <ol>
                        <li>
                          Lead agents must must have 10 account managers on
                          their team
                        </li>
                        <li>
                          A lead agent must be someone with capacity to lead the
                          account managers and encourage them to achieve mininum
                          of 10 subscriptions every month
                        </li>
                        <li>
                          Lead agents must educate and contribute to the success
                          of every member of the team.
                        </li>
                      </ol>
                      <p></p>
                      <Typography gutterBottom variant="h5" component="div">
                        Types of Lead Agents
                      </Typography>
                      <p></p>
                      <ol>
                        <li>
                          Classic Lead Agent(CLA), Earning: 5% one-off on the
                          new customers' subscriptions within the license tenor.
                        </li>
                        <li>
                          Legend Lead Agent(LLA), Earning: 2.5% on both new and
                          recurrent customers' subsriptions within the license
                          tenor.
                        </li>
                      </ol>
                      <p></p>
                      <Typography gutterBottom variant="h5" component="div">
                        Rules of engagement
                      </Typography>

                      <ol>
                        <li>
                          A lead Agent cannot earn lead agent commission when
                          the team size is not at least 10
                        </li>
                        <li>
                          Lead Agent earnings become withdrawable only when the
                          team achieve monthly minimum target of 10 new
                          customers' subscription
                        </li>
                        <li>
                          if there's a month the team could not archieve the
                          minimum target, the lead Agent earnings will be
                          available but not withdrawable.
                        </li>
                      </ol>
                      <p></p>

                      <Typography gutterBottom variant="h5" component="div">
                        Tenor & Renewability
                      </Typography>
                      <p></p>
                      <ol>
                        <li>Lead Agents have two(2) years tenor</li>
                        <li>Renewable upon impressive performance.</li>
                      </ol>
                      <p></p>

                      {response !== "success" ? (
                        <Box className="btn" onClick={() => this.handleClose()}>
                          <span>Proceed</span>
                        </Box>
                      ) : (
                        <Link to="./teamAccount" className="btn">
                          Go back
                        </Link>
                      )}

                      {/* <Button
                        className="btn"
                        variant="primary"
                        onClick={() => this.handleClose()}
                      >
                        Proceed
                      </Button> */}
                      {/* <div style={{ width: "100%", backgroundColor: "red", }}>
                        {LeadAgentModal(
                          () => this.createTeam(),
                          this.handleInput,
                          this.handleSelectChange,
                          this.state.response,
                          this.state.status,
                          formS,
                          this.state.name,
                          "Lead Agent Form"
                        )}
                      </div> */}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Row>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={() => this.handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Lead Agent Form</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {}
            <Alert
              variant={status}
              className="center"
              style={{ justifySelf: "center", alignItems: "center", top: 10 }}
            >
              {response}
            </Alert>
            {loading == true ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: -10,
                  zIndex: 10,
                  width: "100%",
                }}
              >
                <div class="spinner-border center">
                  <span class="sr-only center">Loading...</span>
                </div>
              </div>
            ) : null}
            <Form>
              <Form.Row>
                <Form.Group controlId="formGridAddress1">
                  <Form.Control
                    placeholder="Team Name"
                    type="text"
                    value={name}
                    name="name"
                    onChange={this.handleInput}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Control
                    as="select"
                    onChange={this.handleSelectChange}
                    name="leadType"
                  >
                    <option value="">Lead Type</option>
                    {leadData
                      ? leadData.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))
                      : null}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button> */}
            <Button variant="primary" onClick={() => this.createTeam()}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default LeadAgentGuild;
