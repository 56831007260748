import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import AppRouter from "./routes/AppRouter";

ReactDOM.render(
  <HashRouter>
    <AppRouter />
  </HashRouter>,
  document.querySelector("#root")
);
