import React from "react";
import { Row, Col, Breadcrumb, Tabs, Tab, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Navigation from "../../components/Navigation/Navigation";
import Footer from "../Footer/Footer";
import {
  WalletBalance,
  TotalEarning,
  TotalWithdraw,
  PendingWithdrawalTab,
} from "./tabs";
import { lookUP, save, saveChanges } from "../../api/functions";
import { format } from "../../components/number-format/number-format.component";
import { WithdrawalModal } from "./modal";
import { RetrieveSession } from "../../components/session/session";
import TableComponent from "../../components/table/table";
import Loader from "../../components/Common/Loader";
import * as Icon from "react-feather";
// import { getHomeMembers } from '../team/functions';
import {
  insertWithdrawal,
  getPaymentHistory,
  getHomeMembers,
  transactionHistory,
} from "./function";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentDay = currentDate.getDate();

const isClickable = currentMonth === 4 && currentDay >= 25 && currentDay <= 30;

class Withdrawal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sideMenu: true,
      api_token: RetrieveSession(),
      memberData: {},
      loading: false,
      response: "",
      status: "",
      agentRefId: this.props.match.params.agentRefId,
      agentSummaryData: {},
      welcomeName: "",
      totalCon: 0,
      totalSign: 0,
      taskPer: 0,
      agent_name: "",
      agent_ref: "",
      withdrawAmount: "",
      balance_value: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      bank_code: "",
      historyData: {},
      totalEarnings: "",
      totalWithdraw: "",
      pending_withdraw: "",
    };
  }

  _onSideMenu = (active) => this.setState({ sideMenu: active });

  getFunctions = () => {
    getHomeMembers(this.state.api_token, this.setMembers.bind(this));

    getPaymentHistory(this.state.api_token, this.setWithdraw.bind(this));

    transactionHistory(this.state.api_token, this.setHistory.bind(this));
    // this.revoke();
    this.retrievBankInfo();
  };

  //  dashboardInfo() {

  //     lookUP('addTeam', {inputs: {page: 'agentDetails', web:'web'}}, this.state.api_token, responseJson => {
  //         // console.log(responseJson);
  //         // return false;
  //         try {

  //             this.setState({
  //                 agent_name: responseJson.name, agent_ref: responseJson.ref_id
  //             })

  //         } catch (error) {
  //           console.log(error);
  //         }
  //       });
  //  }

  revoke() {
    this.getFunctions();
  }

  componentDidMount = () => {
    this.getFunctions();
    this.revoke();
  };

  setMembers = (agent_summary_data) =>
    this.setState({
      agentSummaryData: agent_summary_data,
      totalCon: agent_summary_data.total_conversion,
      totalSign: agent_summary_data.total_signup,
      taskPer: agent_summary_data.task_percentage,
      loading: false,
    });

  setWithdraw = (
    obj,
    wallet_balance,
    total_earnings,
    total_withdraw,
    pending_withdraw
  ) =>
    this.setState({
      memberData: obj,
      balance_value: wallet_balance,
      totalEarnings: total_earnings,
      totalWithdraw: total_withdraw,
      pending_withdraw: pending_withdraw,
      loading: false,
    });

  setHistory = (obj) => this.setState({ historyData: obj, loading: false });

  // setVendors = (obj, ven) => this.setState({ vendorData: obj, vendors: ven, loading: false });

  // setCreditors = (obj) => this.setState({creditorData: obj, loading: false });

  // setDebtors = (obj) => this.setState({ debtorData: obj, loading: false });

  // setLoyalty = (obj, loyaltyExist, loyalty_percentage, usable_level, loyaltyStatus) => this.setState({ loyaltyData: obj, loyaltyExist: loyaltyExist, loyalty_percentage: loyalty_percentage, maturity_level: usable_level, loyaltyStatus: loyaltyStatus, loading: false });

  handleDropDownSelect = (event, data) => this.setState({ gender: data.value });

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  getBalance() {}

  retrievBankInfo() {
    lookUP(
      "addTeam",
      { inputs: { page: "agentDetails", web: "web" } },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        // return false;
        try {
          this.setState({
            bankName: responseJson.bank_name,
            accountName: responseJson.account_name,
            accountNumber: responseJson.account_number,
            bank_code: responseJson.bank_code,
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  withdraw() {
    if (this.state.withdrawAmount == "") {
      alert("Field cannot be empty!");
      return false;
    }
    if (this.state.withdrawAmount > this.state.balance_value) {
      alert("Withdraw amount cannot exceed balance!");
      return false;
    }

    const input = {
      amount: this.state.withdrawAmount,
      account_bank: this.state.bankName,
      account_number: this.state.accountNumber,
      account_name: this.state.accountName,
      bank_code: this.state.bank_code,
      page: "withdraw",
    };
    // console.log(input);
    // return false;
    this.setState({ loading: true });
    insertWithdrawal(input, this.state.api_token, this.setResponse);
    this.setState({ loading: false });

    // setImmediate(() =>  getPaymentHistory(this.state.api_token, this.setWithdraw.bind(this)))
  }

  setResponse = (response, status) =>
    this.setState({ response: response, status: status });

  handleClick = () =>
    this.state.loyaltyStatus == 0
      ? this.setState({ loyaltyStatus: 1 })
      : this.setState({ loyaltyStatus: 0 });

  render() {
    const {
      loading,
      memberData,
      historyData,
      totalCon,
      totalSign,
      taskPer,
      agent_name,
      agent_ref,
      balance_value,
      totalEarnings,
      totalWithdraw,
      pending_withdraw,
    } = this.state;
    //  console.log(this.state.totalEarnings)
    return (
      <div className="page-wrapper">
        {/* Navigation */}
        <Navigation onClick={this._onSideMenu} />
        {/* End Navigation */}

        <div
          className={`main-content d-flex flex-column ${
            this.state.sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <Loader loading={loading} />
          {/* Breadcrumb */}
          <div className="main-content-header">
            <Breadcrumb>
              {/* <h1>Welcome back <strong>{agent_name}</strong></h1>
                            
                        <h1>Agent Ref Id: <strong>{agent_ref}</strong></h1> */}
            </Breadcrumb>
          </div>
          {/* End Breadcrumb */}

          <Row>
            <Col sm={3} lg={3}>
              {" "}
              {WalletBalance(`₦ ${balance_value}`)}{" "}
            </Col>

            <Col sm={3} lg={3}>
              {" "}
              {TotalEarning(`₦ ${totalEarnings}`)}{" "}
            </Col>

            <Col sm={3} lg={3}>
              {" "}
              {TotalWithdraw(`₦ ${totalWithdraw}`)}
            </Col>

            <Col sm={3} lg={3}>
              {" "}
              {PendingWithdrawalTab(`₦ ${pending_withdraw}`)}
            </Col>
          </Row>

          {/* Basic Table */}
          <Row style={{ marginTop: 10 }}>
            {/* Tab Demo One */}
            <Col xl={12}>
              <div className="card mb-4">
                <div className="card-body">
                  <div className="tabs-style-one">
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                      <Tab eventKey="home" title="Earnings">
                        <div className="before-table-header-short">
                          {WithdrawalModal(
                            () => this.withdraw(),
                            this.handleInput,
                            this.state.response,
                            this.state.status,
                            this.state.balance_value
                            // isClickable
                          )}
                          {/* <Link 
                                                    // to="/forgot-password/" 
                                                     to="/team-view-account" 
                                                    className="fp-link">
                                                <Button variant="primary" type="submit">
                                                   View agents
                                                </Button>
                                                </Link> */}
                          <Icon.RefreshCcw
                            className="icon"
                            style={{ cursor: "pointer", color: "yellowgreen" }}
                            onClick={() => {
                              this.setState({ loading: true });
                              this.getFunctions();
                            }}
                          />
                          Refresh
                        </div>
                        <TableComponent data={memberData} />
                      </Tab>

                      <Tab eventKey="profile" title="Transaction history">
                        <div className="before-table-header-short">
                          {/* {VendorModal({handleSubmit: this.saveVendor.bind(this)}, genderOptions, this.handleDropDownSelect, this.handleInput)} */}
                          <Icon.RefreshCcw
                            className="icon"
                            style={{ cursor: "pointer", color: "yellowgreen" }}
                            onClick={() => {
                              this.setState({ loading: true });
                              this.getFunctions();
                            }}
                          />
                          Refresh
                        </div>
                        <TableComponent data={historyData} />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Withdrawal;
