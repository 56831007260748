import React from 'react';
import { Link, Redirect } from "react-router-dom";
import {Row, Col, Form, Button, Image } from 'react-bootstrap'; 
import { lookUP } from '../api/functions';
// import Loader from '../components/loader/loader.component';
import { RetrieveSession } from '../components/session/session';
// Logo image file path
import Logo from '../assets/img/trans2.png';
// import './login.scss';



class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            api_token: '',
            loading: false
        }
    }

    handleChange = event => {
        const { value, name } = event.target;
        this.setState({ [name]: value});
    }

    login222 = () => {
        const input = {
            email: this.state.email,
            password: this.state.password,
            page: "forgotpassword"
        }

        if(input.email === ''){
            //alert("Email must not be empty.");
            return false;
        }

        this.setState({loading: true});

        lookUP('registerAgent', {inputs: input}, this.state.api_token, responseJson => {

            if(responseJson['success'] !== true) {
                alert(responseJson['data']);
                this.setState({loading: false});
                return false;
            }
            sessionStorage.setItem('api_token', responseJson.api_token);
            this.setState({loading: false, redirect: true});
            
        });
          
    }


    forgetPassword = () => {
        const input = {
          email: this.state.email, page:"forgotpassword"
        }
        this.setState({loading: true});
        lookUP('registerAgent', {inputs: input}, {}, responseJson => {
          if(responseJson['success'] === true) {
            alert("A reset token has been sent to your phone and email");
            this.props.history.push("/new-password",{license_id: responseJson['data'][0], email: responseJson['data'][1]} );
            this.setState({
              loading: false, modal: false, success: false, status: '', email: ''
            });
          } else {
              alert(responseJson['data']);
            this.setState({loading: false, modal: true, success: responseJson['success'], status: responseJson['data']
            });
          }
        });
        setInterval(() => {
          this.setState({success: null})
        }, 5000)
      };


    render() {
        const { loading, email, password } = this.state;
        //const { navigate } = this.props.navigation;

        if(RetrieveSession()) {return(<Redirect to='/dashboard' />)}
        

        return (
            <div className="auth-main-content">
                <div className="d-table">
                    <div className="d-tablecell"> 
                    {/* <Loader loading={loading} styles="login-loader" /> */}
                    <div className="login-logo">
                         <Image className="login-logo logo" src={Logo} alt="Logo" /> 
                    </div>
                        <div className="auth-box">
                            <Row className="login-container"> 
                                <Col>
                                    <div className="form-content">
                                        <h1 className="heading">Reset Password</h1> 

                                        {/* <Form onSubmit={this.onLoginHandler}> */}
                                            <Form.Group>
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control onChange={this.handleChange} name="email" type="email" value={email} />
                                            </Form.Group>

                                            <div className="text-center">
                                                <Button to='/dashboard/' variant="primary" type="submit" onClick={this.forgetPassword}>
                                                    Reset Password
                                                </Button>

                                               

                                                <Link 
                                                    // to="/forgot-password/" 
                                                     to="/login/" 
                                                    className="fp-link">
                                                    Login
                                                </Link>

                                            </div>
                                        {/* </Form> */}
                                    </div>
                                </Col>     
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;