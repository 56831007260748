import React from 'react';
import {Row, Col, Breadcrumb, Tabs, Tab, Table} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Navigation from '../../components/Navigation/Navigation';
import Footer from '../Footer/Footer';
import {CustomerTab, VendorTab, CreditorTab, DebtorTab, LoyaltyTab} from './tabs';
import { format } from '../../components/number-format/number-format.component';
import {CustomerModal, VendorModal, LoyaltyModal, EditLoyaltyModal} from './modals';
import { lookUP, save } from '../../api/functions';
import { RetrieveSession } from '../../components/session/session';
import TableComponent from '../../components/table/table';
import Loader from '../../components/Common/Loader';
import * as Icon from 'react-feather';

class Account extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            sideMenu: true,
            api_token: RetrieveSession(),
            customerData: {},
            vendorData: {},
            creditorData: {},
            debtorData: {},
            loyaltyData: {},
            debtors: 0,
            customers: '',
            vendors: '',
            creditors: 0,
            loyalty_sum: 0,
            datatable: {},

            genderOptions: [
                { id: 1, value: 'Male', text: 'Male' },
                { id: 2, value: 'Female', text: 'Female' }
            ],
            gender: '',
            name: '',
            email: '',
            phone: '',
            address:'',
            day: '',
            month: '',
            limit: '',
            duration:'',
            loyalty:'',

            loyaltyExist: [],
            loyalty_percentage: '',
            maturity_level: '',
            loyaltyStatus: 0,

            loading: true
        };
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    }

    componentDidMount() {
        this.getCustomers();
        this.getVendors();
        this.getCreditors();
        this.getDebtors();
        this.getLoyalty();
    }

    getCustomers() {
        let cus = 0;
        let i = 0;
        
        let obj={
            columns: [
                {label: '#', field: 'id',width: 270},
                {label: 'Name',field: 'name',width: 270},
                {
                    label: 'Phone',
                    field: 'phone',
                    width: 270,
                },
                {
                    label: 'Email',
                    field: 'email',
                    width: 270,
                },
                {
                    label: 'Balance',
                    field: 'balance',
                    width: 270,
                },
                {
                    label: 'Matured Loyalty',
                    field: 'matured',
                    width: 270,
                },
                {
                    label: 'Growing Loyalty',
                    field: 'growing',
                    width: 270,
                },
                {
                    label: 'Profile',
                    field: 'view',
                    width: 270,
                }
            ],
            rows: []
        };
        // this.setState({loading: true});
        lookUP('customerVendor', { inputs: { page: 'getCustomers', web: 'web' } }, this.state.api_token, responseJson => {
            
          try {
            
            responseJson[0].map(customer => {
                i+=1;
                obj.rows.push(
                    {
                        id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: customer.balance > 0 ? <font color="yellowgreen">{format(customer.balance, 2)}</font> : <font color="red">{format(customer.balance, 2)}</font>, matured: format(customer.matured_loyalty, 2), growing: format(customer.growing_loyalty, 2), view: <Link to={`customer-profile/${customer.id}`}>View</Link>
                    }
                )
                cus+=1;
            });

            this.setState({
              customerData: obj,
              customers: cus,
              debtors: responseJson[1]['debtors'],
              creditors: responseJson[1]['creditors'],
              loyalty_sum: responseJson[2]['loyalty_sum'],
              loading: false
            });

            // console.log(this.state.data);
            
          } catch (error) {
            console.log(error);
          }
        });
      }

      getVendors() {
        let ven = 0;
        let i = 0;
        
        let obj={
            columns: [
                {
                    label: '#',
                    field: 'id',
                    width: 270,
                },
                {
                    label: 'Name',
                    field: 'name',
                    width: 270,
                },
                {
                    label: 'Phone',
                    field: 'phone',
                    width: 270,
                },
                {
                    label: 'Email',
                    field: 'email',
                    width: 270,
                },
                {
                    label: 'Balance',
                    field: 'balance',
                    width: 270,
                },
                {
                    label: 'Profile',
                    field: 'view',
                    width: 270,
                }
            ],
            rows: []
        };
        // this.setState({loading: true});
        lookUP('customerVendor', { inputs: { page: 'getvendors', web: 'web' } }, this.state.api_token, responseJson => {
            
          try {
            
            responseJson[0].map(customer => {
                i+=1;
                obj.rows.push(
                    {
                        id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: customer.balance > 0 ? <font color="green">{format(customer.balance, 2)}</font> : <font color="red">{format(customer.balance, 2)}</font>, view: <Link to={`vendor-profile/${customer.id}`}>View</Link>
                    }
                )
                ven+=1;
            });

            this.setState({
              vendorData: obj,
              vendors: ven,
              loading: false
            });

            // console.log(this.state.data);
            
          } catch (error) {
            console.log(error);
          }
        });
      }

      getCreditors() {
        
        let i = 0;
        
        let obj={
            columns: [
                { label: '#',field: 'id',width: 270},
                {
                    label: 'Name',
                    field: 'name',
                    width: 270,
                },
                {
                    label: 'Phone',
                    field: 'phone',
                    width: 270,
                },
                {
                    label: 'Email',
                    field: 'email',
                    width: 270,
                },
                {
                    label: 'Balance',
                    field: 'balance',
                    width: 270,
                },
                {
                    label: 'View',
                    field: 'view',
                    width: 270,
                }
            ],
            rows: []
        };
        // this.setState({loading: true});
        lookUP('getCreditors', {inputs: {page: 'creditors'}}, this.state.api_token, responseJson => {
          try {
            
            responseJson[0].map(customer => {
                i+=1;
                obj.rows.push(
                    {
                        id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: <font color="green">{format(customer.balance, 2)}</font>, view: <Link to={customer.cate == 'LBA' ? `/customer-profile/${customer.id}` : `/vendor-profile/${customer.id}`}>View</Link>
                    }
                   
                )
            });

            this.setState({
              creditorData: obj,
              loading: false
            });

            // console.log(this.state.data);
            
          } catch (error) {
            console.log(error);
          }
        });
      }

      getDebtors() {
        
        let i = 0;
        
        let obj={
            columns: [
                { label: '#',field: 'id',width: 270},
                {label: 'Name', field: 'name', width: 270,
                },
                {
                    label: 'Phone',
                    field: 'phone',
                    width: 270,
                },
                {
                    label: 'Email',
                    field: 'email',
                    width: 270,
                },
                {
                    label: 'Balance',
                    field: 'balance',
                    width: 270,
                },
                {
                    label: 'View',
                    field: 'view',
                    width: 270,
                }
            ],
            rows: []
        };
        // this.setState({loading: true});
        lookUP('getDebtors', {inputs: {page: 'debtors'}}, this.state.api_token, responseJson => {
          try {
            
            responseJson[0].map(customer => {
                i+=1;
                obj.rows.push(
                    {
                        id: i, name: customer.coy_name, phone: customer.phone, email: customer.email, balance: <font color="tomato">{format(customer.balance, 2)}</font>, view: <Link to={customer.cate == 'LBA' ? `/customer-profile/${customer.id}` : `/vendor-profile/${customer.id}`}>View</Link>
                    }
                   
                )
            });

            this.setState({
              debtorData: obj,
              loading: false
            });

            // console.log(this.state.data);
            
          } catch (error) {
            console.log(error);
          }
        });
      }

      getLoyalty() {
        
        let i = 0;
        
        let obj={
            columns: [
                { label: '#',field: 'id',width: 270},
                {
                    label: 'Name',
                    field: 'name',
                    width: 270,
                },
                {
                    label: 'Growing',
                    field: 'growing',
                    width: 270,
                }, {
                    label: 'Matured',
                    field: 'matured',
                    width: 270,
                },
                , {
                    label: 'View',
                    field: 'view',
                    width: 270,
                },
                
            ],
            rows: []
        };
        // this.setState({loading: true});
        lookUP('getLoyalties', {inputs: {page: 'loyalties', web:'web'}}, this.state.api_token, responseJson => {
          try {
            responseJson[0].rlt.map(customer => {
                i+=1;
                obj.rows.push(
                    {
                        id: i, name: customer.coy_name, growing: format(customer.growing_loyalty, 2), matured: format(customer.matured_loyalty, 2), view: <Link to={`customer-profile/${customer.id}`}>View</Link>
                    }
                )
            });

            this.setState({
              loyaltyData: obj,
              loyaltyExist: responseJson[0].loyaltyExist,
              loyalty_percentage: responseJson[0].loyaltyExist[0].loyalty_percentage,
              maturity_level: responseJson[0].loyaltyExist[0].usable_level,
              loyaltyStatus: responseJson[0].loyaltyExist[0].status,
              loading: false
            });

            // console.log(this.state.data);
            
          } catch (error) {
            console.log(error);
          }
        });
      }      

    handleDropDownSelect = (event, data) => this.setState({gender: data.value});

    handleInput = event => {
        const { value, name } = event.target;
        this.setState({[name]: value}); 
    }

    saveCustomer() {
        
        const input = {
          email: this.state.email, address: this.state.address, number: this.state.phone, name: this.state.name, page: 'addcustomer', credit_duration: this.state.duration, day: this.state.day, month: this.state.month, loyalty_code: this.state.loyalty, gender: this.state.gender, credit_limit: this.state.limit
        }
        
        if(input.name == '' || input.phone == '' || input.gender == '') {
            alert("None of the asterisked fields must be empty");
            return false;
        }
        
        this.setState({ loading: true });
        save('customerVendor', { inputs: input }, this.state.api_token, responseJson => {
          if (responseJson['success'] === true) {
            alert(responseJson['data']);
            this.setState({modal: false});
            this.getCustomers();
          } else {
            alert(responseJson['data']);
          }
        });
      }

      saveVendor() {
        const input = {
          email: this.state.email, address: this.state.address, number: this.state.phone, name: this.state.name, page: 'addvendor', gender: this.state.gender
        }    
        
        if(input.name == '' || input.phone == '' || input.gender == '') {
            alert("None of the asterisked fields must be empty");
            return false;
        }
        
        this.setState({loading: true});
        save('customerVendor', { inputs: input }, this.state.api_token, responseJson => {
          if (responseJson['success'] === true) {
            this.setState({
              loading: false,
              modal: false
            });
            this.getVendors();
            alert(responseJson['data']);
           
          } else {
            alert(responseJson['data']);
            return false;
          }
        });
      }

      saveLoyalty() {
        const input = {
            page: 'setLoyalty',
            loyaltyStatus: this.state.loyaltyStatus,
            maturity: this.state.maturity_level,
            percentage: this.state.loyalty_percentage
          }
          this.setState({loading: true});
          save('setUpLoyalty', {inputs: input}, this.state.api_token, responseJson => {
            if(responseJson['success'] === true){     
              alert("Entry saved successfully");
              this.getLoyalty();
            } else {
                alert(responseJson['data']);
            }
            this.setState({loading: false});
          });
    }


    handleClick = () => {
        this.state.loyaltyStatus == 0 ? this.setState({loyaltyStatus: 1}) : this.setState({loyaltyStatus: 0});
    }

    editLoyalty() {
        
        const input = {
            page: 'editLoyalty',
            loyaltyStatus: this.state.loyaltyStatus,
            maturity: this.state.maturity_level,
            percentage: this.state.loyalty_percentage
          }
          
          this.setState({loading: true});
          save('setUpLoyalty', {inputs: input}, this.state.api_token, responseJson => {
            if(responseJson['success'] === true){            
              alert("Entry saved successfully");
              this.setState({modal: false});
              this.getLoyalty();
            } else {
              alert(responseJson['data']);
            }
            this.setState({loading: false});
          });
    }
     
    render() {
        const { loading, customerData, vendorData, customers, vendors, debtors, creditors, loyalty_sum, creditorData, debtorData, loyaltyData, genderOptions, loyaltyExist, loyalty_percentage, maturity_level, loyaltyStatus } = this.state;

        return (
            <div className="page-wrapper">
                {/* Navigation */}
             <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? 'hide-sidemenu' : ''}`}>
                    <Loader loading={loading} />
                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Accounts</h1>
                            <Link to="/home" className="breadcrumb-item">
                                Dashboard
                            </Link>
                            {/* <Breadcrumb.Item active>Accounts</Breadcrumb.Item> */}
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    <Row>
                        <Col sm={3} lg={2}>
                            {CustomerTab(customers)}
                        </Col>

                        <Col sm={3} lg={2}>
                            {VendorTab(vendors)}
                        </Col>
 
                        <Col sm={6} lg={3}>
                            {CreditorTab(format(creditors, 2))}
                        </Col>

                        <Col sm={6} lg={3}>
                            {DebtorTab(format(debtors, 2))}
                        </Col>

                        <Col sm={6} lg={2}>
                            {LoyaltyTab(format(loyalty_sum, 2))}
                        </Col>
                    </Row>

                    {/* Basic Table */}
                    <Row style={{marginTop: 10}}>

                         {/* Tab Demo One */}
                         <Col xl={12}>
                            <div className="card mb-4">
                                <div className="card-body">
                                    {/* <div className="card-header">
                                        <h5 className="card-title">Customers</h5>
                                    </div> */}

                                    <div className="tabs-style-one">
                                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                            <Tab eventKey="home" title="Accounts">
                                           
                                                <div className="before-table-header-short">
                                                {CustomerModal({handleSubmit: this.saveCustomer.bind(this)}, genderOptions, this.handleDropDownSelect, this.handleInput)}
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getCustomers();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                             <TableComponent data={customerData} />

                                        </Tab>

                                        {/* <Tab eventKey="profile" title="Supplier">
                                        <div className="before-table-header-short">
                                                {VendorModal({handleSubmit: this.saveVendor.bind(this)}, genderOptions, this.handleDropDownSelect, this.handleInput)}
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getVendors();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                            <TableComponent data={vendorData} />
                                        </Tab>                                       

                                        <Tab eventKey="contact" title="Creditors">
                                        <div className="before-table-header-short">
                                                
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getCreditors();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                            <TableComponent data={creditorData} />
                                        </Tab>

                                        <Tab eventKey="debtor" title="Debtors">
                                        <div className="before-table-header-short">
                                                
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getDebtors();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                            <TableComponent data={debtorData} />
                                        </Tab>
                                        <Tab eventKey="creditor" title="Loyalty">
                                        <div className="before-table-header-short">
                                                {loyaltyExist != '' 
                                                ? EditLoyaltyModal({handleSubmit: this.editLoyalty.bind(this)}, this.handleInput, loyalty_percentage, maturity_level, loyaltyStatus, {handleClick: this.handleClick.bind(this)})
                                                : LoyaltyModal({handleSubmit: this.saveLoyalty.bind(this)}, this.handleInput,{handleClick: this.handleClick.bind(this)})}
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getLoyalty();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                            <TableComponent data={loyaltyData} />
                                        </Tab> */}
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* End Tab Demo One */}

  </Row>
                    {/* End Basic Table */}

                    {/* Data Table */}
                 
 
                    
                    {/* Footer  */}    
                    <div className="flex-grow-1"></div>
                    <Footer />
                    {/* End Footer  */}   
                </div>
            </div>
        );
    }
}

export default Account;