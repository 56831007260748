import React from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Row,
  Form,
  Button,
  Image,
  Alert,
  Container,
  Card,
} from "react-bootstrap";
import affiliateImage from "../affiliates3.jpeg";
import { lookUP, save, saveFile } from "../api/functions";
import Loader from "../components/Common/Loader";
import { RetrieveSession } from "../components/session/session";
import axios from "axios";
import Logo from "../assets/img/trans2.png";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class Welcome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      folio: "",
      email: "",
      phone: "",
      password: "",
      rePassword: "",
      address: "",
      teamId: this.props.match.params.code,
      idcard: null,
      idcardName: null,
      passport: null,
      passportName: null,
      loading: false,
      loadingVer: false,
      loadingLocal: false,
      agentList1: "",
      agentList2: "",
      agentList3: "",
      category: "",
      respo: "",
      conRes: "",
      gender: "",
      state: "",
      localGov: "",
      stateList: "",
      localGovList: "",
      verificationList: "",
      verification_type: "",
      verification_number: "",
      stateStatus: false,
      digit: 11,
      verificationStatus: false,
      isHidden: false,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSelectState = this.handleSelectState.bind(this);
    this.handleSelectVerification = this.handleSelectVerification.bind(this);
  }

  handleSelectChange(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectState(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value, stateStatus: true });
  }

  handleSelectVerification(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  isChecked = () => {
    this.setState((state) => ({
      isHidden: !state.isHidden,
    })); /* we're setting the state value isHidden equal to the negation of previous value of isHidden onChange event of checkbox */
  };

  onFileChange1 = (event) => {
    // Update the state
    this.setState({
      passport: event.target.files[0],
      passportName: event.target.files[0].name,
    });
  };
  onFileChange2 = (event) => {
    // Update the state
    this.setState({
      idcard: event.target.files[0],
      idcardName: event.target.files[0].name,
    });
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    this.getAgent();
    this.getState();
    //this.getVerificationData();
  }

  getLocalGov() {
    this.setState({ stateStatus: false, loadingLocal: true });
    const formData = new FormData();
    formData.append("page", "getLga");
    formData.append("state_id", this.state.state);

    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        // console.log(response.data);
        try {
          this.setState({
            localGovList: response.data,
            loadingLocal: false,
          });
        } catch (error) {
          console.log(error);
        }
      });
  }

  getVerificationData() {
    this.setState({ digit: 5, loadingVer: true });
    // alert('yes');

    const formData = new FormData();
    formData.append("page", "verify");
    formData.append("number", this.state.verification_number);
    formData.append("type", this.state.verification_type);

    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbmsdev.com/mobile/l/index.php/bvnNinVerification",
        formData,
        headers
      )
      .then((response) => {
        console.log(response.data);
        try {
          if (this.state.verification_type == "bvn") {
            this.setState({
              verificationList: response.data,
              loadingVer: false,
              folio: response.data.bvn_data.nameOnCard,
              phone: response.data.bvn_data.phoneNumber1,
            });
          } else {
            this.setState({
              verificationList: response.data,
              loadingVer: false,
              folio: `${response.data.nin_data.firstname} ${response.data.nin_data.surname}`,
              phone: response.data.nin_data.telephoneno,
            });
          }
          //console.log(this.state.folio);
        } catch (error) {
          console.log(error);
        }
      });
  }

  getState() {
    const formData = new FormData();
    formData.append("page", "getstate");

    this.setState({ loading: true });
    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        // console.log(response.data.data); return false;
        try {
          this.setState({
            stateList: response.data.data,
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      });
  }

  getAgent() {
    const formData = new FormData();
    formData.append("page", "getAgentList");

    this.setState({ loading: true });
    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .post(
        "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        // console.log(response); return false;
        try {
          this.setState({
            agentList1: response.data[0],
            agentList2: response.data[1],
            agentList3: response.data[2],
            loading: false,
          });
        } catch (error) {
          console.log(error);
        }
      });
  }

  registerCus = (e) => {
    const input = {
      folio: this.state.folio,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      rePassword: this.state.rePassword,
      address: this.state.address,
      page: "newclientWeb",
      gender: this.state.gender,
      state: this.state.state,
      lga: this.state.localGov,
      teamId: this.state.teamId,
      isHidden: this.state.isHidden,
    };

    //  console.log(input);
    //  return false;

    if (
      input.name == "" ||
      input.phone == "" ||
      input.email == "" ||
      input.gender == ""
    ) {
      alert("None of the asterisked fields must be empty");
      return false;
    }

    this.setState({ loading: true });

    e.preventDefault();

    const formData = new FormData();
    formData.append("folio", this.state.folio);
    formData.append("email", this.state.email);
    formData.append("state", this.state.state);
    formData.append("lga", this.state.localGov);
    formData.append("gender", this.state.gender);
    formData.append("password", this.state.password);
    formData.append("phone", this.state.phone);
    formData.append("rePassword", this.state.rePassword);
    //formData.append('idcard', this.state.idcard, this.state.idcarName)
    formData.append("address", this.state.address);
    formData.append("agentCategory", this.state.category);
    formData.append("teamId", this.state.teamId);
    formData.append("isHidden", this.state.isHidden);
    // formData.append('passport', this.state.passport, this.state.passportName)
    formData.append("page", "newclientWeb");

    var headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .post(
        "https://omnixbmsdev.com/mobile/l/index.php/registerAgent",
        formData,
        headers
      )
      .then((response) => {
        //handle success
        //    console.log(response.data.success);
        if (response.data.success == "true") {
          this.setState({
            respo: response.data.data,
            conRes: response.data.success,
            loading: false,
          });
        } else {
          this.setState({
            respo: response.data.data,
            conRes: response.data.success,
            loading: false,
          });
        }
      })
      .catch(function (response) {});
  };

  render() {
    // console.log(this.state.localGov);
    const {
      loading,
      email,
      password,
      respo,
      conRes,
      stateList,
      stateStatus,
      verificationStatus,
      localGovList,
      loadingLocal,
      verification_number,
      digit,
      loadingVer,
    } = this.state;

    if (RetrieveSession()) {
      return <Redirect to="/home" />;
    }

    return (
      <div className="auth-main-content">
        {verification_number.length == digit
          ? this.getVerificationData()
          : null}

        {
          // verificationList = verificationList.map()
        }
        <div className="d-table">
          <div className="d-tablecell">
            <div className="login-logo">
              <Image className="login-logo" src={Logo} alt="Logo" />
            </div>
            <div className="welcome-box">
              <Row>
                <Card className="notes-card">
                  <Card.Body>
                    <CardMedia
                      component="img"
                      // image="https://omnixbmsdev.com/images/Omnix%20Affiliate.jpg"
                      image={affiliateImage} //"../../public/affiliates.jpg"
                      style={{
                        left: 0,
                        bottom: 0,
                        right: 0,
                        top: 0,
                        resizeMode: "contain",
                      }}
                    />
                    <p></p>

                    <Card.Text>
                      <h3>Become a Software distributor/ Account manager.</h3>
                      <p></p>
                      <Typography variant="body2" color="text.secondary">
                        Get <b>60% on onboarding cost </b> and up to
                        <b> 30% commission on subscription</b> for every
                        customer you refer for five years . You also get to
                        receive <b>20% recurrent commission</b> on subscription
                        for every customer referred. T&C applied.
                      </Typography>
                      <p></p>

                      <ol>
                        <li>Sign up as agent.</li>
                        <li>Review offers and agreement.</li>
                        <li>
                          Create account on the Omnix account manager’s portal.
                        </li>

                        <li>
                          Update your profile;
                          <ul>
                            <li>
                              Bank account detail to receive your payment.
                            </li>
                            <li>
                              Upload your passport photograph and ID Card.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Verification: automatically with your NIN, BVN or
                          registered phone number.
                        </li>
                        <li>
                          Copy and Save your Referrer code/ID.
                          <ul>
                            <li>
                              Copy your unique (6 digits number) refferer
                              code/ID and save at ones on the Omnix mobile app
                              or save somewhere.{" "}
                            </li>
                            <li>
                              Download the Omnix mobile app on Playstore or iOS
                              and register.
                            </li>
                            <li>
                              {" "}
                              Navigate to the Refer a friend and Account manager
                              menu within the app and save you code at ones.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Training: Ensure to complete your training on the
                          Account Manager's Portal.
                        </li>
                        <li>
                          Congratulations you can now begin to use your Unique
                          refferer code to set-up your customers. You can also
                          promote or share your unique link on your social
                          handles and other business community's.
                          <ul>
                            <li>
                              Ensure to use your code/link to enable us to track
                              all your referrer acttivities for conversion
                              reward.
                            </li>
                          </ul>
                        </li>
                      </ol>

                      <Typography gutterBottom variant="h5" component="div">
                        FAQ's
                      </Typography>
                      <p></p>

                      <Typography gutterBottom variant="h5" component="div">
                        How does the Omnix Account Manager's program work?
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        The Omnix Account Program rewards you with 60%
                        onboarding and up to 30% commission for every new paying
                        customers. You will be able to monitor your progress on
                        the agent portal and view the total number of paid
                        subscribers you have referred.
                      </Typography>
                      <p></p>
                      <Typography gutterBottom variant="h5" component="div">
                        How do I get my referral link to start earning?{" "}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Once you have register and have been verified, you will
                        get an approval Status as an affiliate and will
                        automatically access your unique referral Id, which you
                        can then share as link within or outside of the omnix
                        app to any platform (social media, personal emails etc).
                      </Typography>
                      <p></p>
                      <Typography gutterBottom variant="h5" component="div">
                        How does my referral link work?
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        Once download is made from your referral link. The
                        system automatically generates your unique referral link
                        and fills it into the referral code section during the
                        signup process of a new user. The code must not be
                        removed while completing the sign-up process because it
                        is the only way to authenticate who has referrered.
                      </Typography>
                      <p></p>

                      <Typography gutterBottom variant="h5" component="div">
                        Where do I share my referral link?
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Share your referral link within the omnix mobile app by
                        navigating to the Invite a friend menu/Affiliate refer
                        and earn section. Once you have saved your code that you
                        copied from the agents portal, begin to share your
                        unique referral link which gives you opportunity to earn
                        when a new customer pays. You are able to track your
                        earning and easily access your cash immediately. You
                        will Increase your potential cash earning the more you
                        share with friends and followers via your social
                        networks (Facebook, Instagram, WhatsApp, telegram,
                        twitter, LinkedIn, online groups, personalised email,
                        blogs, etc).
                      </Typography>
                      <p></p>

                      <Typography gutterBottom variant="h5" component="div">
                        Are there promotional content that can help me?
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        You will be added to our affiliates community group on
                        Telegram and will be granted access to omnix business
                        community group on facebook where you will have access
                        to tips, daily posts and other assets of the company.
                      </Typography>
                      <p></p>
                      <Typography gutterBottom variant="h5" component="div">
                        Where do I get support and help?
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Kindly email, or send chat to WhatsApp for help on;
                        Email: support@omnixbms.com Phone: 08066242995 or
                        09060288730
                      </Typography>

                      <a href="./login" class="btn ">
                        get started
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
