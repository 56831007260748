import React from 'react';
import {Row, Col, Breadcrumb, Tabs, Tab, Button} from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import Navigation from '../../../components/Navigation/Navigation';
import Loader from '../../../components/Common/Loader';
import Footer from '../../Footer/Footer';
import { format } from '../../../components/number-format/number-format.component';
import {EditCustomerModal} from './modals';
import DateFilter from '../../../components/date-filter/date-filter';
import { lookUP, saveChanges, save } from '../../../api/functions';
import { RetrieveSession } from '../../../components/session/session';
import TableComponent from '../../../components/table/table';
import * as Icon from 'react-feather';
import DateRange from "../../../components/datepicker/datepicker";
import { Dropdown } from 'semantic-ui-react';
import { getSingleCustomer, transactions, purchaseHistory, loyaltyTransactions, users, eraseCustomer, updateCustomer } from './functions';

class CustomerProfile extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            sideMenu: true, api_token: RetrieveSession(), loading: true, profileData: {}, transactionData: {}, purchaseHistoryData: {}, loyaltyData: {}, tcr: 0, tdr: 0, growing: 0, used: 0, loyaltyBalance: 0, matured: 0,
            deletion: '', modal: false, redirect: false,  genderOptions: [
                { id: 1, value: 'Male', text: 'Male' },
                { id: 2, value: 'Female', text: 'Female' }
            ], gender: '', name: '', email: '', phone: '', address:'', day: '', month: '', limit: '', duration:'', filter: '', transactionDateRange: '', purchaseHistoryDateRange: '', loyaltyDateRange: '', userId: '', users: '', balance: 0
        };
    }

    _onSideMenu = active => this.setState({sideMenu: active});

    componentDidMount = () => this.getFunctions();

    getFunctions() {
        getSingleCustomer(this.state.api_token, this.props.match.params.id, {profileData: this.setSingleCustomer.bind(this)});
        transactions(this.state.api_token, this.props.match.params.id, this.state.filter, this.state.transactionDateRange, this.state.userId, this.getTransactions.bind(this));
        purchaseHistory(this.state.api_token, this.props.match.params.id, this.state.filter, this.state.transactionDateRange, this.state.userId, this.getPurchaseHistory.bind(this));
        loyaltyTransactions(this.state.api_token, this.props.match.params.id, this.state.filter, this.state.loyaltyDateRange, this.getLoyaltyTransactions.bind(this));
        users(this.state.api_token, this.getUsers.bind(this));
    }

    setSingleCustomer = (responseJson) => this.setState({ profileData:  responseJson[0], email: responseJson[0].email, address: responseJson[0].address, phone: responseJson[0].phone, name: responseJson[0].name, duration: responseJson[0].credit_duration, day: responseJson[0].day, month: responseJson[0].month, gender: responseJson[0].gender, limit: responseJson[0].credit_limit, loading: false });

    getTransactions = (obj, credit, debit) => this.setState({ transactionData: obj, transactionDateRange: '', tcr: credit, tdr: debit, filter: '' });

    sortTransactionsByUser = (event, data) => this.setState({userId: data.value, loading: true}, this.getFunctions);

    sortPurchaseHistoryByUser = (event, data) => this.setState({userId: data.value, loading: true}, this.getFunctions);

    sortTransactionsByFilter = (event, data) => this.setState({filter: data.value, loading: true}, this.getFunctions);

    sortPurchaseHistoryByFilter = (event, data) => this.setState({filter: data.value, loading: true}, this.getFunctions);

    sortLoyaltyTransactionsByFilter = (event, data) => this.setState({filter: data.value, loading: true}, this.getFunctions);

    setTransactionDate = value => this.setState({transactionDateRange: value, loading: true}, this.getFunctions);

    setPurchaseHistoryDate = value => this.setState({purchaseHistoryDateRange: value, loading: true}, this.getFunctions);

    setLoyaltyDate = value => this.setState({loyaltyDateRange: value, loading: true}, this.getFunctions);

    handleDropDownSelect = (event, data) => this.setState({gender: data.value});

    handleInput = event => {
        const { value, name } = event.target;
        this.setState({[name]: value}); 
    }

    getPurchaseHistory = (obj, balance) => this.setState({ purchaseHistoryData: obj, purchaseHistoryDateRange: '', filter: '', balance: balance, loading: false });

    getLoyaltyTransactions = (obj, growing, balance, used, matured) =>  this.setState({ loyaltyData: obj, loyaltyDateRange: '', filter: '', growing: growing, loyaltyBalance: balance, used: used, matured: matured, loading: false });

    getUsers = (user) => this.setState({users: user});

    deleteCustomer = () => eraseCustomer(this.state.api_token, this.props.match.params.id, this.setRedirect.bind(this));

    setRedirect = (status) => this.setState({redirect: status});

    editCustomer() {
        const input = { email: this.state.email, address: this.state.address, phone: this.state.phone, name: this.state.name, page: 'editcustomer', credit_duration: this.state.duration, day: this.state.day, month: this.state.month, gender: this.state.gender, credit_limit: this.state.limit, id: this.props.match.params.id };
        
        this.setState({loading: true});
        updateCustomer(input, this.state.api_token, this.getFunctions.bind(this));
        this.setState({loading: false});
    }    
     
    render() {
        const { loading, profileData, transactionData, purchaseHistoryData, users, loyaltyData, tdr, tcr, balance, matured, growing, loyaltyBalance, used, redirect, gender, genderOptions, name, phone, email, address, day, month, limit, duration} = this.state;

        if(redirect == true) {
            return(<Redirect to="/registration" />);
        }

        return (
            <div className="page-wrapper">
                {/* Navigation */}
             <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? 'hide-sidemenu' : ''}`}>
                    <Loader loading={loading} />
                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Customers Profile</h1>
                            <Link to="/registration" className="breadcrumb-item">
                                Customer/Supplier
                            </Link>
                            <Link to="/home" className="breadcrumb-item">
                                Dashboard 
                            </Link>
                            {/* <Breadcrumb.Item active>Customers</Breadcrumb.Item> */}
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                  
                    {/* Basic Table */}
                    <Row style={{marginTop: 10}}>
                        
                         {/* Tab Demo One */}
                         <Col xl={12}>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="card-header">
                                        <h5 className="card-title">{profileData.name} </h5>
                                    </div>

                                    <div className="tabs-style-one">
                                    
                                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                            
                                            <Tab eventKey="home" title="Profile">
                                           
                                            <Col lg="12">
                                                <div className="profile-left-content">
                                                    <div className="card mb-4" style={{flexDirection: 'row', justifyContent:'space-between'}}>
                                                        <div className="card-body" style={{width: '25%'}}>
                                                            <div className="card-header">
                                                                <h5 className="card-title">Personal Info</h5>
                                                            </div>
                                                            <ul className="info">
                                                                <li> 
                                                                    <Icon.User 
                                                                        className="icon"
                                                                    /> 
                                                                    Gender: {profileData.gender}
                                                                </li>
                                                                <li> 
                                                                    <Icon.MapPin 
                                                                        className="icon"
                                                                    /> 
                                                                    Address: {profileData.address ? profileData.address: '---'}
                                                                </li>
                                                                <li> 
                                                                    <Icon.Aperture 
                                                                        className="icon"
                                                                    /> 
                                                                    Birthday: {profileData.day > 0 ? profileData.day : '00 -'} {profileData.month ? profileData.month : '00 -'} {profileData.year ? profileData.year : '00'}
                                                                </li>
                                                                <li> 
                                                                    <Icon.Phone 
                                                                        className="icon"
                                                                    /> 
                                                                    Phone: {profileData.phone ? profileData.phone : '---'}
                                                                </li>
                                                                <li> 
                                                                    <Icon.Mail 
                                                                        className="icon"
                                                                    /> 
                                                                    Email: {profileData.email ? profileData.email: '---'}
                                                                </li>
                                                                <li> 
                                                                    <Icon.Heart 
                                                                        className="icon"
                                                                    /> 
                                                                    Loyalty: {profileData.loyalty_code}
                                                                </li>
                                                            </ul>
                                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                                <Button onClick={() => window.confirm('Are you sure?') ? this.deleteCustomer() : null} variant="danger" className="mt-1 mr-1">Delete</Button>
                                                                {EditCustomerModal({handleSubmit: this.editCustomer.bind(this)}, gender, genderOptions, this.handleDropDownSelect, this.handleInput, name, phone, email, address, day, month, limit, duration)}
                                                            </div>
                                                        </div>
                                                        <div className="card-body" style={{width: '75%'}}>
                                                            <div className="card-header">
                                                                <h5 className="card-title">Analytical Info</h5>
                                                            </div>
                                                            <ul className="info">
                                                                <li> 
                                                                    <Icon.User 
                                                                        className="icon"
                                                                    /> 
                                                                    Name: Canada
                                                                </li>
                                                                <li> 
                                                                    <Icon.MapPin 
                                                                        className="icon"
                                                                    /> 
                                                                    Location: Canada
                                                                </li>
                                                                <li> 
                                                                    <Icon.Aperture 
                                                                        className="icon"
                                                                    /> 
                                                                    Birthday: Born March 2, 1995
                                                                </li>
                                                                <li> 
                                                                    <Icon.Phone 
                                                                        className="icon"
                                                                    /> 
                                                                    Phone: +0 (123) 456 7892
                                                                </li>
                                                                <li> 
                                                                    <Icon.Mail 
                                                                        className="icon"
                                                                    /> 
                                                                    Email: example@gmail.com
                                                                </li>
                                                                
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Tab>

                                        <Tab eventKey="profile" title="Transactions">
                                           <div className="before-table-header">
                                             
                                                <DateRange getDateRange = {{getDateRange : this.setTransactionDate.bind(this)}} />
                                                
                                                <div>
                                                    <Dropdown
                                                        placeholder='Sort by User'
                                                        // fluid
                                                        search
                                                        selection
                                                        options={users}
                                                        onChange={this.sortTransactionsByUser}
                                                    />
                                                </div>
                                                <div>
                                                    <Dropdown
                                                        placeholder='Sort by Date'
                                                        // fluid
                                                        search
                                                        selection
                                                        options={DateFilter}
                                                        onChange={this.sortTransactionsByFilter}
                                                    />
                                                </div>
                                                <div>
                                                    <Icon.RefreshCcw 
                                                        className="icon"
                                                        style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                        onClick={() => {
                                                            this.setState({loading: true, userId: ''});
                                                            this.getFunctions();
                                                        }}
                                                    /> 
                                                    Refresh
                                                </div>
                                                
                                                <font style={{fontSize: 15}} color="yellowgreen">Credit #{format(tcr, 2)}</font>

                                                <font style={{fontSize: 15}} color="tomato">Debit #{format(tdr, 2)}</font>
                                                
                                            </div>
                                            <TableComponent data={transactionData} />
                                        </Tab>                                       

                                        <Tab eventKey="contact" title="Purchase History">
                                        <div className="before-table-header">
                                             
                                            <DateRange getDateRange = {{getDateRange : this.setPurchaseHistoryDate.bind(this)}} />
                                             
                                            <div>
                                                <Dropdown
                                                    placeholder='Sort by User'
                                                    fluid
                                                    search
                                                    selection
                                                    options={users}
                                                    onChange={this.sortPurchaseHistoryByFilter}
                                                />
                                             </div>
                                             <div>
                                             <Dropdown
                                                 placeholder='Sort by Date'
                                                 // fluid
                                                 search
                                                 selection
                                                 options={DateFilter}
                                                 onChange={this.sortPurchaseHistoryByUser}
                                             />
                                             </div>
                                             <div>
                                             <Icon.RefreshCcw 
                                                 className="icon"
                                                 style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                 onClick={() => {
                                                     this.setState({loading: true, userId: ''});
                                                     this.getFunctions();
                                                 }}
                                             /> 
                                             Refresh
                                             </div>
                                             <font style={{fontSize: 15}} >Balance #{format(balance, 2)}</font>
                                         </div>
                                            <TableComponent data={purchaseHistoryData} />
                                        </Tab>

                                        <Tab eventKey="debtor" title="Loyalty">
                                        <div className="before-table-header">
                                             
                                             <DateRange getDateRange = {{getDateRange : this.setLoyaltyDate.bind(this)}} />
                                                                                        
                                             <div>
                                             <Dropdown
                                                 placeholder='Sort by Date'
                                                 // fluid
                                                 search
                                                 selection
                                                 options={DateFilter}
                                                 onChange={this.sortLoyaltyTransactionsByFilter}
                                             />
                                             </div>
                                             <div>
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true, userId: ''});
                                                        this.getFunctions();
                                                    }}
                                                /> 
                                                 Refresh
                                             </div>
                                             <font style={{fontSize: 15}} color="yellowgreen">Matured #{format(matured, 2)}</font>
                                                <font style={{fontSize: 15, color: '#aaa'}}>Growing #{format(growing, 2)}</font>
                                                <font style={{fontSize: 15, color: 'orange'}}>Used #{format(used, 2)}</font>
                                                <font style={{fontSize: 15, color: '#2962ff'}}>Total Balance #{format(loyaltyBalance, 2)}</font>
                                            </div>
                                            <TableComponent data={loyaltyData} />                                            
                                        </Tab>
                                        
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* End Tab Demo One */}

  </Row>
                    {/* End Basic Table */}

                    {/* Data Table */}
                 
 
                    
                    {/* Footer  */}    
                    <div className="flex-grow-1"></div>
                    <Footer />
                    {/* End Footer  */}   
                </div>
            </div>
        );
    }
}

export default CustomerProfile;