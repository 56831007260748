import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import * as Icon from "react-feather";
import "./SideMenu.css";
import { lookUP } from "../../../api/functions";
// import Loader from '../components/loader/loader.component';
import {
  DashboardOutlined,
  CaretRightFilled,
  CaretDownFilled,
  ShoppingCartOutlined,
  AppstoreOutlined,
  SettingOutlined,
  ArrowRightOutlined,
  UserOutlined,
  FileTextOutlined,
  MoneyCollectOutlined,
  MessageOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { RetrieveSession } from "../../session/session";

class SideMenuLight extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      id: "",
      api_token: RetrieveSession(),
      sidebarStatus: false,
    };
  }

  componentDidMount() {
    this.getSideMenu();
  }

  getSideMenu() {
    lookUP(
      "addTeam",
      { inputs: { page: "sidebarAccess" } },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        this.setState({
          sidebarStatus: responseJson.success,
        });
      }
    );
  }

  // getModule() {

  //     const arr=[];

  //     lookUP('users', {inputs: {page: 'getUsersModule'}}, this.state.api_token, responseJson => {
  //     //   console.log(responseJson);
  //       try{
  //         responseJson.map((item) => {
  //             if(item.module_name === 'Transaction'){
  //                 arr.push({
  //                     id: '1',
  //                     title: 'Transaction',
  //                     menu: [
  //                         {name: 'Sales/Invoice', page: '/sales-list'},
  //                         {name: 'Expenses', page: '/expense-list'},
  //                         {name: 'Payments', page: '/payment-list'},
  //                         {name: 'Estimate', page: '/estimate-list'}
  //                     ],
  //                     icon: <ShoppingCartOutlined  className="icon" />
  //                 })
  //             } else if(item.module_name === 'Inventory') {
  //                 arr.push({
  //                     id: '2',
  //                     title: 'Inventory',
  //                     menu: [
  //                         {name: 'Manage Product', page: '/products'},
  //                         {name: 'Product Grouping', page: '/product-grouping'},
  //                         {name: 'Inventory', page: '/inventory'}
  //                     ],
  //                     icon: <AppstoreOutlined  className="icon" />
  //                 })
  //             } else if(item.module_name === 'Account') {
  //                 arr.push({
  //                     id: '3',
  //                     title: 'Account',
  //                     menu: [
  //                         {name: 'Customer/Supplier', page: '/registration'},
  //                         {name: 'Manage Banks', page: '/banks'},
  //                         {name: 'Account Category', page: '/account-category'}
  //                     ],
  //                     icon: <UserOutlined  className="icon" />
  //                 })
  //             } else if(item.module_name === 'Report Ledger') {
  //                 arr.push({
  //                     id: '4',
  //                     title: 'Report Ledger',
  //                     menu: [
  //                         {name: 'Income Statement', page: '/income-statement'}
  //                     ],
  //                     icon: <FileTextOutlined  className="icon" />
  //                 })
  //             } else if(item.module_name === 'Settings') {
  //                 arr.push({
  //                     id: '5',
  //                     title: 'Settings',
  //                     menu: [
  //                         {name: 'User Management', page: '/users'},
  //                         {name: 'Measurement Management', page: '/manage-measurement'},
  //                         {name: 'Manage Locations', page: '/manage-location'},
  //                         {name: 'Loyalty Management', page: '/manage-loyalty'},
  //                     ],
  //                     icon: <SettingOutlined  className="icon" />
  //                 })
  //             } else if(item.module_name === 'Bills') {
  //                 arr.push({
  //                     id: '6',
  //                     title: 'Bills',
  //                     menu: [
  //                         {name: 'Airtime', page: '/airtime'},
  //                         {name: 'Data', page: '/data'},
  //                         {name: 'Electricity', page: '/electricity'},
  //                         {name: 'TV Subcription', page: '/tv-subscription'},
  //                         {name: 'Wallet Top Up', page: '/top-up'}
  //                     ],
  //                     icon: <MoneyCollectOutlined  className="icon" />
  //                 })
  //             } else if(item.module_name === 'SMS') {
  //                 arr.push({
  //                     id: '7',
  //                     title: 'SMS',
  //                     menu: [
  //                         {name: 'Send SMS', page: '/sms'},
  //                         {name: 'Buy SMS', page: '/buy-sms'},
  //                         {name: 'Patronage Settings', page: '/patronage'}
  //                     ],
  //                     icon: <MessageOutlined className="icon" />
  //                 })
  //             } else if(item.module_name === 'Subscription') {
  //                 arr.push({
  //                     id: '8',
  //                     title: 'Subscription',
  //                     menu: [
  //                         {name: 'Subscription', page: '/subscription'}
  //                     ],
  //                     icon: <ImportOutlined  className="icon" />
  //                 })
  //             }

  //         });

  //         this.setState({
  //           data: arr,
  //           loading: false
  //         });

  //         // return true;

  //       } catch(error) {
  //         this.revoke();
  //       }
  //     });
  //   }

  //   revoke() {
  //       this.getModule();
  //   }

  render() {
    const { sidebarStatus } = this.state;
    return (
      <div
        className={`sidemenu-area sidemenu-light ${
          this.props.sideMenu ? "" : "sidemenu-toggle"
        }`}
      >
        <Navbar
          className={`sidemenu ${this.props.sideMenu ? "" : "hide-nav-title"}`}
        >
          <Navbar.Collapse>
            <Nav>
              <NavLink to="/dashboard" className="nav-link">
                <DashboardOutlined className="icon" />
                <span className="title">Dashboard</span>
              </NavLink>
              {sidebarStatus == true ? (
                <NavLink to="/teamAccount" className="nav-link">
                  <Icon.Award className="icon" />
                  <span className="title">Team Account</span>
                </NavLink>
              ) : null}

              {/* <NavLink to={{pathname: 'https://trainning.omnixbmsdev.com/login'}} target="_blank" className="nav-link">
                            <Icon.Monitor  className="icon"  /> 
                            <span className="title">Training</span>
                            </NavLink>  */}

              <NavLink to="/user-profile" className="nav-link">
                <Icon.User className="icon" />
                <span className="title">Profile</span>
              </NavLink>

              <NavLink to="/withdrawal" className="nav-link">
                <Icon.DollarSign className="icon" />
                <span className="title">Transactions</span>
              </NavLink>

              {/* <NavDropdown 
                                className="super-color" 
                                title= {
                                    <div className="dropdown-title">
                                        <Icon.Filter 
                                            className="icon"
                                        />
                                        <span className="title">
                                            Account 
                                            <Icon.ChevronRight 
                                                className="icon fr"
                                            />
                                        </span>
                                    </div>
                                }
                                id="basic-nav-dropdown">
                                <NavLink  
                                    activeClassName="drpMenu"
                                    to="/teamAccount" 
                                    className="dropdown-item">
                                    <Icon.Bell 
                                        className="icon" 
                                    />
                                    Team Account
                                </NavLink>
                                <NavLink 
                                    activeClassName="drpMenu"
                                    to="/ui-components/badges/" 
                                    className="dropdown-item"> 
                                    <Icon.Award 
                                        className="icon" 
                                    /> 
                                    All Accounts
                                </NavLink>
                                <NavLink 
                                    activeClassName="drpMenu"
                                    to="/ui-components/buttons/" 
                                    className="dropdown-item">
                                    <Icon.ArrowRightCircle 
                                        className="icon" 
                                    />
                                    Due Account
                                </NavLink>
                                <NavLink 
                                    activeClassName="drpMenu"
                                    to="/ui-components/cards/" 
                                    className="dropdown-item">
                                    <Icon.Layers 
                                        className="icon" 
                                    />
                                    New Account (Month)
                                </NavLink>
                            </NavDropdown> */}

              {/* <NavDropdown 
                                className="super-color" 
                                title= {
                                    <div className="dropdown-title">
                                        <Icon.Filter 
                                            className="icon"
                                        />
                                        <span className="title">
                                            Payments
                                            <Icon.ChevronRight 
                                                className="icon fr"
                                            />
                                        </span>
                                    </div>
                                }
                                id="basic-nav-dropdown">
                                <NavLink  
                                    activeClassName="drpMenu"
                                    to="/ui-components/alerts/" 
                                    className="dropdown-item">
                                    <Icon.Bell 
                                        className="icon" 
                                    />
                                    Payment History
                                </NavLink>
                                <NavLink 
                                    activeClassName="drpMenu"
                                    to="/ui-components/badges/" 
                                    className="dropdown-item"> 
                                    <Icon.Award 
                                        className="icon" 
                                    /> 
                                    Unpaid Commision
                                </NavLink>
                            </NavDropdown> */}

              {/* <NavLink to="/inbox/" className="nav-link">
                                <Icon.Inbox 
                                    className="icon"
                                /> 
                                <span className="title">Profile</span>
                            </NavLink>

                            <NavLink to="/inbox/" className="nav-link">
                                <Icon.Inbox 
                                    className="icon"
                                /> 
                                <span className="title">Knowledgebase</span>
                            </NavLink> */}

              {/* {
                                data.map((item, index) => (                            
                                    <div key={index}>
                                    <NavDropdown 
                                        className="super-color" 
                                        title= {
                                            <div className="dropdown-title">
                                                {item.icon}
                                                <span className="title">
                                                    {item.title}
                                                    <Icon.ChevronRight 
                                                        className="icon fr"
                                                    />
                                                </span>
                                            </div>
                                        }
                                        id="basic-nav-dropdown">
                                        {
                                            item.menu.map((i, x) => (
                                                <div key={x}>
                                                <NavLink  
                                                    activeClassName="drpMenu"
                                                    to={i.page} 
                                                    className="dropdown-item"
                                                    >
                                                     <Icon.ArrowRightCircle 
                                                            className="icon" 
                                                        />
                                                    {i.name}
                                                </NavLink>    
                                                </div>                                
                                            ))
                                        }         
                                    </NavDropdown>
                                    </div>
                                ))
                            } */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default SideMenuLight;
