import React, { useState } from "react";
import ModalComponent from "../../components/modal/modal";
import AccountModalComponent from "../../components/modal/accountModal";
import InstructionModalComponent from "../../components/modal/instructionModal";
import AgentAcceptModal from "../../components/modal/agentAcceptTermsModel";
import {
  Row,
  Form,
  Button,
  Image,
  Alert,
  Container,
  Input,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
// import { phone } from 'faker/locale/en_CA';

export function AccountDetailsModal(bankName, accountNumber, accountName) {
  return (
    //   ModalComponent({content, action, heading, handleSubmit, errorMsg, errorStatus}){
    <AccountModalComponent
      action="Account details"
      heading="Bank account details"
      errorMsg=""
      errorStatus=""
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Bank name"
                type="text"
                value={bankName}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Account number"
                type="text"
                value={accountNumber}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Account name"
                type="text"
                value={accountName}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      }

      // handleSubmit={() => teamObj()}
    />
  );
}

export function PassportIdModal(
  updateImage,
  onFileChange1,
  onFileChange2,
  status,
  response
) {
  return (
    //   ModalComponent({content, action, heading, handleSubmit, errorMsg, errorStatus}){
    <ModalComponent
      action="Update passport & ID"
      heading="Update your passport & ID here"
      errorMsg={response}
      errorStatus={status}
      content={
        <Form>
          <Form.Row>
            <Form.Group>
              <Form.Label>Upload Passport</Form.Label>
              <Form.Control
                onChange={onFileChange1}
                name="passport"
                type="file"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Upload Identity Card</Form.Label>
              <Form.Control
                onChange={onFileChange2}
                name="idcard"
                type="file"
              />
            </Form.Group>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => updateImage()}
    />
  );
}

export function AcceptTermsModal({ agent_name, handleInput, updateTerms }) {
  return (
    //   ModalComponent({content, action, heading, handleSubmit, errorMsg, errorStatus}){
    <AgentAcceptModal
      content={
        <Form>
          <Form.Row>
            <div>
              <p>
                <b> Dear Affiliate {agent_name} </b>
              </p>
              <p>
                You are welcome on board as Omnix Affiliate Ambassador and we
                sincerely appreciate your time for the registration exercise.
              </p>
              <p>
                Before you proceed, you are expected to go through and review
                our{" "}
                <a
                  href="https://omnixbms.com/agentsAgreements.php"
                  className="readme"
                >
                  Affiliate Agreement
                </a>
              </p>
            </div>

            <span>
              <input
                type="checkbox"
                name="agree"
                className="checkbox"
                id="termschkbx"
                onChange={handleInput}
              />
              I agree to Terms & Conditions.
              <a
                href="https://omnixbms.com/agentsAgreements.php"
                className="readme"
              >
                Read here
              </a>{" "}
            </span>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => updateTerms()}
    />
  );
}

export function InsturctionModal() {
  return (
    //   ModalComponent({content, action, heading, handleSubmit, errorMsg, errorStatus}){
    <InstructionModalComponent
      content={
        <div>
          <p>
            <b> Dear Agents </b>
          </p>
          <p>
            <b>
              {" "}
              You are welcome on board as Omnix Brand Ambassador and we
              sincerely appreciate your time for the training.{" "}
            </b>
          </p>
          <p>
            <b>
              To be fully absorbed into our team, please upload your passport
              and any government isssued ID Card of yours, this is to enable us
              to know you more and give you access to all features on the
              portal. Please see link below.
            </b>
          </p>

          <Link to="/user-profile">
            <strong> Click here to add your bank details</strong>
          </Link>
          <br></br>

          <Link to="/update-profile" target="_blank">
            <strong> Click here to upload your Passport and Id Card</strong>
          </Link>

          <p>
            <b>
              Also, be aware that our tech team are fully on ground to give
              neccesary support, in such case, please reach us on the whatsap
              group or call the designated numbers.
            </b>
          </p>

          <p>
            <b>We wish you a fruitful and hitch free exercise.</b>
          </p>

          <p>
            <b>Best regards</b>
          </p>

          <p>
            <b>Admin.</b>
          </p>
        </div>
      }
    />
  );
}

export function TeamModal(
  teamObj,
  handleInput,
  response,
  status,
  formS,
  team_name,
  team_target,
  team_member_target,
  team_size,
  logistic_percentage,
  task_unit_value,
  heading
) {
  return (
    //   ModalComponent({content, action, heading, handleSubmit, errorMsg, errorStatus}){
    <ModalComponent
      action={formS}
      heading={heading}
      errorMsg={response}
      errorStatus={status}
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Team Name"
                type="text"
                value={team_name}
                name="name"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Team Target"
                name="team_target"
                value={team_target}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                name="team_member_target"
                value={team_member_target}
                placeholder="Team Member Target"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                name="team_size"
                value={team_size}
                placeholder="Team Size"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="tel"
                placeholder="Logistic Percentage"
                value={logistic_percentage}
                name="logistic_percentage"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Task Unit Value"
                value={task_unit_value}
                name="task_unit_value"
                onChange={handleInput}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => teamObj()}
    />
  );
}

export function LeadAgentModal(
  agentLeadObj,
  handleInput,
  response,
  status,
  formS,
  team_name,
  heading,
  leadData
) {
  return (
    //   ModalComponent({content, action, heading, handleSubmit, errorMsg, errorStatus}){
    <ModalComponent
      action={formS}
      heading={heading}
      errorMsg={response}
      errorStatus={status}
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Team Name"
                type="text"
                value={team_name}
                name="name"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group>
              <Form.Control
                as="select"
                onChange={this.handleSelectChange}
                name="lead_type"
              >
                <option value="">Lead Type</option>
                {leadData
                  ? leadData.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))
                  : null}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => agentLeadObj()}
    />
  );
}

export function EditCustomerModal(
  handleSubmit,
  gender,
  genderOptions,
  handleDropDownSelect,
  handleInput,
  name,
  phone,
  email,
  address,
  day,
  month,
  limit,
  duration
) {
  return (
    <ModalComponent
      action="Edit"
      heading="Edit Customer"
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Name"
                type="text"
                name="name"
                value={name}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Dropdown
                placeholder="Select Gender"
                fluid
                search
                selection
                defaultValue={gender}
                options={genderOptions}
                onChange={handleDropDownSelect}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="tel"
                name="phone"
                placeholder="Phone"
                onChange={handleInput}
                value={phone}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                onChange={handleInput}
                value={email}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                onChange={handleInput}
                value={address}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Day of birth e.g. 09"
                name="day"
                onChange={handleInput}
                value={day > 0 ? day : ""}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Month of birth e.g. June"
                name="month"
                onChange={handleInput}
                value={month}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Credit limit"
                name="limit"
                onChange={handleInput}
                value={limit > 0 ? limit : ""}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Credit duration, e.g. 30 days"
                name="duration"
                onChange={handleInput}
                value={duration}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => handleSubmit.handleSubmit()}
    />
  );
}

export function VendorModal(
  handleSubmit,
  genderOptions,
  handleDropDownSelect,
  handleInput
) {
  return (
    <ModalComponent
      action="New Supplier"
      heading="Create Supplier"
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Name"
                type="text"
                name="name"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Dropdown
                placeholder="Select Gender"
                fluid
                search
                selection
                options={genderOptions}
                onChange={handleDropDownSelect}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="tel"
                name="phone"
                placeholder="Phone"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                onChange={handleInput}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => handleSubmit.handleSubmit()}
    />
  );
}

export function EditVendorModal(
  handleSubmit,
  gender,
  genderOptions,
  handleDropDownSelect,
  handleInput,
  name,
  phone,
  email,
  address
) {
  return (
    <ModalComponent
      action="Edit"
      heading="Edit Supplier"
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                placeholder="Name"
                type="text"
                name="name"
                value={name}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Dropdown
                placeholder="Select Gender"
                fluid
                search
                selection
                defaultValue={gender}
                options={genderOptions}
                onChange={handleDropDownSelect}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="tel"
                name="phone"
                placeholder="Phone"
                onChange={handleInput}
                value={phone}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                onChange={handleInput}
                value={email}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                onChange={handleInput}
                value={address}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      }
      handleSubmit={() => handleSubmit.handleSubmit()}
    />
  );
}

export function LoyaltyModal(handleSubmit, handleInput, handleClick) {
  return (
    <ModalComponent
      action="Set Up Loyalty"
      heading="Set Up Loyalty"
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="tel"
                name="loyalty_percentage"
                placeholder="Loyalty Percentage"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="email"
                name="maturity_level"
                placeholder="Maturity Level"
                onChange={handleInput}
              />
            </Form.Group>
          </Form.Row>
          <Form.Group id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              label="Send SMS to your customer when loyalty is matured"
              onClick={() => handleClick.handleClick()}
            />
          </Form.Group>
        </Form>
      }
      handleSubmit={() => handleSubmit.handleSubmit()}
    />
  );
}

export function EditLoyaltyModal(
  handleSubmit,
  handleInput,
  loyalty_percentage,
  maturity_level,
  loyaltyStatus,
  handleClick
) {
  return (
    <ModalComponent
      action="Edit Loyalty"
      heading="Edit Loyalty"
      content={
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="tel"
                name="loyalty_percentage"
                value={loyalty_percentage}
                placeholder="Loyalty Percentage"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group controlId="formGridAddress1">
              <Form.Control
                type="email"
                name="maturity_level"
                value={maturity_level}
                placeholder="Maturity Level"
                onChange={handleInput}
              />
            </Form.Group>
          </Form.Row>
          <Form.Group id="formGridCheckbox">
            <Form.Check
              defaultChecked={loyaltyStatus == 1 ? "checked" : ""}
              type="checkbox"
              label="Send SMS to your customer when loyalty is matured"
              onClick={() => handleClick.handleClick()}
            />
          </Form.Group>
        </Form>
      }
      handleSubmit={() => handleSubmit.handleSubmit()}
    />
  );
}
