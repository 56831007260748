import React, { useState } from "react";
import ModalComponent from "../../components/modal/modal";
import LeadAgentComponentModal from "../../components/modal/leadAgentModal";
import InstructionModalComponent from "../../components/modal/instructionModal";
import {
  Row,
  Form,
  Button,
  Image,
  Alert,
  Container,
  Card,
  Modal,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import affiliateImage from "../../affiliates3.jpeg";
import { lookUP, save, saveFile } from "../../api/functions";
import { RetrieveSession } from "../../components/session/session";
import axios from "axios";
import Logo from "../../assets/img/trans2.png";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";

const leadData = [
  { label: "Classic Lead Agent(CLA)", value: "5" },
  { label: "Legend Lead Agent(LLA)", value: "6" },
];

class LeadAgentGuild extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      leadType: "",
      api_token: RetrieveSession(),
      response: "",
      status: "",
      show: false,
      loading: false,
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  handleClose() {
    this.setState({ show: !this.state.show });
  }

  handleInput = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSelectChange(event) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  createTeam() {
    const input = {
      name: this.state.name,
      agentCategory: this.state.leadType,
      page: "registerTeam",
    };
    this.setState({ loading: true });
    // console.log(input, this.state.api_token);
    // return;
    save("addTeam", { inputs: input }, this.state.api_token, (responseJson) => {
      console.log(responseJson);
      //   return false;
      if (responseJson.success === true) {
        this.setState({
          response: responseJson.data,
          status: "success",
          loading: false,
        });
      } else {
        this.setState({
          response: responseJson.data,
          status: "danger",
          loading: false,
        });
      }
    });
  }

  render() {
    const {
      loading,
      email,
      password,
      respo,
      conRes,
      stateList,
      stateStatus,
      verificationStatus,
      localGovList,
      show,
      response,
      status,
      name,
    } = this.state;
    return (
      <LeadAgentComponentModal
        action="Become a Lead Agent"
        content={
          <div className="auth-main-content">
            <div className="d-table">
              <div className="d-tablecell">
                <div className="welcome-box">
                  <Row>
                    <Card className="notes-card">
                      <Card.Body>
                        <CardMedia
                          component="img"
                          image={affiliateImage}
                          style={{
                            left: 0,
                            bottom: 0,
                            right: 0,
                            top: 0,
                            resizeMode: "contain",
                          }}
                        />
                        <p></p>

                        <Card.Text>
                          <h3>Guildlines for aspiring Agent Lead.</h3>
                          <p></p>

                          <p></p>

                          <Typography gutterBottom variant="h6" component="div">
                            Lead agent expected characteristics
                          </Typography>
                          <p></p>
                          <ol>
                            <li>
                              Lead agents must must have 10 account managers on
                              their team
                            </li>
                            <li>
                              A lead agent must be someone with capacity to lead
                              the account managers and encourage them to achieve
                              mininum of 10 subscriptions every month
                            </li>
                            <li>
                              Lead agents must educate and contribute to the
                              success of every member of the team.
                            </li>
                          </ol>
                          <p></p>
                          <Typography gutterBottom variant="h5" component="div">
                            Types of Lead Agents
                          </Typography>
                          <p></p>
                          <ol>
                            <li>
                              Classic Lead Agent(CLA), Earning: 5% one-off on
                              the new customers' subscriptions within the
                              license tenor.
                            </li>
                            <li>
                              Legend Lead Agent(LLA), Earning: 2.5% on both new
                              and recurrent customers' subsriptions within the
                              license tenor.
                            </li>
                          </ol>
                          <p></p>
                          <Typography gutterBottom variant="h5" component="div">
                            Rules of engagement
                          </Typography>

                          <ol>
                            <li>
                              A lead Agent cannot earn lead agent commission
                              when the team size is not at least 10
                            </li>
                            <li>
                              Lead Agent earnings become withdrawable only when
                              the team achieve monthly minimum target of 10 new
                              customers' subscription
                            </li>
                            <li>
                              if there's a month the team could not archieve the
                              minimum target, the lead Agent earnings will be
                              available but not withdrawable.
                            </li>
                          </ol>
                          <p></p>

                          <Typography gutterBottom variant="h5" component="div">
                            Tenor & Renewability
                          </Typography>
                          <p></p>
                          <ol>
                            <li>Lead Agents have two(2) years tenor</li>
                            <li>Renewable upon impressive performance.</li>
                          </ol>
                          <p></p>

                          {response !== "success" ? (
                            <Box
                              className="btn"
                              onClick={() => this.handleClose()}
                            >
                              <span>Proceed</span>
                            </Box>
                          ) : (
                            <Link to="./teamAccount" className="btn">
                              Go back
                            </Link>
                          )}

                          {/* <Button
                      className="btn"
                      variant="primary"
                      onClick={() => this.handleClose()}
                    >
                      Proceed
                    </Button> */}
                          {/* <div style={{ width: "100%", backgroundColor: "red", }}>
                      {LeadAgentModal(
                        () => this.createTeam(),
                        this.handleInput,
                        this.handleSelectChange,
                        this.state.response,
                        this.state.status,
                        formS,
                        this.state.name,
                        "Lead Agent Form"
                      )}
                    </div> */}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Row>
                </div>
              </div>
            </div>
            <Modal show={show} onHide={() => this.handleClose()}>
              <Modal.Header closeButton>
                <Modal.Title>Lead Agent Form</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {}
                <Alert
                  variant={status}
                  className="center"
                  style={{
                    justifySelf: "center",
                    alignItems: "center",
                    top: 10,
                  }}
                >
                  {response}
                </Alert>
                {loading == true ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      top: -10,
                      zIndex: 10,
                      width: "100%",
                    }}
                  >
                    <div class="spinner-border center">
                      <span class="sr-only center">Loading...</span>
                    </div>
                  </div>
                ) : null}
                <Form>
                  <Form.Row>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        placeholder="Team Name"
                        type="text"
                        value={name}
                        name="name"
                        onChange={this.handleInput}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Control
                        as="select"
                        onChange={this.handleSelectChange}
                        name="leadType"
                      >
                        <option value="">Lead Type</option>
                        {leadData
                          ? leadData.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))
                          : null}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                {/* <Button variant="danger" onClick={handleClose}>
                          Close
                      </Button> */}
                <Button variant="primary" onClick={() => this.createTeam()}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        }
      />
    );
  }
}
export default LeadAgentGuild;
