import React from 'react';

const Loader = ({loading}) => (
    <div className="loader">
        {
            loading
            ?
                <div style={{position: 'absolute', right: 100}} className="spinner-border text-info" role="status"></div>
            :
            ''
        }
        
    </div>
);


export default Loader;