export const format = (num, decimal='', check='') => {
    decimal = decimal == '' ? 0 : 2;
    return(
        num != null
        ?
            check != ''
            ?
            num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            :
            parseInt(num).toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        :
        0
    );
}