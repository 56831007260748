import React from 'react';
import Navigation from '../../components/Navigation/Navigation';
import {Row, Breadcrumb, Col } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Loader from '../../components/Common/Loader';
import SalesStatistics from '../../components/Dashboard/SalesStatistics';
import PriceMovements from '../../components/Dashboard/PriceMovements';
import LastMonthSales from '../../components/Dashboard/Sales/LastMonthSales';
import ProductSolds from '../../components/Dashboard/Sales/ProductSolds';
import NewOrders from '../../components/Dashboard/Sales/NewOrders';
import NewCustomers from '../../components/Dashboard/Sales/NewCustomers';
import NewCustomersList from '../../components/Dashboard/NewCustomersList';
import NewOrdersTable from '../../components/Dashboard/NewOrdersTable';
import SocialMarketing from '../../components/Dashboard/SocialMarketing';
import ProductsOfTheMonth from '../../components/Dashboard/ProductsOfTheMonth';
import OrderStats from '../../components/Dashboard/OrderStats';
import { RetrieveSession } from '../../components/session/session';

class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            sideMenu: true,
            loading: true,
            api_token: RetrieveSession()
        };
    }    

    // Loading icon false after DOM loaded
    componentDidMount() {
       
            this.setState({ loading: false });
        
    }

    componentWillUnmount(){
        clearInterval(this.myInterval);
    }

    // Toggle side bar menu
    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    }
    
    render() {
        
        
        const {loading} = this.state;
        
        return (
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */} 
                
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? 'hide-sidemenu' : ''}`}>
                    {/* Loader */}
                    <Loader loading={loading} />
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Dashboard</h1> 
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    <Row>
                        <Col sm={6} lg={3}>
                            {/* File Path: src/components/Dashboard/Sales/SalesLastMonthSales.js */}
                            <LastMonthSales />
                        </Col>

                        <Col sm={6} lg={3}>
                            {/* File Path: src/components/Dashboard/Sales/ProductSolds.js */}
                            <ProductSolds />
                        </Col>
 
                        <Col sm={6} lg={3}>
                            {/* File Path: src/components/Dashboard/Sales/NewOrders.js */}
                            <NewOrders />
                        </Col>

                        <Col sm={6} lg={3}>
                            {/* File Path: src/components/Dashboard/Sales/NewCustomers.js */}
                            <NewCustomers />
                        </Col>
                    </Row>

                    {/* Sales Statistics & New Customers Card */}
                    <div className="row">
                        <Col lg={12}>
                            {/* File Path: src/components/Dashboard/SalesStatistics.js */}
                            <SalesStatistics />
                        </Col>

                        <Col lg="8">
                            {/* File Path: src/components/Dashboard/PriceMovements.js */}
                            <PriceMovements />
                        </Col>

                        <Col lg={4}>
                            {/* File Path: src/components/Dashboard/NewCustomersList.js */}
                            <NewCustomersList />
                        </Col>
                    </div>
                    {/* End Sales Statistics & New Customers Card */}

                    {/* New Orders & Marketing Card */}
                    <div className="row">
                        <Col lg={8}>
                            {/* File Path: src/components/Dashboard/NewOrdersTable.js */}
                            <NewOrdersTable />
                        </Col>

                        <Col lg={4}>
                            {/* File Path: src/components/Dashboard/SocialMarketing.js */}
                            <SocialMarketing />
                        </Col>
                    </div>
                    {/* End New Orders & Marketing Card */}

                    {/* Top Product & Notification */}
                    <Row>
                        <Col lg={7}>
                            {/* File Path: src/components/Dashboard/ProductsOfTheMonth.js */}
                            <ProductsOfTheMonth />
                        </Col>

                        <Col lg={5}>
                            {/* File Path: src/components/Dashboard/OrderStats.js */}
                            <OrderStats />
                        </Col>
                    </Row>
                    {/* End Top Product & Notification */}

                    {/* Footer */}
                    <div className="flex-grow-1"></div>
                    <Footer /> 
                    {/* End Footer */}
                </div>
            </div>
        );
    }
}

export default Home;