import React from "react";
import { Route, Redirect } from "react-router-dom";

import Home from "../screens/Dashboard/Dashboard";
import Welcome from "../screens/Welcome";
import DashboardTeamMember from "../screens/Dashboard/mainDashboard";
import Inbox from "../screens/Inbox/Inbox";
import Chat from "../screens/Chat/Chat";
import Todos from "../screens/Todos";
import Notes from "../screens/Notes";
import Calendar from "../screens/Calendar";
import Search from "../screens/Search";
import Alerts from "../screens/drive/Alerts";
import Badges from "../screens/drive/Badges";
import Buttons from "../screens/drive/Buttons";
import Cards from "../screens/drive/Cards";
import Dropdowns from "../screens/drive/Dropdowns";
import Forms from "../screens/drive/Forms";
import ListGroups from "../screens/drive/ListGroups";
import Modals from "../screens/drive/Modals";
import ProgressBars from "../screens/drive/ProgressBars";
import TemplateTabs from "../screens/drive/TemplateTabs";
import Signup from "../screens/Signup";
import ForgotPassword from "../screens/ForgotPassword";
import NewPassword from "../screens/NewPassword";
// import Profile from '../screens/Profile';
// import ProfileSettings from '../screens/ProfileSettings';
import LineCharts from "../screens/Apex-Charts/LineCharts";
import AreaCharts from "../screens/Apex-Charts/AreaCharts";
import ColumnCharts from "../screens/Apex-Charts/ColumnCharts";
import BarCharts from "../screens/Apex-Charts/BarCharts";
import MixedCharts from "../screens/Apex-Charts/MixedCharts";
import PieDonutsCharts from "../screens/Apex-Charts/PieDonutsCharts";
import CustomGoogleMap from "../screens/Map/CustomGoogleMap";
import CustomJVectorMap from "../screens/Map/CustomJVectorMap";
import FeatherIcons from "../screens/Icons/FeatherIcons";
import IcofontIcons from "../screens/Icons/IcofontIcons";
import Notification from "../screens/Notification";
import UsersCard from "../screens/UsersCard";
import TimeLine from "../screens/TimeLine";
import InvoiceTemplate from "../screens/InvoiceTemplate";
import CustomGallery from "../screens/Gallery/CustomGallery";
import Faq from "../screens/Faq";
import Pricing from "../screens/Pricing";
import FourHandedFourError from "../screens/FourHandedFourError";

import Login from "../screens/login/Login";
import Registration from "../screens/Registration";

import TeamAccounts from "../screens/team/teamAccount";
import TeamMember from "../screens/team/teamMember";
import ReferredClient from "../screens/team/referredClient";
import Payments from "../screens/payments/payments";
import Account from "../screens/account/account";
import CustomerProfile from "../screens/reg/Customer/customer-profile";
import VendorProfile from "../screens/reg/Customer/vendor-profile";
import Profile from "../screens/profile/profile";
import Banks from "../screens/reg/Bank/banks";
import ImageUpload from "../screens/imageUpload";
import Withdrawal from "../screens/withdraw/withdraw";
import LeadAgentGuild from "../screens/AgentGuild/";

import Airtime from "../screens/bills/airtime";
import Data from "../screens/bills/data";
import SmsVerification from "../screens/login/smsVerification";

import "../assets/css/style.css";
import "../assets/css/responsive.css";

const AppRouter = () => (
  <React.Fragment>
    <Route exact path="/" render={() => <Redirect to="/login" />} />
    {/* <Route
      exact
      path="/portal/registration"
      render={() => <Redirect to="/registration" />}
    />
    <Route
      exact
      path="/portal/registration/:code"
      render={() => <Redirect to="/registration/:code" />}
    /> */}
    {/* <Route path="/welcome" component={Welcome} /> */}
    <Route path="/login" component={Login} />
    <Route exact path="/registration/:code" component={Registration} />
    <Route exact path="/registration" component={Registration} />
    <Route exact path="/smsVerification" component={SmsVerification} />
    <Route path="/forgot-password/" component={ForgotPassword} />
    <Route path="/new-password/" component={NewPassword} />

    <Route path="/teamAccount" component={TeamAccounts} />
    <Route path="/payments" component={Payments} />
    <Route path="/account" component={Account} />
    <Route path="/customer-profile/:id" component={CustomerProfile} />
    <Route path="/vendor-profile/:id" component={VendorProfile} />
    <Route path="/memberAccount/:agentRefId" component={TeamMember} />
    <Route path="/referred-client/:agentRef,:date" component={ReferredClient} />
    <Route path="/user-profile" component={Profile} />
    <Route path="/update-profile" component={ImageUpload} />
    <Route path="/withdrawal" component={Withdrawal} />

    <Route path="/banks" component={Banks} />
    <Route path="/airtime" component={Airtime} />
    <Route path="/data" component={Data} />

    <Route path="/home" exact component={Home} />
    <Route path="/dashboard" exact component={DashboardTeamMember} />

    <Route path="/leadAgentGuild" component={LeadAgentGuild} />

    {/* <Route path="/inbox/" component={Inbox} />
        <Route path="/chat/" component={Chat} />
        <Route path="/todos/" component={Todos} />
        <Route path="/notes/" component={Notes} />
        <Route path="/calendar/" component={Calendar} />
        <Route path="/search/" component={Search} />
        <Route path="/drive/alerts/" component={Alerts} />
        <Route path="/drive/badges/" component={Badges} />
        <Route path="/drive/buttons/" component={Buttons} />
        <Route path="/drive/cards/" component={Cards} />
        <Route path="/drive/dropdowns/" component={Dropdowns} /> */}
    <Route path="/drive/forms/" component={Forms} />
    {/* <Route path="/drive/list-groups/" component={ListGroups} />
        <Route path="/drive/modals/" component={Modals} />
        <Route path="/drive/progress-bars/" component={ProgressBars} />  
        <Route path="/drive/tabs/" component={TemplateTabs} />  
        <Route path="/signup/" component={Signup} />
        <Route path="/forgot-password/" component={ForgotPassword} />
        <Route path="/profile/" component={Profile} />
        <Route path="/profile-settings/" component={ProfileSettings} />
        <Route path="/line-charts/" component={LineCharts} />
        <Route path="/area-charts/" component={AreaCharts} />
        <Route path="/column-charts/" component={ColumnCharts} />
        <Route path="/bar-charts/" component={BarCharts} />
        <Route path="/mixed-charts/" component={MixedCharts} />
        <Route path="/pie-donuts-Charts/" component={PieDonutsCharts} />
        <Route path="/google-map/" component={CustomGoogleMap} />
        <Route path="/vector-map/" component={CustomJVectorMap} />
        <Route path="/feather-icons/" component={FeatherIcons} />
        <Route path="/icofont-icons/" component={IcofontIcons} />
        <Route path="/notifications/" component={Notification} />
        <Route path="/users-card/" component={UsersCard} />
        <Route path="/time-line/" component={TimeLine} />
        <Route path="/invoice-template/" component={InvoiceTemplate} />
        <Route path="/gallery/" component={CustomGallery} />
        <Route path="/faq/" component={Faq} />
        <Route path="/pricing/" component={Pricing} />
        <Route path="/error-404/" component={FourHandedFourError} /> */}
  </React.Fragment>
);

export default AppRouter;
