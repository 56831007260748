import React from 'react';
import {Row, Col, Breadcrumb, Tabs, Tab, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Navigation from '../../components/Navigation/Navigation';
import Footer from '../Footer/Footer';
import {CustomerTab, VendorTab, CreditorTab, DebtorTab, LoyaltyTab} from '../Accounts/Customer/tabs';
import { format } from '../../components/number-format/number-format.component';
import {PaymentModal} from './modal';
import { lookUP, save, saveChanges } from '../../api/functions';
import { RetrieveSession } from '../../components/session/session';
import TableComponent from '../../components/table/table';
import * as Icon from 'react-feather';
import { getCustomers, getVendors, getCreditors, getDebtors, getLoyalty, insertVendor, insertTeam, insertLoyalty, updateLoyalty } from './functions';

class Payments extends React.Component {
    constructor(props){
        super(props);

        this.state = { sideMenu: true, api_token: RetrieveSession(), customerData: {}, vendorData: {}, creditorData: {}, debtorData: {}, loyaltyData: {}, debtors: 0, customers: '', vendors: '', creditors: 0, loyalty_sum: 0, datatable: {}, t_name: '', t_target: '', member_target: '', percentage: '', id_prefix:'', phone: '', address:'', day: '', month: '', limit: '', duration:'', loyalty:'', loyaltyExist: [], loyalty_percentage: '', maturity_level: '', loyaltyStatus: 0, loading: true, accBalance: ''
        };
    }

    _onSideMenu = (active) => this.setState({sideMenu: active});

    getFunctions() {
        getCustomers(this.state.api_token, this.setCustomers.bind(this));
        getVendors(this.state.api_token, this.setVendors.bind(this));
        getCreditors(this.state.api_token, this.setCreditors.bind(this));
        getDebtors(this.state.api_token, this.setDebtors.bind(this));
        getLoyalty(this.state.api_token, this.setLoyalty.bind(this));
    }

    componentDidMount = () => this.getFunctions();

    setCustomers = (obj, cus, debtors, creditors, loyalty_sum) => this.setState({ customerData: obj, customers: cus, debtors: debtors, creditors: creditors, loyalty_sum: loyalty_sum, loading: false });

    setVendors = (obj, ven) => this.setState({ vendorData: obj, vendors: ven, loading: false });

    setCreditors = (obj) => this.setState({creditorData: obj, loading: false });

    setDebtors = (obj) => this.setState({ debtorData: obj, loading: false });
    
    setLoyalty = (obj, loyaltyExist, loyalty_percentage, usable_level, loyaltyStatus) => this.setState({ loyaltyData: obj, loyaltyExist: loyaltyExist, loyalty_percentage: loyalty_percentage, maturity_level: usable_level, loyaltyStatus: loyaltyStatus, loading: false });

    handleDropDownSelect = (event, data) => this.setState({gender: data.value});

    handleInput = event => {
        const { value, name } = event.target;
        this.setState({[name]: value}); 
    }

    createTeam() {
        const input = {  teamName: this.state.t_name, target: this.state.t_target, memberTarget: this.state.member_target, percentage: this.state.percentage, page: 'newclientWeb', idPrefix: this.state.id_prefix}
        console.log(input); return false;
        this.setState({loading: true});
        insertTeam(input, this.state.api_token, {getFunctions: this.getFunctions.bind(this)});
        this.setState({loading: false});

    }

      saveVendor() {
        const input = { email: this.state.email, address: this.state.address, number: this.state.phone, name: this.state.name, page: 'addvendor', gender: this.state.gender }  

        this.setState({loading: true});
        insertVendor(input, this.state.api_token, {getFunctions: this.getFunctions.bind(this)});
        this.setState({loading: false});
        
      }

      saveLoyalty() {
        const input = { page: 'setLoyalty', loyaltyStatus: this.state.loyaltyStatus, maturity: this.state.maturity_level, percentage: this.state.loyalty_percentage }

        this.setState({loading: true});
        insertLoyalty(input, this.state.api_token, {getFunctions: this.getFunctions.bind(this)});
        this.setState({loading: false});
    }


    handleClick = () => this.state.loyaltyStatus == 0 ? this.setState({loyaltyStatus: 1}) : this.setState({loyaltyStatus: 0});

    editLoyalty() {
        const input = { page: 'editLoyalty', loyaltyStatus: this.state.loyaltyStatus, maturity: this.state.maturity_level, percentage: this.state.loyalty_percentage }

        this.setState({loading: true});
        updateLoyalty(input, this.state.api_token, {getFunctions: this.getFunctions.bind(this)});
        this.setState({loading: false});
    }


    Withdraw(){
        if (this.state.accBalance.length >  4) {
            alert('Allowed digit length is 5!');
            return false;
        }
        if(this.state.accBalance == ''){
          alert('Field can not be empty!');
          return false;
         } 

             this.setState({loading: true})
              const input = {
                accBalance: this.state.accBalance,
                  page: 'withdraw'
                 }
                //  console.log(input);
                //  return false;
                 lookUP('setting', { inputs: input  }, this.state.api_token, responseJson => {
                   try{
                    //  console.log(responseJson);
                       this.setState({
                       respo: responseJson.data, loading: false
                     })
                    
                      alert(this.state.respo);
                  
                    this.setState({respo: ''});
                     return true;
                   } catch(error){
                   
                   }      
                 })

       }
  
     
    render() {
        const { loading, customerData, vendorData, customers, vendors, debtors, creditors, loyalty_sum, creditorData, debtorData, loyaltyData, genderOptions, loyaltyExist, loyalty_percentage, maturity_level, loyaltyStatus, accBalance } = this.state;

        return (
            <div className="page-wrapper">
                {/* Navigation */}
             <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? 'hide-sidemenu' : ''}`}>
                    {/* <Loader loading={loading} /> */}
                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Payments</h1>
                            <Link to="/home" className="breadcrumb-item">
                                Dashboard
                            </Link>
                            {/* <Breadcrumb.Item active>Customers</Breadcrumb.Item> */}
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    <Row>
                        <Col sm={3} lg={2}> {CustomerTab(customers)} </Col>

                        <Col sm={3} lg={2}> {VendorTab(vendors)} </Col>
 
                        <Col sm={6} lg={3}> {CreditorTab(format(creditors, 2))} </Col>

                        <Col sm={6} lg={3}> {DebtorTab(format(debtors, 2))} </Col>

                        <Col sm={6} lg={2}> {LoyaltyTab(format(loyalty_sum, 2))} </Col>
                    </Row>

                    {/* Basic Table */}
                    <Row style={{marginTop: 10}}>

                         {/* Tab Demo One */}
                         <Col xl={12}>
                            <div className="card mb-4">
                                <div className="card-body">
                                   
                                    <div className="tabs-style-one">
                                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                            <Tab eventKey="home" title="Team">
                                           
                                                <div className="before-table-header-short">
                                                {PaymentModal({handleSubmit: this.Withdraw.bind(this)}, genderOptions, this.handleDropDownSelect, this.handleInput, this.state.accBalance)}
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getFunctions();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                                <TableComponent data={customerData} />

                                        </Tab>

                                        {/* <Tab eventKey="profile" title="Supplier">
                                        <div className="before-table-header-short">
                                            {VendorModal({handleSubmit: this.saveVendor.bind(this)}, genderOptions, this.handleDropDownSelect, this.handleInput)}
                                            <Icon.RefreshCcw 
                                                className="icon"
                                                style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                onClick={() => {
                                                    this.setState({loading: true});
                                                    this.getFunctions();
                                                }}
                                            /> 
                                            Refresh
                                            </div>
                                            <TableComponent data={vendorData} />
                                        </Tab>                                       

                                        <Tab eventKey="contact" title="Creditors">
                                        <div className="before-table-header-short">
                                                
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getFunctions();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                            <TableComponent data={creditorData} />
                                        </Tab>

                                        <Tab eventKey="debtor" title="Debtors">
                                        <div className="before-table-header-short">
                                                
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getFunctions();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                            <TableComponent data={debtorData} />
                                        </Tab>
                                        <Tab eventKey="creditor" title="Loyalty">
                                        <div className="before-table-header-short">
                                                {loyaltyExist != '' 
                                                ? EditLoyaltyModal({handleSubmit: this.editLoyalty.bind(this)}, this.handleInput, loyalty_percentage, maturity_level, loyaltyStatus, {handleClick: this.handleClick.bind(this)})
                                                : LoyaltyModal({handleSubmit: this.saveLoyalty.bind(this)}, this.handleInput,{handleClick: this.handleClick.bind(this)})}
                                                <Icon.RefreshCcw 
                                                    className="icon"
                                                    style={{cursor: 'pointer', color: 'yellowgreen'}}
                                                    onClick={() => {
                                                        this.setState({loading: true});
                                                        this.getFunctions();
                                                    }}
                                                /> 
                                                Refresh
                                                </div>
                                            <TableComponent data={loyaltyData} />
                                        </Tab> */}
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                     
                    <div className="flex-grow-1"></div>
                    <Footer /> 
                </div>
            </div>
        );
    }
}

export default Payments;