import React, {useState} from 'react';
// import ModalComponent from '../../components/modal/modal';
import UploadModal from '../../components/modal/uploadModal';
// import WithdrawalComponentModal from '../../components/modal/withdrawalComponentModal';
// import InstructionModalComponent from '../../components/modal/instructionModal';
import {Row, Form, Button, Image, Alert, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Dropdown } from 'semantic-ui-react';
// import { phone } from 'faker/locale/en_CA';





export function ImageUplaodModal(Upload, onFileChange1, onFileChange2, loading, response, status){

    return (

        <UploadModal

            action="Update Profile"
            heading="Upload Images"
            errorMsg={response}
            errorStatus={status}
            content={
                <Form>
                <Form.Row>
                     <Form.Group>
                        <Form.Label>Upload Passport</Form.Label>
                            <Form.Control onChange={onFileChange1} name="passport" type="file"  />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Upload Identity Card</Form.Label>
                            <Form.Control onChange={onFileChange2} name="idcard" type="file"  />
                        </Form.Group>

                                            <div className="text-center">
                                            {
                                                loading == true ?
                                               
                                                <div style={{display: 'flex', justifyContent:'center',alignItems: 'center', top: -10, zIndex: 10, width: '100%'}}>
                                                    <div class="spinner-border center" >
                                                        <span class="sr-only center">Loading...</span>
                                                    </div>
                                                </div>
                                            
                                                : null
                                            }
                                            </div>
                </Form.Row>      
                </Form>


            }

            handleSubmit={() => Upload()}

        />
    );
};


        