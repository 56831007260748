import React from 'react';
import {Row, Col, Breadcrumb, Form, Button, InputGroup, FormControl, DropdownButton, Dropdown} from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import Navigation from '../../components/Navigation/Navigation';
import Footer from '../Footer/Footer';
import ValidationForm from '../drive/ValidationForm';
import { lookUP, save } from '../../api/functions';
import { RetrieveSession } from '../../components/session/session';

class Airtime extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
            network: '',
            amount: '',
            recipent: '',
            api_token: RetrieveSession(),
            page:'',
            loading: false,
            sideMenu: true
        };
       this.handleSelectChange= this.handleSelectChange.bind(this);
    //    this.handleChange = this.handleChange.bind(this);
   }

  

   handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value});
}

handleSelectChange(event) {
    this.setState({ network: event.target.value });
}

// handlechange = (event) => {
//     const course = { ...this.state.course, title: event.target.value };
//     this.setState({ course });
//   };



    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    }

  

    buyItem() {

        const input = {
            network: this.state.network,
            amount: this.state.amount,
            recipent: this.state.recipent,
            page: 'airtime'
           }
        
    if(input.network == '' || input.amount == '' || input.recipent == '') {
                alert("None of the asterisked fields must be empty");
                return false;
            }
    

           lookUP('bills', { inputs: input  }, this.state.api_token, responseJson => {
            try{
             //  console.log(responseJson);
                this.setState({
                respo: responseJson.data,
               //  failed:
               loading: false
                })
                if(this.state.respo == 'TRANSACTION SUCCESSFUL'){
                  alert('Transaction Successful');
                  this.setState({respo: ''})
                }else{
                  alert(this.state.respo);
                }
              return true;
            } catch(error){
              this.revoke();
            }      
          })

      };

    render() {
        console.log(this.state.recipent);
       // const { network, amount, recipent } = this.state;
        return (
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? 'hide-sidemenu' : ''}`}>
                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Forms</h1>
                            <Link to="/dashboard" className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>Forms</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    {/* Basic Forms */}
                    <Row>
                        <Col xl={6}>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="card-header">
                                        <h5 className="card-title">Basic Forms</h5>
                                    </div>


                                    {/* <Form> */}
                                        <Form.Group as={Col} controlId="formGridState">
                                                <Form.Label>Select Network</Form.Label>
                                                <Form.Control as="select" onChange={ this.handleSelectChange } name="network" > 
                                                <option value='mtn'>MTN</option>
                                                <option value='glo'>GLO</option>
                                                <option value='airtel'>Airtel</option>
                                                <option value='etisalat'>9Mobile</option>
                                                </Form.Control>
                                        </Form.Group>



                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Airtime Amount</Form.Label>
                                            <Form.Control onChange={this.handleChange} type="text" placeholder="Enter Airtime Amount" name="amount"  />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control onChange={this.handleChange} type="number" placeholder="Enter Mobile Number" name="recipent"  />
                                        </Form.Group>

                                       

                                        <Button variant="primary" type="submit" onClick={() => this.buyItem()}>
                                            Submit
                                        </Button>
                                    {/* </Form> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* End Basic Forms */}

                    {/* Horizontal forms */}
                                        {/* End InputGroup Buttons with Dropdowns */}

                    {/* Footer  */}    
                    <div className="flex-grow-1"></div>
                    <Footer />
                    {/* End Footer  */}   
                </div>
            </div>
        );
    }
}

export default Airtime;