import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import { lookUP } from "../../api/functions";
// import Loader from '../components/loader/loader.component';
import { RetrieveSession } from "../../components/session/session";
// Logo image file path
import Logo from "../../assets/img/trans2.png";
// import './login.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      api_token: "",
      loading: false,
    };
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  login = () => {
    const input = {
      email: this.state.email,
      password: this.state.password,
      appId: "4",
    };

    if (input.email === "" || input.password === "") {
      alert("None of the fields must be empty.");
      return false;
    }

    this.setState({ loading: true });

    lookUP(
      "loginAgent",
      { inputs: input },
      this.state.api_token,
      (responseJson) => {
        console.log(responseJson);
        // return;
        if (responseJson["success"] !== true) {
          alert(responseJson["data"]);
          this.setState({ loading: false });
          return false;
        }
        sessionStorage.setItem("api_token", responseJson.api_token);
        this.setState({ loading: false, redirect: true });
      }
    );
  };

  render() {
    const { loading, email, password } = this.state;

    if (RetrieveSession()) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="auth-main-content">
        <div className="d-table">
          <div className="d-tablecell">
            {/* <Loader loading={loading} styles="login-loader" /> */}
            <div className="login-logo">
              <Image className="login-logo logo" src={Logo} alt="Logo" />
            </div>
            <div className="auth-box">
              <Row className="login-container">
                <Col>
                  <div className="form-content">
                    <h1 className="heading">Omnix</h1>

                    {/* <Form onSubmit={this.onLoginHandler}> */}
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        name="email"
                        type="email"
                        value={email}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        value={password}
                      />
                    </Form.Group>

                    <div className="text-center">
                      <Button
                        to="/dashboard/"
                        variant="primary"
                        type="submit"
                        onClick={this.login}
                      >
                        Log In
                      </Button>

                      <Link
                        // to="/forgot-password/"
                        to="/registration/"
                        className="fp-link"
                      >
                        Register?
                      </Link>

                      <Link
                        // to="/forgot-password/"
                        to="/forgot-password/"
                        className="fp-link"
                      >
                        Forgot Password ?
                      </Link>
                    </div>
                    {/* </Form> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
