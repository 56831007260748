import React from 'react';
import TopTab from '../../components/tabs/top-tab';

export const CustomerTab = (data) => {
    return (
        <TopTab data={data} text="Customers" icon="lni-users" color="purple" />
    );
};

export const VendorTab = (data) => {
    return (
        <TopTab data={data} text="Supplier" icon="lni-users" color="light-blue" />
    );
};

export const CreditorTab = (data) => {
    return (
        <TopTab data={data} text="Creditors" icon="lni-users" color="success" />
    );
};

export const DebtorTab = (data) => {
    return (
        <TopTab data={data} text="Debtors" icon="lni-users" color="danger" />
    );
};

export const LoyaltyTab = (data) => {
    return (
        <TopTab data={data} text="Loyalty" icon="lni-users" color="purple" />
    );
};