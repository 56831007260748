import React, {useState} from 'react';
import ModalComponent from '../../../components/modal/modal';
import {Form} from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';
// import { phone } from 'faker/locale/en_CA';

export function CustomerModal(handleSubmit, genderOptions, handleDropDownSelect, handleInput){

    return (
        <ModalComponent

            action="New Customer"
            heading="Create Customer"
            content={
                <Form>
                <Form.Row>
                    <Form.Group controlId="formGridAddress1">
                        <Form.Control placeholder="Name" type="text" name="name" onChange={handleInput}/>
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">                       
                       <Dropdown
                           placeholder='Select Gender'
                           fluid
                           search
                           selection
                           options={genderOptions}
                           onChange={handleDropDownSelect}
                       />
                   </Form.Group>

                   <Form.Group controlId="formGridAddress1">
                        <Form.Control type="tel" name="phone" placeholder="Phone" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleInput} />
                    </Form.Group>
                    
                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Address" name="address" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Day of birth e.g. 09" name="day" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Month of birth e.g. June" name="month" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Credit limit" name="limit" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Credit duration, e.g. 30 days" name="duration" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Loyalty code. Automated if left empty" name="loyalty" onChange={handleInput} />
                    </Form.Group>

                    </Form.Row>
                    
            </Form>
       

            }

            handleSubmit={() => handleSubmit.handleSubmit()}

        />
    );
};

export function EditCustomerModal(handleSubmit, gender, genderOptions, handleDropDownSelect, handleInput, name, phone, email, address, day, month, limit, duration){

    return (
        <ModalComponent

            action="Edit"
            heading="Edit Customer"
            content={
                <Form>
                <Form.Row>
                    <Form.Group controlId="formGridAddress1">
                        <Form.Control placeholder="Name" type="text" name="name" value={name} onChange={handleInput}/>
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">                       
                       <Dropdown
                           placeholder='Select Gender'
                           fluid
                           search
                           selection
                           defaultValue={gender}
                           options={genderOptions}
                           onChange={handleDropDownSelect}
                       />
                   </Form.Group>

                   <Form.Group controlId="formGridAddress1">
                        <Form.Control type="tel" name="phone" placeholder="Phone" onChange={handleInput} value={phone} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleInput} value={email} />
                    </Form.Group>
                    
                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Address" name="address" onChange={handleInput} value={address} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Day of birth e.g. 09" name="day" onChange={handleInput} value={day > 0 ? day : ''} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Month of birth e.g. June" name="month" onChange={handleInput} value={month} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Credit limit" name="limit" onChange={handleInput} value={limit > 0 ? limit : ''} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Credit duration, e.g. 30 days" name="duration" onChange={handleInput} value={duration} />
                    </Form.Group>                   

                    </Form.Row>
                    
            </Form>
       

            }

            handleSubmit={() => handleSubmit.handleSubmit()}

        />
    );
};

export function VendorModal(handleSubmit, genderOptions, handleDropDownSelect, handleInput){

    return (
        <ModalComponent

            action="New Supplier"
            heading="Create Supplier"
            content={
                <Form>
                <Form.Row>
                    <Form.Group controlId="formGridAddress1">
                        <Form.Control placeholder="Name" type="text" name="name" onChange={handleInput}/>
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">                       
                       <Dropdown
                           placeholder='Select Gender'
                           fluid
                           search
                           selection
                           options={genderOptions}
                           onChange={handleDropDownSelect}
                       />
                   </Form.Group>

                   <Form.Group controlId="formGridAddress1">
                        <Form.Control type="tel" name="phone" placeholder="Phone" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleInput} />
                    </Form.Group>
                    
                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Address" name="address" onChange={handleInput} />
                    </Form.Group>


                    </Form.Row>
                    
            </Form>
       

            }

            handleSubmit={() => handleSubmit.handleSubmit()}

        />
    );
};

export function EditVendorModal(handleSubmit, gender, genderOptions, handleDropDownSelect, handleInput, name, phone, email, address){

    return (
        <ModalComponent

            action="Edit"
            heading="Edit Supplier"
            content={
                <Form>
                <Form.Row>
                    <Form.Group controlId="formGridAddress1">
                        <Form.Control placeholder="Name" type="text" name="name" value={name} onChange={handleInput}/>
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">                       
                       <Dropdown
                           placeholder='Select Gender'
                           fluid
                           search
                           selection
                           defaultValue={gender}
                           options={genderOptions}
                           onChange={handleDropDownSelect}
                       />
                   </Form.Group>

                   <Form.Group controlId="formGridAddress1">
                        <Form.Control type="tel" name="phone" placeholder="Phone" onChange={handleInput} value={phone} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Control type="email" name="email" placeholder="Enter email" onChange={handleInput} value={email} />
                    </Form.Group>
                    
                    <Form.Group controlId="formGridAddress1">
                    <Form.Control type="text" placeholder="Address" name="address" onChange={handleInput} value={address} />
                    </Form.Group>

                   
                    </Form.Row>
                    
            </Form>
       

            }

            handleSubmit={() => handleSubmit.handleSubmit()}

        />
    );
};

export function LoyaltyModal(handleSubmit, handleInput,handleClick){

    return (
        <ModalComponent

            action="Set Up Loyalty"
            heading="Set Up Loyalty"
            content={
                <Form>
                <Form.Row>
                    

                   <Form.Group controlId="formGridAddress1">
                        <Form.Control type="tel" name="loyalty_percentage" placeholder="Loyalty Percentage" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Control type="email" name="maturity_level" placeholder="Maturity Level" onChange={handleInput} />
                    </Form.Group>                  

                    
                    </Form.Row>
                    <Form.Group id="formGridCheckbox">
                                            <Form.Check type="checkbox" label="Send SMS to your customer when loyalty is matured" onClick={() => handleClick.handleClick()} />
                                        </Form.Group> 
            </Form>
       

            }

            handleSubmit={() => handleSubmit.handleSubmit()}

        />
    );
};

export function EditLoyaltyModal(handleSubmit, handleInput, loyalty_percentage, maturity_level, loyaltyStatus, handleClick){

    return (
        <ModalComponent

            action="Edit Loyalty"
            heading="Edit Loyalty"
            content={
                <Form>
                <Form.Row>
                    

                <Form.Group controlId="formGridAddress1">
                        <Form.Control type="tel" name="loyalty_percentage" value={loyalty_percentage} placeholder="Loyalty Percentage" onChange={handleInput} />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Control type="email" name="maturity_level" value={maturity_level} placeholder="Maturity Level" onChange={handleInput} />
                    </Form.Group>
                    
                    
                   
                    </Form.Row>
                    <Form.Group id="formGridCheckbox">
                                            <Form.Check defaultChecked={loyaltyStatus == 1 ? 'checked' : ''} type="checkbox" label="Send SMS to your customer when loyalty is matured" onClick={() => handleClick.handleClick()} />
                                        </Form.Group> 
            </Form>
       

            }

            handleSubmit={() => handleSubmit.handleSubmit()}

        />
    );
};
