import React, {useState} from 'react'; 
import {Modal, Button, Alert} from 'react-bootstrap';

export default function AgentAcceptModal({content, action, heading, handleSubmit, errorMsg, errorStatus}){

    const [show, setShow] = useState(true);
   
    const handleClose = () => {
        setShow(false);
    }

   
    
        return (
            <div className="modal-wrap">

                <Modal show={show} onHide={handleClose}  backdrop="static">
                    <Modal.Header >
                        <Modal.Title>Terms and Conditions</Modal.Title>
                    </Modal.Header>

                    <Modal.Body> 
                        {}
                    <Alert variant={errorStatus} className="center" style={{justifySelf: 'center', alignItems: 'center', top: 10}}>
                                        {errorMsg}
                                    </Alert>
                        {content}

                    </Modal.Body>

                    <Modal.Footer>
                        {/* <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button> */}
                        <Button variant="primary"  onClick={handleSubmit}>
                            Agree & Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
