import React, { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";

export default function UplaodModal({
  content,
  action,
  heading,
  handleSubmit,
  errorMsg,
  errorStatus,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <div className="modal-wrap">
      <Button variant="btn btn-xs btn-outline-primary" onClick={handleShow}>
        {action} <br></br>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {}
          <Alert
            variant={errorStatus}
            className="center"
            style={{ justifySelf: "center", alignItems: "center", top: 10 }}
          >
            {errorMsg}
          </Alert>
          {content}
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button> */}
          <Button variant="primary" onClick={handleSubmit}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
